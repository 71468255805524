.news-updates-page{
    padding: 1em;
    width: var(--box-container-width);
    margin: 0 auto;
}

.news-updates-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    text-align: center;
}

.news-updates-page .news-updates-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5em;
    margin-bottom: 3em;
}

.news-updates-page .news-updates-card{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
    overflow: hidden;
}

.news-updates-page .news-updates-card .image-section{
    /* flex: 1; */
    height: 350px;
    aspect-ratio: 1 / 1;
}

.news-updates-page .news-updates-card .image-section img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    
}

.news-updates-page .news-updates-card .video-container{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.news-updates-page .news-updates-card .video-container .play-btn{
    position: absolute;
    font-size: 4rem;
    

}

.news-updates-page .news-updates-card .image-section video{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    object-position: center;
}



.news-updates-page .news-updates-card .details-section{
    flex: 1;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.news-updates-page .news-updates-card .details-section .news-date,
.news-updates-page .news-updates-card .details-section .news-desc{
    font-size: 1.5rem;
}