.whats-app-modal .MuiBox-root{
    border-radius: 0;
}

/************* HEADER *******************************/

.whats-app-modal .whatsApp-modal-section header{
    padding: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fefefe;
    border-bottom: 1px solid #cccccc;

}

.whats-app-modal .whatsApp-modal-section header h3{
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    margin-left: auto;
    
}

.whats-app-modal .whatsApp-modal-section header .close-icon{
    margin-left: auto;
    cursor: pointer;
    font-size: 2.3rem;
    color: #212121;
}


/************* LIST *******************************/
.whats-app-modal .whatsApp-modal-section .list .link{
    padding: 0.75em 1em;
    display: block;
}

.whats-app-modal .whatsApp-modal-section .list .link:hover,
.whats-app-modal .whatsApp-modal-section .list .link:active{
    color: var(--highlight-color);
}

.whats-app-modal .whatsApp-modal-section .list .link{
    border-top: 1px solid #cccccc;
 }

.whats-app-modal .whatsApp-modal-section .list .link .field-name{
    font-weight: 600;
}