.product_title_only{
    padding: 0.75em !important;
    
}

.product_title_only{
    color: var(--highlight_color);
    font-weight: 600;
    font-size: 1.8rem;
    text-align: left;
    border: 1px solid var(--menu_separator_color, #eee);
    padding: 0.5em !important;
    padding-left: 0.75em !important;
    border-left: 5px solid var(--highlight_color);
    border-radius: 0.5rem;
    background-color: var(--menu_bg_color);
    
}

@media only screen and (min-width: 400px) {
    .product_title_only h2{

        font-size: 2rem;
    }
}

@media only screen and (min-width: 768px) {
    .product_title_only h2{

        font-size: 2.3rem;
    }
}
