.pagination-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em !important;
}

.pagination-container.bottom-pagination{
    /* background-color: #fff; */
    background-color: transparent;
   
    z-index: 2;
}

.pagination-container .MuiPagination-ul{
    display: flex;
    gap: 0.75em;
}

.MuiPaginationItem-root{
    font-size: 1.2rem !important;
}

.pagination-container .Mui-selected{
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color) !important;
    border-color: var(--btn_primary_color) !important;
}


/*top-header-filtered-pagination*/
.top-header-filtered-pagination{
    padding: 0.25em 0 !important;
}
.top-header-filtered-pagination .MuiButtonBase-root{
    height: 35px !important;
    font-family: inherit;
}

.top-header-filtered-pagination .MuiButtonBase-root, .bottom-pagination .MuiButtonBase-root, .top-header-mobile-view-pagination .MuiButtonBase-root{
    color: var(--web_pagination_fg_color);
    border-color: var(--web_pagination_fg_color);
}

@media only screen and (min-width:1170px) {
    .top-header-filtered-pagination .MuiButtonBase-root, .bottom-pagination .MuiButtonBase-root{
        height: 40px !important;
        width: 40px !important;
        font-size: 1.5rem !important;
    }

    
}


/* top-header-mobile-view-pagination */
.top-header-mobile-view-pagination{
    z-index: 2;
    background-color: var(--matrix_toolbar_bg_color, #fff);
    box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid var(--menu_separator_color, #eee);
}
.top-header-mobile-view-pagination .MuiPagination-ul{
    gap: 0.25em;
}