.chain-house-24-contact-us{
    display: flex;
    flex-direction: column;
    width: min(1440px, 100%);
    padding: 2em 1em;
    margin: 0 auto !important;
}

.chain-house-24-contact-us .location{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25em;
    padding-bottom: 2em;
    margin-bottom: 2em !important;
    border-bottom: 1px solid #212121;
}

.chain-house-24-contact-us .location .CHLogo{
    width: min(300px, 80%);
}

.chain-house-24-contact-us .location-title{
    margin-block: 0.5em !important;
}

.chain-house-24-contact-us .location-title h4{
    font-size: 2rem;
    font-weight: 600;
}

.chain-house-24-contact-us .location .address{
    font-size: 1.6rem;
    color: #212121;
    margin-bottom: 0.5em;
}

.chain-house-24-contact-us .location p:not(.address){
    font-size: 1.5rem;
    color: #212121;
}

.chain-house-24-contact-us .location p a{
    font-size: inherit;
    color: inherit;
    text-decoration: underline;
}

.chain-house-24-contact-us .location .button{
    display: block;
    padding: 0.5em 2em;
    background: #daaf66;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212121;
    margin-top: 1em;
}





.chain-house-24-contact-us  .social{
    display: flex;
    flex-direction: column;
    gap: 3em;
    margin-top: 2em;
}

.chain-house-24-contact-us  .social h2{
    font-size: 3rem;
    text-align: center;
}

.chain-house-24-contact-us  .social .social-links{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    width: min(600px, 100%);
    margin: 0 auto !important;
}

.chain-house-24-contact-us  .social .social-links a{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    color: #212121;
    font-size: 1.6rem;

}

.chain-house-24-contact-us  .social .social-links img{
    width: min(150px, 100%);
    aspect-ratio: 1;
}

@media only screen and (max-width: 576px){
    .chain-house-24-contact-us .location-title h4{
        font-size: 1.8rem;
    }

    .chain-house-24-contact-us .location .address{
        font-size: 1.5rem;
        
    }
    
    .chain-house-24-contact-us .location p:not(.address){
        font-size: 1.4rem;
        
    }

    .chain-house-24-contact-us  .social h2{
        font-size: 2.5rem;
    }

    .chain-house-24-contact-us  .social .social-links a{
        font-size: 1.5rem;
    }

    .chain-house-24-contact-us  .social .social-links img{
        width: min(100px, 100%);
    }
}