.custome-order-select-client-form{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.75em !important;
    padding-bottom: 2em;
    position: relative;
}

.custome-order-select-client-form .number-input{
    width: 100%;
}

.custome-order-select-client-form input:not(.r-container input){
    height: 44px;
}

.custome-order-select-client-form .r-container input{
    height: 39px !important;
}

.custome-order-select-client-form .r-container{
    text-align: left;
}

.custome-order-select-client-form label{
    color: #444444;
    font-size: 1.5rem;
}

.custome-order-select-client-form .input-group:nth-child(2) label,
.custome-order-select-client-form .input-group:nth-child(11) label
{
    margin-bottom: 1em;
}


.custome-order-select-client-form-footer{
    position: sticky;
    bottom: 0;
    padding: 0.5em;
    display: flex;
    background: #fff;

}
.custome-order-select-client-form textarea{
    padding: 0.5em;
    border: 1px solid #eeeeee;
    margin-top: 1em;
}

.custome-order-select-client-form-footer .button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.5em 1em;
    border-radius: 0.3rem;
    text-transform: uppercase;
    width: 100%;
}
