.usp-section:has(:not(.trending-slick)) .home-card-header-sec h2{
    margin-left: 20px !important;
}

.usp-section:has(.trending-slick) .home-card-header-sec h2{
    margin-left: 40px !important;
}

.usp-section .mb-minus-30{
    margin-top: 2em !important;
    padding-inline: 15px;
}

.usp-section .slick-track{
    display: flex;
    gap: 1em;
}
.usp-section:has(.trending-slick) .ups-single-card{
    width: 100%;
    
}
