/* 
  
  @media only screen and (max-width:280px) {
    .single-nav-thumb .single-slide {
      width: 80px !important;
    }
  
    .mapped-image {
      width: 30px !important;
      display: flex !important;
    }
  
    .single-pro-img-no-sidebar {
      width: 100%;
    }
  }
   */
   @media only screen and (max-width: 320px) {
    .single-pro-img.single-pro-img-no-sidebar.sidebar-left {
      width: 44% !important;
      margin-left: -25px;
    }
  
    .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 25% !important;
      margin-left: 134px;
    }
  
    div#single-slide-left {
      width: 70px !important;
    }
  
    .slick-slider.single-nav-thumb.left.slick-vertical.slick-initialized {
      width: 64px;
    }
  
    #leftThumbDiv {
      width: 80px;
      display: block;
    }
  
    /* .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 220px !important;
      margin-left: 170px !important;
    } */
  
    .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 300px !important;
      margin-left: 15px !important;
    }
  
    #image-left {
      width: 200px;
    }
  
    .single-product-cover-left {
      width: 300px;
      height: 300px;
    }
  
    /* .imgLeft {
      width: 80% !important;
      display: block !important;
    } */
    .single-nav-thumb .single-slide {
      width: 180px !important;
    }
  
    .mapped-image {
      width: 70px !important;
      display: flex !important;
    }
  
    /* .single-nav-thumb .single-slide {
        width: 180px !important;
      }
    
      .mapped-image {
        width: 70px !important;
        display: flex !important;
      }
    
      .single-pro-img-no-sidebar {
        width: 100%;
      } */
  }
  
  @media only screen and (max-width: 425px) {
    .single-pro-img.single-pro-img-no-sidebar.sidebar-left {
      width: 44% !important;
      margin-left: -25px;
    }
  
    .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 25% !important;
      margin-left: 134px;
    }
  
    div#single-slide-left {
      width: 70px !important;
    }
  
    .slick-slider.single-nav-thumb.left.slick-vertical.slick-initialized {
      width: 64px;
    }
  
    #leftThumbDiv {
      width: 80px;
      display: block;
    }
  
    /* .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 220px !important;
      margin-left: 170px !important;
    } */
  
    .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 343px !important;
      /* margin-left: 39px !important;  */
    }
  
    #image-left {
      width: 350px;
    }
  
    .single-product-cover-left {
      width: 300px;
      height: 300px;
    }
  }
  
  @media only screen and (max-width: 575px) and (min-width:425px) {
       
    .col-xl-2.pro-gl-content {
      width: 50%;
    max-width: none;
  }
  }
  @media only screen and (max-width: 575px) {
    .filter-by{
      display: none;
    }

    .infinite-scroll-component__outerdiv {
      padding: 0;
  }
    .col-xl-2.pro-gl-content {
      /* width: 100%; */
    max-width: none;
  }
  .actions-ico{
    width: 100% !important;
  }
  
    .ec-product-sbtn .ec-product-image .pic-1 {
      width: 100% !important;
      /* height: 300px !important; */
      height: 180px !important;
    }
    .site-header-logo img {
      max-width: min(100%, 250px) !important;
      padding: 0 1em;
  }

    .loader {
      height: auto;
    }
   
    /* .ec-single-qty {
      bottom: 60px !important;

    } */
  }
  
  @media only screen and (max-width: 576px) {
    /* *{
      font-size: 14px;
    } */
    .shortlist-page .ec-page-content{
      margin-top: 72px !important;
    }
    .cart-page .ec-page-content{
      margin-top: 72px !important;
    }
  
    .cart-footer {
      padding: 0;
      /* bottom: 60px!important; */
      bottom:0;
      font-size: 13px;
    }
    .ec-register-wrapper .ec-register-container .ec-register-form .ec-register-wrap.ec-register-half {
      width: 100% !important;
  }
    /* .row div {
      margin: 0;
      padding: 0;
    } */
  
    /* .row {
      margin: 0.2rem 0;
    }
   */
    .btn-li {
      padding: 0.5rem 0.75rem !important;
      font-size: 14px;
    }
  
    .ec-breadcrumb {
      padding: 0;
    }
  
    /* .col-sm-5 {
      width: 41.6666666667%;
    }
  
    .col-sm-7 {
      width: 58.3333333333%;
    } */
  
    .ec-pro-variation-inner {
      display: flex;
    }
  }
  
  @media only screen and (min-width: 767px) {
    .single-pro-desc-no-sidebar {
      width: 40%;
    }
  
    .col-sm-5 {
      display: flex;
      justify-content: flex-start;
    }

    /* .single-pro-content .ec-pro-variation{
      border-bottom:none;
    } */
  
    /* .container {
      max-width: 1270px;
    } */
  }
  
  @media only screen and (max-width: 768px) {
    .single-pro-img.single-pro-img-no-sidebar.sidebar-left {
      width: 44% !important;
    }
  
    .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 25% !important;
      /* margin-left: 134px; */
    }
  
    div#single-slide-left {
      width: 80px !important;
    }
  
    .slick-slider.single-nav-thumb.left.slick-vertical.slick-initialized {
      width: 64px;
    }
  
    #leftThumbDiv {
      width: 80px;
      display: block;
    }
  
    /* .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 220px !important;
      margin-left: 170px !important;
    } */
  
    .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 42% !important;
      /* margin-left: 23px !important; */
    }
  
    /* #image-left {
      width: 350px;
    } */
  
    /* .single-nav-thumb .single-slide {
      width: 100px !important;
    } */
  
    .mapped-image {
      width: 90px !important;
      display: flex !important;
    }
  
    .single-pro-img-no-sidebar {
      width: 100% !important;
    }

    /* .imgLeft {
      width: 80% !important;
      display: block !important;
    }
    .single-nav-thumb .single-slide {
        width: 100px !important;
      }
    
      .mapped-image {
        width: 90px !important;
        display: flex !important;
      }
    
      .single-pro-img-no-sidebar {
        width: 100%;
      } */
  }
  
  @media only screen and (max-width:768px) {
    .modal-content {
      width: 80%;
    }
  }
  @media only screen and (width > 1760px) {
    /* body{
      background-color: red;
    } */
    .ec-title{
      padding: 0.25em;
      font-size: 2.5rem;
    }
    /*Hide for avoid conflict*/
    /* .auth-comp label  {
    font-size: 1.2rem !important;
    margin-bottom: 0.35em!important;
    margin-top: 0.75em;
  } */


  /*Hide for avoid conflict*/
  /* .auth-comp button {
    font-size: 1.4rem !important ;
  } */

  /*Hide for avoid conflict*/
  /* .auth-comp ::placeholder{
    
  font-size: 1.12rem !important ;
} */
.auth-comp .required-field{
  font-size: 1.1em !important ;
}

/*Hide for avoid conflict*/
/* .auth-comp input ,.auth-comp  .flag-in{
  font-size: 1.1rem !important ;
} */



/* .auth-comp .ec-login-wrapper{
  width: 85vh ;  
  max-width: none ;} */
  .auth-comp .ec-register-wrapper{
    /* width: 160vh ;   */
    max-width: 2370px ;
  }
  .site-header-logo img {
  width: calc(0.75vw + 300px) !important;
  max-width: none ;
}
.auth-comp .ec-login-wrapper .ec-login-container .ec-login-form input{
  padding:30px
}

.login-button button,.ec-register-btn button{
  height: 55px
}
span.ec-register-wrap.ec-register-btn{
  width: 160px;
}


/* #RegContaier .number-input, #RegContaier .RegInp, #RegContaier .css-13cymwt-control, #RegContaier .css-13cymwt-control > * {
  height: 50px !important;
} */
#RegContaier .css-13cymwt-control, #RegContaier .css-t3ipsp-control {
  font-size: 24px !important
}
}

@media only screen and (width > 3840px) {
  .auth-comp label  {
    font-size: 2.3rem !important ;
    margin-bottom: 0.5em!important;
    margin-top: 1em;
  }
  .auth-comp input ,.auth-comp  .flag-in{
    font-size: 2.5em !important ;
  }
  svg.svg-inline--fa.fa-eye, svg.svg-inline--fa.fa-eye-slash{
    height: 2.7em;
  }
  .auth-comp button {
    font-size: 2.25rem !important;
}

#LoginContainer .number-input, #LoginContainer input {
  height: 80px !important;
  font-size: 2.3rem;
}

#LoginContainer input::placeholder {
  font-size: 2.3rem !important;
}



.auth-comp .required-field {
  font-size: 2.5em !important;
}
.login-button button, .ec-register-btn button {
  height: 100px;
}
.ec-title {
  padding: 0.25em !important;
  font-size: calc(0.75vw * 2.8rem) !important;
}
.ec-login-wrapper .ec-login-container .ec-login-form .btn{
  height: 70px;
}
}

@media only screen and (max-width: 1024px) {
  .ec-register-wrapper .ec-register-container .ec-register-form label {
    font-size: 1.1rem;
  }

  .single-pro-img.single-pro-img-no-sidebar.sidebar-left {
    width: 34% !important;
  }
  
  .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 35% !important;
      /* margin-left: 164px; */
    }
    
    div#single-slide-left {
      width: 100px !important;
    }
    
    .slick-slider.single-nav-thumb.left.slick-vertical.slick-initialized {
      width: 100px;
    }
    
    #leftThumbDiv {
      width: 100px;
      display: block;
    }
    

  
    .single-pro-img.single-pro-img-no-sidebar.sidebar-left {
      width: 34% !important;
    }
  
    .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 100% !important;
      /* margin-left: 164px; */
    }
    div#single-slide-left {
      width: 100% !important;
    }
  
    /* .slick-slider.single-nav-thumb.left.slick-vertical.slick-initialized {
      width: 100%;
    } */
  
    #leftThumbDiv {
      /* width: 100px; */
      display: block;
    }
  
    /* .single-pro-desc.single-pro-desc-no-sidebar.left-pro-desc {
      width: 300px !important;
      margin-left: 150px !important;
    } */
  }
  
  @media only screen and (max-width: 1366px) {
    .single-pro-content .ec-pro-variation .ec-pro-variation-inner .ec-pro-variation-content li {
      width: auto;
      padding: 5px;
      margin-bottom: 10px;
    }
  }
  
  @media only screen and (max-width: 992px) {

    /* .section-space-p{
      min-height: 132vh;
    } */
    .ec-pro-list-top {
      /* top: 54.5px; */
      /* top: 7.7%; */
      top: 7%;
    }
    /* .slick-slider.single-nav-thumb.left.slick-vertical.slick-initialized {
      width:100%;
    } */
    .cart-shortlist-section{
      margin-top: -4px;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .ec-pro-list-top {
      top: 16%;  
    }

    .ec-register-wrapper .ec-register-container .ec-register-form .btn {
      width: 100%;
    }

    /* .section-space-p{
      min-height: 135vh;
    } */
  }
  
  @media only screen and (max-width: 576px) and (min-width: 576px) {
    .ec-pro-list-top {
      top: 60px;
    }
  }
  
  @media only screen and (max-width: 767px) {
  
    #RegContaier .number-input,
    #RegContaier .RegInp,
    #RegContaier .css-13cymwt-control,
    #RegContaier .css-t3ipsp-control,
    #RegContaier .css-1fdsijx-ValueContainer,
    #RegContaier .css-13cymwt-control>* {
      height: 42px !important;
      font-size: 0.98rem;
    }
    #RegContaier .css-qbdosj-Input{
      display: flex;
      align-items: center;
    }
    
    #RegContaier .css-b62m3t-container input {
      height: 0px !important;
    }
    /* .section-space-p{
      min-height: 140vh;
    } */
    /*   
    #RegContaier .css-qbdosj-Input{
      height: 34px !important;
    } */

    /*Hide for avoid conflict*/
    /* #LoginContainer .number-input,
    #LoginContainer input:not(.no-selection) {
      height: 42px !important;
    } */

    /*OPT Inputs */
    .no-selection{
      width: 45px !important;
    }
  
  }

  
@media screen and (min-width: 768px) {
  .ec-register-three {
    width: 33% !important;
  }
}
@media only screen and (max-width: 500px) {
  .ec-pro-list-top {
    top: 14.5%;  
  }
}
@media only screen and (max-width:425px) {
  .ec-pro-list-top {
    top: 13.5%;  
  }

  .ec-login-wrap .otp-inputs > div{
    gap: 0.5em;
  }

  /*Hide for avoid conflict*/
  /* .ec-login-wrap .otp-inputs .no-selection{
    width: 35px !important;
  } */

  /* .section-space-p{
    min-height: 130vh;
  } */
}
@media only screen and (max-width:375px) {
  .ec-pro-list-top {
    top: 12.5%;  
  }
  /* .section-space-p{
    min-height: 126vh;
  } */

  #Verification input{
    width: 36px !important;
    height: 36px !important;
  
}

}

/*Cart Product Details greater than 768px*/
@media screen and (min-width: 768px) {
  .col-12.cart-product-details .row > *{
    margin-bottom: 5px;
  }

  .col-12.cart-product-details .row > .half-width{
    padding-right: 0;
  }

  .col-12.cart-product-details .row > .full-width .label 
  {
      width: 25%;
  }

  .col-12.cart-product-details .row > .half-width .label{
    width: 50%;
  }

  .col-12.cart-product-details .row > .half-width select{
      margin-left: 0 !important;
  }

  .col-12.cart-product-details .data{
    display: flex;
    justify-content: flex-end;
    /* width: 85px; */
  }


  .cart-product-details + .qty label{
    width: 25%;
  }

  

  .productDetail .cart-btn{
        display: none;
  }

  /*Change Accrodation body hight to show cart and short list button*/
  .cart-accordation-body{
    padding-bottom: 40px  !important;
    flex-wrap: wrap;
  }

}

@media screen and (min-width: 576px) {
    .karat_dropdown{
      width: 122px;
      flex-shrink: 0;
    }
}

@media screen and (min-width: 768px) {
  .karat_dropdown{
    /* width: 122px !important; */
    flex-shrink: 0 !important;
    position: relative;
    /* right: 37px; */
    /* display: none !important; */
  }
}

@media screen and (max-width: 900px) and (min-width: 768px) {
  .karat_dropdown{
    
    width: 85px !important;
    right: 0 !important;
  }
}

/*This is just for 768px fixed Qty select & other data in cart and short-list*/
/* @media screen and (width = 768px) {
  .cart-product-details + .qty .data{
     justify-content: flex-end !important;
     position: relative;
     right: 0px;
  }

  .col-12.cart-product-details .half-width .data{
    position: relative;
    right: 15px !important;
  }

  .karat_dropdown{
    width: 122px !important;
    
  }
} */


@media screen and (max-width: 768px) {
  .col-12.cart-product-details .row > *{
    margin-bottom: 5px;
  }
  
  .col-12.cart-product-details .data{
    margin-left: auto;
  }

  /*Change Accrodation body hight to show cart and short list button*/
  /* .cart-accordation-body{
    padding-bottom: 80px  !important;
  } */
}

.cart-inner-body{
  margin-bottom: 120px;
}

@media screen and (max-width: 576px) {
  .cart-inner-body{
    margin-bottom: 160px;
  }
}