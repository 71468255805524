.campaign-page{
    padding: 1em;
    width: min(1440px, 100%);
    margin: 0 auto !important;
    
    
}

.campaign-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    text-align: center;
}

.campaign-page .campaign-section {
    display: flex;
    flex-direction: column;
    gap: 3em;
}

.campaign-page .campaign-card{
    display: flex;
    align-items: center;
    gap: 3em;
}

.campaign-page .campaign-card:nth-child(even) .image-section{
    order: 2
}

.campaign-page .campaign-card:nth-child(even) .detail-section{
    order: 1
}

.campaign-page .campaign-card .image-section{
    width: 75%;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    /* cursor: pointer; */
}

.campaign-page .campaign-card .detail-section{
    width: 25%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    background: #fff;
    gap: 1em;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
}

.campaign-page .campaign-card .detail-section .year{
    color: var(--highlight_color);
}

.campaign-page .campaign-card .detail-section .view-more-btn{
    margin-top: 2em;
    
}

.campaign-page .campaign-card .detail-section .view-more-btn:hover{
    color: var(--highlight_color);
    text-decoration: underline;
}

@media only screen and (max-width: 1170px){
    .campaign-page .campaign-card{
        gap: 2em;
    }
}

@media only screen and (max-width: 768px){
    .campaign-page .campaign-section {
        gap: 2em;
    }

    .campaign-page .campaign-card:nth-child(even) .image-section{
        order: 1
    }

    .campaign-page .campaign-card:nth-child(even) .detail-section{
        order: 2
    }

    .campaign-page .campaign-card .image-section, .campaign-page .campaign-card .detail-section{
        width: 100%;
        box-shadow: none;
    }

    .campaign-page .campaign-card .detail-section{
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .campaign-page .campaign-card .detail-section .view-more-btn{
        margin-top: 0;
    }

    .campaign-page .campaign-card{
        gap: 0;
        flex-direction: column;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
        
    }
}

@media only screen and (max-width: 576px) {
    .campaign-page .campaign-card .detail-section{
        padding: 1em;
    }
}

/*===================================================================================================*/
.campaign-details-section header{
    margin-block: 1em;
    padding-left: 1em;
    border-left: 5px solid var(--highlight_color);
    border-radius: 0.5rem;
}

.campaign-details-section header .campaign-details-title{
    font-size: 1.6rem;
}

.campaign-details-section header .year{
    color: var(--highlight_color);
}


.campaign-details-section .campaign-details-card .campaign-details-image-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
}

.campaign-details-section .campaign-details-card .campaign-details-image-grid img{
    cursor: pointer;
}

@media only screen and (max-width: 768px){
    .campaign-details-section .campaign-details-card .campaign-details-image-grid{

        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 576px){
    .campaign-details-section .campaign-details-card .campaign-details-image-grid{

        grid-template-columns: repeat(1, 1fr);
    }
}