.store-section-container *{
    padding: 0 !important;
    margin: 0 !important;
}

.store-section-container{
    display: flex;
    flex-direction: column;
    position: relative;
    /* overflow: hidden; */
}

.store-section-container .store-bg-image{
    height: 400px;
    object-position: top center;
    object-fit: cover;
    position: absolute;
    /* z-index: -1; */
    width: 100%;
}

.store-section-container .store-card-container{
    padding-top: 260px !important;
    /* padding-inline: 1em !important; */
    margin: 0 auto !important;
    padding-bottom: 2em !important;
    width: min(900px, 97%);
}

.store-section-container .store-card-container .slick-track{
    display: flex;
    gap: 0.5em;
    align-items: stretch;
}

.store-section-container .store-card{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #eeeeee;
    border-radius: 0.3rem;
    max-width: 300px;
    flex: 1 0 175px;
}

.store-section-container .store-card.remove-bg{
    background-color: transparent;
    border: none;
}

.store-section-container .store-card .store-card-img{
    /* aspect-ratio: 1 / 1; */
    object-fit: cover;
    /* object-position: center; */
}

.store-section-container .store-card:hover{
    box-shadow: 0 3px 10px  rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
}

.store-section-container .store-card .store-card-details{
    display: flex;
    flex-direction: column;
    gap: 0.35em;
    padding: 0.65em !important;
    text-align: center;
    flex: 1;
}

.store-section-container .store-card .store-card-title{
    margin-bottom: auto !important;
}

.store-section-container .store-card .store-direction-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0.25em !important;
    margin-top: 1em !important;
    gap: 0.5em;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    border: 1px solid var(--highlight_color);
    color: var(--highlight_color);
    margin-top: auto !important;
}

.store-section-container .store-card .store-direction-btn:hover{
    background-color: var(--highlight_color);
    color: var(--button_foreground_color);
}


.store-section-container .slick-arrow{
    display: none !important;
}


@media only screen and (min-width:768px) {
    .store-section-container .store-card .store-card-details{
        padding: 0.75em !important;
    }

    .store-section-container .store-card-container .slick-track{
        gap: 1em;
    }
    

    .store-section-container .store-card .store-direction-btn{
        font-size: 1.4rem;
    }

    .store-section-container .store-card-container{
        padding-top: 200px !important;
    }

    .store-section-container .slick-arrow{
        display: block !important;
    }
}

@media only screen and (max-width:576px){
    .store-section-container .store-card-container{
        padding-top: 130px !important;
    }

    .store-section-container .store-bg-image{
        height: 250px !important;
    }
}