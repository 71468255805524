.home-banner-slick-dots{
    display: flex !important;
    justify-content: center;
    gap: 0.25em;
    padding-top: 0.25em;
}

.home-banner-slick-dots button{
    border: 1px solid #212121;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.home-banner-slick-dots li.slick-active button{
    background-color: #212121;
    color: #212121;
}

/* .home-banner-slick-dots li.slick-active button::before{
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    background: #212121;
    border-radius: 50%;
} */