.wait-search-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    padding: 1em;
}

.wait-search-container p{
    color: var(--highlight_color);
    text-align: center;
}