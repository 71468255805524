/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */


 .heartbeat {
	-webkit-animation: heartbeat 1000ms ease-in-out 800ms both;
	        animation: heartbeat 1000ms ease-in-out 800ms both;
}


@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  

/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */

 .ping {
	-webkit-animation: ping 500ms ease-in-out forwards;
	        animation: ping 500ms ease-in-out forwards;
}


@-webkit-keyframes ping {
        0% {
                -webkit-transform: scale(0.2);
                        transform: scale(0.2);
                opacity: 0.8;
              }
              50% {
                -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                opacity: 0;
              }
              100% {
                -webkit-transform: scale(1);
                        transform: scale(1);
                opacity: 1;
              }
      }
      @keyframes ping {
        0% {
                -webkit-transform: scale(0.2);
                        transform: scale(0.2);
                opacity: 0.8;
              }
              50% {
                -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                opacity: 0;
              }
              100% {
                -webkit-transform: scale(1);
                        transform: scale(1);
                opacity: 1;
              }
      }
      