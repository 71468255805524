.input-group label{
    margin: 0;
    color: var(--login_register_fg_color);
} 

.input-group input:not(.react-select-container input, .react-tel-input input, input[type="checkbox"]){
    height: 44px;
    padding-left: 0.5em;
    
}

.input-group .react-tel-input input{
    width: 100%;
}

.input-group.dropdown .react-select-container,
.input-group .react-tel-input,
.input-group input:not(.react-select-container input, .react-tel-input input, .custom-order-modal-body input)
{
    margin-block: 1rem !important;
    border-color: var(--login_register_fg_color);
    border-radius: 0.3rem;
    color: var(--login_register_fg_color);
    background-color: transparent !important;
}

.input-group .required{
    color: red;
}

.input-group .error-text{
    font-size: 1.3rem;
    color: red;
}

.input-group .country-list {
    text-align: left;
}