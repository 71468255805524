

.framer-motion-left-right-animation{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    gap: 1em;
    width: var(--box-container-width);
    padding: 1.25em 1em;
}


.framer-motion-left-right-animation .left-comp{
    width: fit-content;
    /* flex: 1 1 400px; */
    display: flex;
    align-items: flex-end;
    order: 2;

}

.framer-motion-left-right-animation .left-comp .text-container{
    display: flex;
    flex-direction: column;
    color: #212121;
    text-wrap: nowrap;
    padding-left: 1em;
    border-left: 5px solid var(--highlight_color);

}

.framer-motion-left-right-animation .left-comp .cat-title{
    font-size: 1.8rem;
    font-weight: 600;
    line-height: normal;
}

.framer-motion-left-right-animation .right-comp{
    overflow: hidden;
    border-radius: 1rem;
    cursor: pointer;
    order: 1;
}


@media only screen and (min-width:768px) {

    .framer-motion-left-right-animation{
        flex-direction: row;
    }

    .framer-motion-left-right-animation .left-comp{
        order: 1;
        flex: 1 1 400px;
    }

    .framer-motion-left-right-animation .right-comp{
        order: 2;
        
    }

    .framer-motion-left-right-animation .left-comp .cat-title{
        font-size: 2rem;
    }

}

@media only screen and (min-width:992px) {
    .framer-motion-left-right-animation .left-comp .cat-title{
        font-size: 2.3rem;
    }
}