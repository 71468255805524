.ZoomImageDrawer{
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    
}

.ZoomImageDrawer .image-header{
    display: flex;
    padding: 0.5em;
    justify-content: flex-end;
    font-size: 2.4rem;
    position: absolute;
    top: 0;
    z-index: 1;
    right: 0.25em;
    background-color: white;
    border-radius: 50%;
}

.ZoomImageDrawer .image-body{
    
    overflow: hidden;
    min-height: 100vh;
}

.ZoomImageDrawer .image-body div{
    height: 100%;
    width: 100%;
    min-height: 100dvh;
}

.ZoomImageDrawer .image-body .zoom-img{
    height: 100vh;
    height: 100dvh;
    width: 100%;
    object-fit: contain;
}

.ZoomImageDrawer .image-body.zoom_cover .zoom-img{
    object-fit: cover;
}