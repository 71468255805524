/*************** MATRIX PRODUCT CARD ***************************/
.responsive-matrix-card{
    width: 100%;
    padding: 0.35em 0.35em !important;
}

/* Base On View layout */
.responsive-matrix-card.single-layout{
    width: 100% !important;
}

/* .responsive-matrix-card.single-layout .single-matrix-product{
    
    height: calc(100vh - var(--mobile-nav-bar-height) - 30px) !important;
} */

.responsive-matrix-card.single-layout .ec-product-image{
    flex: 1;
}

.responsive-matrix-card.single-layout .pic-1{
    height: 100% !important;
}

.responsive-matrix-card.grid-layout{
    width: 50% !important;
}

/* ------- img_type = 6 CSS ----------------------------------------------------- */
.responsive-matrix-card.img-type-six{
    width: 100%;
}

.responsive-matrix-card.img-type-six .single-matrix-product{
   flex-direction: row !important;
}

.responsive-matrix-card.img-type-six .single-matrix-product,
.responsive-matrix-card.img-type-six .pic-1
{
    height: 200px !important;
}



.responsive-matrix-card.img-type-six  .ec-product-image{
    flex: 6
}

.responsive-matrix-card.img-type-six  .ec-product-body{
    flex: 1 1 100px;
}

.responsive-matrix-card.img-type-six  .ec-pro-actions{
    justify-content: flex-start !important;
}

.responsive-matrix-card.img-type-six  .actions-ico{
    width: fit-content !important;
}

.responsive-matrix-card.img-type-six  .ec-product-button-group{
    margin-top: auto !important;
}

/** MEDIA QUERY ***/
@media only screen and (min-width:350px) {
    .responsive-matrix-card{
        width: 50%;
    }
}

@media only screen and (min-width:768px) {
    .responsive-matrix-card{
        width: 33.333%;
        padding: 0.65em 0.65em !important;
    }
    
}

@media only screen and (min-width: 992px) {
    .responsive-matrix-card.full-width-matrix{
        width: 25%;
    }
}



@media only screen and (min-width:1440px) {
    .responsive-matrix-card{
        width: 25%;
    }
    .responsive-matrix-card.full-width-matrix{
        width: 20%;
    }
}

@media only screen and (min-width:1750px) {
    .responsive-matrix-card{
        width: 20%;
    }
}

@media only screen and (min-width:1920px) {
    .responsive-matrix-card{
        width: 16.66667%;
   }

   
}