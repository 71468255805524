.pagination-comp{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
}

.pagination-comp .MuiPagination-ul{
    display: flex;
    gap: 0.5em;
}

.pagination-comp .MuiPaginationItem-root{
    font-size: 1.2rem !important;
}

.pagination-comp .Mui-selected{
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color) !important;
    border-color: var(--btn_primary_color) !important;
}
