:root{
    /*Set size for mobile first*/
    font-size: 62.5%;
    color-scheme: only light !important;
    --page-margin-bottom-for-footer: 6em;

    /*------- Font Default Values ------------------------------*/
    /* --default-font: 'Rubik', sans-serif; */
    --default-font: var(--web_default_font_css_value);
    --title-fs: 2.3rem; /*23 px*/
    --subTitle-fs: 1.5rem; 
    --normalText-fs: 1.5rem; 
    --smallText-fs: 1.2rem; 
    --label-fs: 1.5rem; 
    --label-margin-tb: 0.5em 0 1em!important; 
    --input-value-fs: 1.5rem;

    --mainHeader-padding: 0.85em 1em;
    --mainHeader-height: 55px;

    /*buttons*/
    --borderless-btn-fs: 1.5rem;
    --btn-fs: 1.6rem;

    /* Modal */
    --modal-title-fs: 1.8rem;

    --modal-body-fs: 1.4rem;

    --modal-btn-fs: 1.6rem;
    --modal-btn-padding: 0.375em 0.85em;
    --modal-btn-margin: 0.4em;

    /*Home*/
    --box-container-width: min(1440px, 100%);
    --category-title-fs: 1.8rem;
    --category-label-fs: 1.4rem;
    --category-display-img-width: 50%;

    /*Matrix page*/
    --matrix-single-product-fs: 1.3rem;
    --matrix-single-product-title-fs: 1.4rem;
    --matrix-single-product-button-fs: 1.3rem;
    --background-img-scale-cover: 100000%;

    --count-color: rgb(255, 103, 103);
    --count-fs: 1rem;
    --card-top-label-fs: 1rem;
    --filter-section-sidebar-title-fs: 1.6rem;
    --filter-section-sidebar-title-border: 1px solid #eee;
    --filter-section-sidebar-title-padding: 0.5em;
    --filter-sidebar-section-inline-padding: 0.95em;

    --filter-section-sidebar-spacing: 1.5em;

    --filter-section-sidebar-slider-bgColor: #f5f5f5;
    --filter-section-sidebar-slider-border: 1px solid #eeeeee;

    --filter-section-sidebar-input-fs: 1.4rem;

    --filter-section-sidebar-btn-fs: 1.2rem;
    --filter-section-sidebar-btn-padding: 0.5em 1em;
    --filter-section-sidebar-btn-gap: 1em;
    
    /*FSV page*/
    --fsv-header-padding: 0.5em;
    --fsv-header-title-fs: 1.5rem !important;
    --fsv-header-nav-fs: 1.4rem !important;

    --fsv-prod-details-title-fs: 1.5rem;
    --fsv-prod-details-title-padding: 1rem;
    --fsv-prod-details-label-fs: 1.25rem;
    --fsv-prod-details-value-fs: 1.25rem;
    --fsv-prod-details-btn-padding: 0.35em;
    --fsv-prod-details-input-padding: 0.35em;
    --fsv-prod-details-gap: 1em;
    --fsv-prod-details-group-sm-gap: 0.4em;
    --fsv-prod-details-bottom-group-gap: 0.5em;
    --fsv-prod-other-details-bottom-group-gap: calc(var(--fsv-prod-details-bottom-group-gap) / 2);
    --fsv-prod-details-total-fs: 1.8rem;

    --fsv-footer-btn-fs: 1.4rem;

    --fsv-bottom-img-slider-gap: 0.2em;

    /*Cart page*/
    --cart-group-by-fs: 1.4rem;
    --cart-group-by-btn-fs: 1.3rem;
    --cart-group-by-btn-gap: 0.5em;
    --cart-group-by-padding: 0.5em 1em 0.5em;

    --cart-accordian-header-title: 1.4rem;
    --cart-accordian-bottom-margin: 145px;
    --cart-product-details-gap: 0.75em;
    --cart-product-details-fs: 1.4rem;
    --cart-product-dropdown-padding: 0.75em 0.5em;
    --cart-product-btn-padding: 0.75em;

    --cart-footer-padding: 0.75em;
    --cart-footer-fs: 1.3rem;
    --cart-footer-gap: 0.25em;
    --cart-footer-btn-fs: 1.3rem;
    --cart-footer-btn-padding: 0.95em;

    /*Checkout page*/
    --checkout-fields-gap: 1em;
    --checkout-billing-title-fs: 2.4rem;

    /*------- Spacing Values ---------------------------------*/
    --btn-border-radius: 0.3em;

    /*Form container*/
    --form-container-padding: 1em;

    /*buttons*/
    --primary-btn-padding: 0.55em;
    --primary-btn-margin-tb: 1em;
    --secondary-btn-margin-tb: var(--primary-btn-margin-tb);


    --borderless-btn-margin-tb: 0.15em;

    /*inputs*/
    --input-padding: 0.85em;

    /*Matrix page*/
    --matrix-single-product-button-padding: 0.85em;
}

/********** DEFAULT SETTING ***********************************************************************/

body{
    font-family: var(--default-font) !important;
    font-size: 1.4rem !important;
}


body *:not(.fa, .ecicon, .fa-solid, .fa-brands, .page-not-found, .jewelsmith-about-us-page){
    font-family: inherit !important;
}


/*************************************************************************************************************/
/*********************************** MEDIA QUERIES ***********************************************************/
/*************************************************************************************************************/

/*--------- Mobile: 576px and larger --------------------------------------------*/
@media only screen and (min-width:576px) {
    :root{
        color-scheme: only light !important;
        --fsv-prod-details-group-sm-gap: 1em;
        --fsv-prod-details-bottom-group-gap: 0.75em;

        --cart-group-by-btn-fs: 1.4rem;
        --cart-accordian-bottom-margin: 85px;
        --cart-footer-fs: 1.4rem;
        --cart-footer-btn-fs: 1.5rem;
        --card-top-label-fs: 1.2rem;

        --category-title-fs: 2.2rem;
        --category-label-fs: 1.55rem;
    }
}

@media only screen and (min-width:600px) {
    
        :root{
            --category-display-img-width: 45%;
            --fsv-prod-details-label-fs: 1.4rem;
            --fsv-prod-details-value-fs: 1.4rem;
        }
    
}

/*--------- Table: 768px and larger --------------------------------------------*/
@media only screen and (min-width:768px) {
    :root{
        /* Font Default Values */

        --title-fs: 2.5rem; 
        --smallText-fs: 1.4rem; 
        --label-fs: 1.6rem; 
        --input-value-fs: 1.6rem;
        --normalText-fs: 1.6rem; 
        /*buttons*/
        --borderless-btn-fs: 1.6rem;
        --btn-fs: 1.7rem;

        --fsv-header-padding: 1em;

        /*spacing*/
        --form-container-padding: 1em 1.5em;

        --cart-product-details-fs: 1.5rem;
        --cart-accordian-header-title: 1.5rem;

        --category-title-fs: 2.4rem;

        --category-display-img-width: 30%;
    }
}


/*--------- Laptop: 1024px and larger --------------------------------------------*/
@media only screen and (min-width:1024px) {
    :root{
        --category-display-img-width: 23%;
    }
}



@media only screen and (min-width:1200px){
    :root{
        --category-display-img-width: 20.7%;
    }

}



/*--------- Desktop: 1440px and larger --------------------------------------------*/
@media only screen and (min-width:1440px){
    :root{
        /* Font Default Values */

        --title-fs: 3.2rem; 
        --label-fs: 1.7rem; 
        /* --input-value-fs: 1.8rem; */
        --normalText-fs: 1.8rem; 
        /*buttons*/
        --borderless-btn-fs: 1.8rem;
        --btn-fs: 2rem;

        /*spacing*/
        --form-container-padding: 1em 1.5em;

        /* Modal */
        --modal-title-fs: 1.8rem;

        --modal-body-fs: 1.6rem;

        --modal-btn-fs: 1.8rem;
        --modal-btn-padding: 0.375em 0.85em;
        --modal-btn-margin: 0.4em;
    }

}


/*--------- 4K Screen: 2560px and larger --------------------------------------------*/
@media only screen and (min-width:2560px) {

}