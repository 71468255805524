.InlineFilter-section{
    align-items: flex-start;
    flex-direction: column;
    gap: 0.75em;
    justify-content: center;
}

/********** DROPDOWN SECTION **************************************/
.InlineFilter-section .dropdown-options{
    display: flex;
    justify-content: center;
    gap: 2em;
}

.InlineFilter-section .dropdown-options .down-arrow{
    font-size: 1.4rem;
}


/********** FILTER LABEL SECTION **************************************/
.InlineFilter-section .filtered-label-section{
    display: flex;
    gap: 0.5em;
    align-items: center;
    flex-wrap: wrap;
}

.InlineFilter-section .filtered-label-section .filtered-capsul-section{
    display: flex;
    gap: 0.75em;
    flex-wrap: wrap;
    
}

.InlineFilter-section .filtered-capsul-section{
    color: var(--login_register_fg_color);
}

.InlineFilter-section .filtered-label-section .label-capsul{
    display: flex;
    align-items: center;
    background-color: var(--highlight_color-opac);
    text-wrap: nowrap;
    padding: 0.35em 0.75em !important;
    border-radius: 999px;
    line-height: normal;
    gap: 0.25em;
    cursor: pointer;
    
}

.InlineFilter-section .filtered-label-section .label-capsul:hover{
    background-color: var(--highlight_color);
    color: var(--btn_primary_foreground_color);
}

.InlineFilter-section .filtered-label-section .label-capsul .remove-btn{
    font-size: 1.5rem;
    line-height: normal;
}

.InlineFilter-section .filtered-label-section .filtered-remove-all{
    text-decoration: underline;
    text-underline-offset: 2px;
    cursor: pointer;
}


