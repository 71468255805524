/********** UTILITY CLASSES ***********************************************************************/
/*Set size for mobile first*/
/*--------- Heading and Body realated utility classes ----------------------------------*/
.title{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 

    /* Modify */
    font-size: var(--title-fs) !important;
    line-height: 1.2em !important;
    margin: 1em 0 !important;
};


.subTitle{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 

    /* Modify */
    font-size: var(--subTitle-fs) !important;
    
}

.normalText{
    /* Reset */
    padding: 0 !important;
    line-height: normal !important; 

    /* Modify */
    font-size: var(--normalText-fs) !important;
}



/*--------- Form realated utility classes --------------------------------------------*/

.label{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 

    /* Modify */
    font-size: var(--label-fs) !important;
    margin: var(--label-margin-tb) !important;
    
}


.fieldValue{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 
    height: auto !important;
    border-radius: 0 !important;

    /* Modify */
    font-size: var(--input-value-fs) !important;
    padding: var(--input-padding) !important;
    
}

.errorText{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 

    /* Modify */
    font-size: var(--smallText-fs) !important;
    margin-top: 0.25em !important;
    margin-bottom: 0.5em !important;
    

}

.contryCodeDropDown{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 

    /* Modify */
    font-size: var(--input-value-fs) !important;
}

.btn-primary{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
    height: auto !important;
     

    /* Modify */
    font-size: var(--btn-fs) !important;
    margin: var(--primary-btn-margin-tb) 0 !important;
    padding: var(--primary-btn-padding) !important;
    
}

.modal-btn{
    padding: 0 !important;
    margin: 0 !important;

    font-size: var(--modal-btn-fs) !important;
    padding: var(--modal-btn-padding) !important;
    margin: var(--modal-btn-margin) !important;
    
}

.modal-title{
    font-size: var(--modal-title-fs) !important;
}

.modal-body{
    font-size: var(--modal-body-fs) !important;
}

.modal-backdrop{
    width: 100%;
    height: var(--device-pixel-ratio-height) !important;
}

.filter-sidebar-section-overlay, .filter-sidebar-section, .filter-sidebar-section .ec-sidebar-wrap{
    height: var(--device-pixel-ratio-height) !important;
}



.btn-secondary{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 
    height: auto !important;

    /* Modify */
    font-size: var(--btn-fs) !important;
    margin: var(--primary-btn-margin-tb) 0 !important;
    padding: var(--primary-btn-padding) !important;
}

.borderless-btn{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 

    /* Modify */
    font-size: var(--borderless-btn-fs) !important;
    margin: var(--borderless-btn-margin-tb) 0 !important; 
}

/*--- React Select ---*/

.r-select__single-value, .r-select__placeholder{
    /* Reset */
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important; 

    /* Modify */
    font-size: var(--input-value-fs) !important;
    color: var(--login_register_fg_color, #333333) !important;
}

#scroll-to-top-btn{
    gap: 1em !important;
    align-items: center !important;
    font-size: 1.4rem !important;
    padding: 2em 0 !important;
    color: var(--highlight_color);
    
}

#scroll-to-top-btn > *{
    color: inherit;
    font-size: inherit;
}

.custom-input-btn-mode-enable{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    border-color: var(--btn_primary_color) !important;
    text-align: center;
}

/**************** Button classes *******************/
.disabled-btn{
    background-color: rgb(185 185 185) !important;
}

.box-container-width{
    width: var(--box-container-width);
    margin: 0 auto !important;
    padding-inline: 1em !important;
}


.qty-plus-minus .dec{
    order: 1
}

.qty-plus-minus .inc{
    order: 3
}

.qty-plus-minus .qty-input{
    order: 2;
    
}



/*************************************************************************************************************/
/*********************************** MEDIA QUERIES ***********************************************************/
/*************************************************************************************************************/

/*--------- Mobile: 576px and larger --------------------------------------------*/
@media only screen and (min-width:576px) {
    
}

/*--------- Table: 768px and larger --------------------------------------------*/
@media only screen and (min-width:768px) {

}


/*--------- Laptop: 1024px and larger --------------------------------------------*/
@media only screen and (min-width:1024px) {

}


/*--------- Desktop: 1280px and larger --------------------------------------------*/
@media only screen and (min-width:1280px) {

}


/*--------- 4K Screen: 2560px and larger --------------------------------------------*/
@media only screen and (min-width:2560px) {

}