.jewellery-redesign-page{
    padding: 1.5em;
    width: min(1440px, 100%);
    margin: 0 auto;
}

.jewellery-redesign-page .image-section{
    flex-direction: column;

}

.jewellery-redesign-page .image-section .image-warpper{
    width: 80%;
}

.jewellery-redesign-page .image-section .image-warpper + .image-warpper {
    margin-top: 1em;
}

.jewellery-redesign-page .image-section .image-warpper h3{
    font-size: 2rem;
    text-align: center;
    margin-bottom: 0.5em;
    color: var(--highlight_color);
}


@media only screen and (max-width: 1170px) {
    .jewellery-redesign-page .image-section .image-warpper{
        width: 100%;
    }
}
