.header-mobile-menu .ec-menu-title header{
    flex: 1;
}

.header-mobile-menu .main-header{
    text-transform: uppercase;
    position: static !important;
}

.header-mobile-menu .ec-menu-title{
    border: none;
}

.header-mobile-menu .menu_title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header-mobile-menu .ec-close{
    align-self: flex-start;
    color: var(--menu_text_color, #555) !important;
}

.header-mobile-menu .user-name{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: .5em;
    font-size: 1.6rem;
    color: var(--menu_text_color, #444444);
}

.header-mobile-menu .user-name .edit-btn{
    margin: 0;
    
}


.header-mobile-menu .ec-menu-content li:not(.category-menu, .border-b) a{
    border-bottom: none !important;
}

.header-mobile-menu .browse-by-category-mobile{
    margin-block: 0.5em;
}

.header-mobile-menu .browse-by-category-mobile h3{
    padding-bottom: 0.5em;
}

.header-mobile-menu li:has(.main-header-title){
    margin-top: 1em;
}

.header-mobile-menu .main-header-title{
    font-size: 1.6rem;
    color: var(--menu_header_text_color, --btn_primary_color);
    text-transform: uppercase;
    font-weight: 600;
}

.header-mobile-menu .general-link{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.header-mobile-menu .general-link .r-arrow{
    font-size: 2rem;
}

.header-mobile-menu .general-link .r-arrow{
    margin-left: auto;
}

.header-mobile-menu  .ec-menu-content > ul li.active > .menu-toggle::before{
    content: "\f068";
}