.checkout-side-modal .checkout.checkout-container{
    padding: 1em !important;
    margin-bottom: 0 !important;
    border: none;
    min-height: calc(100vh - 65px);
    background-color: var(--login_register_bg_color);
}

.checkout-side-modal .checkout.checkout-container .checkout-container{
    border: none;
    padding: 0 !important;
}



.checkout-side-modal .checkout-container .ec-register-half{
    width: 100% !important;
}

.checkout-side-modal .checkout.checkout-container .ec-register-form > form
{
    min-height: calc(100vh - 65px - 2em);
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0 !important;
}

.checkout-side-modal .checkout.checkout-container .ec-register-form > form .place-order-button{
    margin-top: auto !important;
    position: sticky;
    bottom: 0;
    /* background-color: #ffffff; */
    padding: 0.5em !important;
}

.checkout-side-modal .checkout.checkout-container .ec-register-form > form .error-text{
    /* margin-top: 1em !important; */
    display: block;
    /* text-transform: capitalize !important; */
}

.checkout-side-modal .checkout.checkout-container .ec-register-form > form button{
    
    border-radius: 0.3rem;
}

.notifier.modal{
    z-index: 1300;
}


