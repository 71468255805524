:root{
    --button-ui-padding: 1em;
    --btton-ui-fs: 1.4rem;
    --buttin-height: 40px;
    --buttin-radius: 0.3rem;

    --label-ui-fs: 1.5rem;
    --label-ui-margin: 2rem 1rem;

    --input-ui-height: 40px;
    --input-ui-padding: 0.65em;
    /* --input-ui-border: 1px solid #eeeeee; */
    --input-ui-border: 1px solid var(--login_register_fg_color);

    --error-ui-color: red;
    --error-ui-margin: 1em;
    --error-ui-fs: 1.2rem;
    
    --title-ui-fs: 2.8 rem;
    --title-margin: 1em
}

.ui.button-ui{
    padding: var(--button-ui-padding) !important;
    font-size: var(--btton-ui-fs) !important;
    height: var(--buttin-height) !important;
    border-radius: var(--buttin-radius) !important;
}



form.ui.form-ui label, 
.ui.form-label-ui {
    font-size: var(--label-ui-fs) !important;
    margin-block: var(--label-ui-margin) !important;
}

form.ui.form-ui label.button-label{
    margin-block: 0 !important;
}

form.ui.form-ui input[type="text"],
form.ui.form-ui input[type="tel"], 
form.ui.form-ui input[type="number"], 
.ui.form-input-ui
{
    margin: 0 !important;
    height: var(--input-ui-height) !important;
    padding: var(--input-ui-padding) !important;
    color: var(--login_register_fg_color);
    border-radius: 0.3rem !important;
}

form.ui.form-ui input.react-select{
    padding: 0 !important;
}

form.ui.form-ui .react-select.r-select__control{
    height: 40px;
}

form.ui.form-ui .react-select.r-select__input-container{
    margin: 0 !important;
    padding: 0 !important;
}


form.ui.form-ui input.form-control.number-input{
    padding-left: 48px !important;
   
}

form.ui.form-ui .react-tel-input,
form.ui.form-ui .react-select-container {
    margin: 0 !important;
}


form.ui.form-ui textarea,
.ui.form-textarea-ui
{
    margin: 0 !important;
    padding: var(--input-ui-padding) !important;
    background-color: transparent;
    border-radius: 0.3rem !important;
    color: var(--login_register_fg_color);
}

form.ui.form-ui input[type="text"]:not(input.react-select),
form.ui.form-ui textarea,
form.ui.form-ui input[type="number"]
{
    border: var(--input-ui-border) !important;

}


form.ui.form-ui [class*="error"]{
    color: var(--error-ui-color) !important;
    margin-top: var(--error-ui-margin) !important;
    font-size: var(--error-ui-fs) !important;
    
}


/********** TITLE ****************/
.ui.title-ui {
    font-size: 2.8rem !important;
    color: #444444 !important;
    text-align: center;
    text-wrap: balance;
    margin-block: var(--title-margin) !important;
}