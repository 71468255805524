.ShippingBilling_Section{
    width: var(--box-container-width);
    margin:  0 auto;
    padding: 3em 1em; 
}

.ShippingBilling_Section form {
    border: 1px solid #eee;
    padding: 1em 1.5em;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    
}

.ShippingBilling_Section .form-wrapper header{
    font-size: var(--checkout-billing-title-fs);
    color: var(--highlight_color);
    font-weight: 600;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid var(--highlight_color);

}

.ShippingBilling_Section .form-wrapper .form-body{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 0.5em;
}

.ShippingBilling_Section .form-body label{
    margin: 0;
    font-size: calc(var(--label-fs) * 0.9);
}

.ShippingBilling_Section .required-field{
    font-size: 1.4rem;
}

.ShippingBilling_Section .form-body input:not(.image-file){
    height: 45px;
}

.ShippingBilling_Section .form-body .imagefile-container{
    height: 45px;
    overflow: hidden;
    position: relative;
    margin-block: 0.5em;
    border: 1px solid #eeeeee;
    /* opacity: 0.6; */
}

.ShippingBilling_Section .form-body .imagefile-container::before{
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #eeeeee;
    top: 0;
    left: 101px;
    z-index: 1;
}

.ShippingBilling_Section .form-body .image-file{
    border: 0;
    padding: 0;
    margin: 0;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
}

.ShippingBilling_Section .form-body textarea{
    padding: 1em;
    border: 1px solid #eeeeee;
    margin: 0.75em 0 1em;
}

.ShippingBilling_Section .form-body .error-msg{
    color: red;
}

.ShippingBilling_Section .react-tel-input{
    margin-block: 0.65em !important;
}

.ShippingBilling_Section .number-input{
    
    width: 100%;
    height: 40px !important;
}

.ShippingBilling_Section .react-select-container{
    margin-block: 0.75em !important;
}

.ShippingBilling_Section .react-select{
    border-radius: 0;
}

.ShippingBilling_Section .r-select__input-container{
    height: 38px !important;

}

.ShippingBilling_Section .r-select__placeholder{
    margin-top: 5px !important;
}

.ShippingBilling_Section .copy_to_shipping{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-block: 1em;
}

.ShippingBilling_Section .copy_to_shipping label{
    margin: 0;
}

.ShippingBilling_Section .copy_to_shipping_input{
    width: 20px;
    height: 20px;
    accent-color: var(--btn_primary_color);
    box-shadow: inset 0px 0px 0px 2px var(--btn_primary_color);
}

.ShippingBilling_Section .submit-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    text-transform: uppercase;
    padding: 0.75em 2em;
    margin: 1.5em auto 0.75em;
    display: inline-block;
    font-size: large;
}

@media only screen and (min-width:690px) {
    .ShippingBilling_Section .form-wrapper .form-body{
        gap: 1em;
    }
}


@media only screen and (min-width:992px) {

    .ShippingBilling_Section .form-wrapper .form-body{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        
    }
}
