/*-----------------------------------------------------------------------------------

    Item Name: Ekka - Ecommerce HTML Template.
    Author: ashishmaraviya
    Version: 3.3
    Copyright 2022-2023
	Author URI: https://themeforest.net/user/ashishmaraviya

-----------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------
    Background Image-4
-----------------------------------------------------------------------------------*/
.body-bg-4 {
  background-color: #fff;
}
/*# sourceMappingURL=bg-4.css.map */