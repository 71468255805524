
/***** ABOUT US *********************************************/

.aboutUs .externalHTML.CHAINHOUSE24{
    height: 2850px;
}

.aboutUs .externalHTML.CHAINHOUSE24::-webkit-scrollbar{
    display: none !important;
}

@media only screen and (max-width: 768px){
.aboutUs .externalHTML.CHAINHOUSE24{
        height: 2795px;
    }
}


/***** CONTACT US *********************************************/
.contact-us .externalHTML.CHAINHOUSE24{
    height: 1200px;
}


@media only screen and (max-width: 768px){
    .contact-us .externalHTML.CHAINHOUSE24{
            height: 720px;
        }
}