.full-screen-image-container{
    height: 100vh;
    padding: 1em;
    position: relative;
}

.full-screen-image-container .close-btn{
    position: absolute;
    font-size: 3rem;
    line-height: normal;
    right: 0.5em;
    background-color: var(--btn_primary_color);
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 2;
    color: var(--btn_primary_foreground_color);
    cursor: pointer;
}



.full-screen-image-container .slider-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
}



.full-screen-image-container .main-image-preview{
    height: calc(95vh - 100px);
    border: 1px solid #eee;
    border-radius: 0.3rem;
    position: relative;

}

.full-screen-image-container .main-image-preview.full-height{
    height: 95vh;
}

.full-screen-image-container .main-image-preview .zoom-in{
    position: absolute;
    z-index: 1;
    bottom: 0.75em;
    right: 0.75em;
}

.full-screen-image-container .main-image-preview .zoom-in .zoom-in-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    line-height: normal;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    
}

.full-screen-image-container .main-image-preview *:not(.slick-arrow, .tools, button, .zoom-in){
    height: inherit;
}

.full-screen-image-container .main-image-preview .main-zoom-img{
    object-fit: scale-down;
}

.full-screen-image-container .thumb-image-preview{
    margin-top: auto;
    height: 100px;
}

.full-screen-image-container .thumb-image-preview img{
    object-fit: contain;
}

.full-screen-image-container .thumb-image-preview .slick-track{
    display: flex;
    gap: 0.5em;
}

.full-screen-image-container .thumb-image-preview *:not(.slick-arrow){
    height: inherit;
}

.full-screen-image-container .main-img-container{
    display: flex !important;
    justify-content: center;
}

.full-screen-image-container .thumb-img-container{
    display: flex !important;
    justify-content: center;
    border: 1px solid #eee;
    border-radius: 0.3rem;
    overflow: hidden;
    
}

.full-screen-image-container .thumb-image-preview .slick-current .thumb-img-container{
    border: 1px solid var(--highlight_color);
}