/*------ Custom Order Card ---------------------------------------------------------------------------*/
.custom-order-body-cards-section {
  display: grid;
  row-gap: 1em;
  column-gap: 1em;
  padding-block: 1em;
}

.custom-order-body-cards-section .custom-order-card {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.06);
  min-height: 200px;
  max-height: 275px !important;
  cursor: pointer;
  position: relative;
}

.custom-order-body-cards-section .custom-order-card.itemList{
  min-height: 75px;
  max-height: 180px !important;
}

.custom-order-body-cards-section .custom-order-card .image-section {
  flex: 0 0 115px;
  position: relative;
}

.custom-order-body-cards-section
  .custom-order-card
  .image-section
  .image-count {
  position: absolute;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.25em;
  border-radius: 5px;
  bottom: 0.25em;
  right: 0.25em;
  display: flex;
  gap: 0.15em;
  font-size: 1.15rem;
  align-items: center;
}

.custom-order-body-cards-section .custom-order-card .image-section > section,
.custom-order-body-cards-section .custom-order-card .image-section > section > div
{
    height: 100%;
}

.custom-order-body-cards-section .custom-order-card .image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-order-body-cards-section .custom-order-card .details-section {
  flex: 2;
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.35em;
}

.custom-order-body-cards-section .custom-order-card.itemList .details-section{
  display: flex ;
}

.custom-order-body-cards-section .custom-order-card .details-section .top-section{
  display: flex;
}

.custom-order-body-cards-section .custom-order-card .order-detail h2 {
  font-size: 1.45rem;
  font-weight: 600;
}

.custom-order-body-cards-section .custom-order-card .order-detail h3 {
  font-size: 1.45rem;
  font-weight: 600;
  /* opacity: 0.85; */
}

.custom-order-body-cards-section .custom-order-card .date-section {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  opacity: 0.75;
  margin-left: auto;
  text-align: right;
}





.custom-order-body-cards-section .custom-order-card .date-section span {
  line-height: normal;
  /* background-color: #a6ebb6; */
  /* background-color: var(--highlight_color); */
  display: flex;
  align-items: center;
  /* padding: 0.35em; */
  border-radius: 0.3rem;
  color: #383838;
  font-size: 1.15rem;
  /* font-weight: 600; */
  /* text-transform: uppercase; */
  margin-left: auto;
}



.custom-order-body-cards-section .custom-order-card .del-icon-wrapper:hover {
  color: red;
}

.custom-order-body-cards-section
  .custom-order-card.itemList
  .del-icon-wrapper {
  display: block;
}

.custom-order-body-cards-section
  .custom-order-card
  .del-icon-wrapper
  .del-icon {
  font-size: 2.3rem;
  color: inherit;
}

.custom-order-body-cards-section .custom-order-card .middle-section {
  /* font-weight: 600; */
  /* font-style: italic; */
  color: #3b3b3b;
  font-size: 1.35rem;
}

.custom-order-body-cards-section .custom-order-card .tag{
  display: inline-block;
  align-self: flex-start;
  font-size: 1.45rem;
  font-style: normal;
  border-radius: 0.3rem;
  padding: 0.25em 0.5em;
  font-weight: 400;
}

.custom-order-body-cards-section .custom-order-card.itemList .tag{
  display: none;
}

.custom-order-body-cards-section .custom-order-card .bottom-section {
  font-size: 12px;
  /* font-weight: 600; */
  color: #212121;
  position: relative;
  margin-top: auto;
}

.custom-order-body-cards-section .custom-order-card.itemList .bottom-section{
  margin-top: initial;
  width: calc(100% - 35px);
}

.custom-order-body-cards-section .custom-order-card.itemList .del-icon-wrapper{
  width: fit-content;
  position: absolute;
  right: 0.5em;
}

.custom-order-body-cards-section .custom-order-card.itemList .bottom-section::before {
  display: none;
}

.custom-order-body-cards-section .custom-order-card .bottom-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #c5c5c5;
  top: -0.45em;
  opacity: 0.5;
}

/*--------- Custom Order Card => Table: 768px and larger --------------------------------------------*/
@media only screen and (min-width: 768px) {
  .custom-order-body-cards-section .custom-order-card .top-section {
    display: flex;
    justify-content: space-between;
  }

  .custom-order-body-cards-section .custom-order-card .bottom-section {
    margin-top: auto;
  }

  .custom-order-body-cards-section .custom-order-card .image-section {
    max-height: 200px;
    flex: 0 0 150px;
  }
}

@media only screen and (min-width: 992px) {
  .new-custom-order-wrapper .custom-order-body-cards-section,
  .custom-order-section .custom-order-body-cards-section {
    grid-template-columns: repeat(2, 1fr);
  }

  .custom-order-body-cards-section .custom-order-card .order-detail h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .custom-order-body-cards-section .custom-order-card .order-detail h3 {
    font-size: 1.5rem;
  }

  .custom-order-body-cards-section .custom-order-card .middle-section {
    font-size: 1.4rem;
  }

  .custom-order-body-cards-section .custom-order-card .bottom-section {
    font-size: 1.3rem;
  }

  .custom-order-body-cards-section .custom-order-card .date-section span {
    font-size: 1.3rem;
  }
}
