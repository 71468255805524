.news-letters-page{
    padding: 3em;
    width: min(1440px, 100%);
    margin: 0 auto !important;
}

.news-letters-page header{
    position: relative;
}

.news-letters-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    text-align: center;

}

.news-letters-page header a{
    position: absolute;
}

.news-letters-page .news-letters-section{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    margin-block: 2.5em;
}

.news-letters-page .news-letters-card{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: rgba(76, 52, 31, 0.4) -5px 5px, rgba(76, 52, 31, 0.3) -10px 10px, rgba(76, 52, 31, 0.2) -15px 15px, rgba(76, 52, 31, 0.1) -20px 20px, rgba(76, 52, 31, 0.05) -25px 25px;
    
}

.news-letters-page .news-letters-card img{
    width: 100%;
    
}

.news-letters-page .news-letters-card p{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 1em;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-align: center;
    margin-top: auto;
}

@media only screen and (max-width: 992px) {
    .news-letters-page .news-letters-section{
        grid-template-columns: repeat(2, 1fr);
        
    }

    .news-letters-page .news-letters-card p{
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 475px) {
    .news-letters-page .news-letters-section{
        grid-template-columns: 1fr;
        
    }

    .news-letters-page .news-letters-card p{
        font-size: 1.4rem;
        padding: 0.5em;
    }
}