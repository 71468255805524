.edit_profile_section{
    width: min(700px, 100%);
    margin: 0 auto;
    padding: 1em;
    
    
}



.edit_profile_section header h2{
    text-align: center;
    color: var(--highlight_color, #555);
    font-weight: 800;
}
 

.edit_profile_section .edit_profile_form{
    padding: 1em 2em;
    border: 1px solid var(--menu_separator_color, #ededed);
    border-radius: 5px;
    background-color: var(--login_register_bg_color);
}

.edit_profile_section .update-profile-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    width: 100%;
    margin-block: 1em;
    padding: 0.5em;
    text-transform: uppercase;
    border-radius: 0.3rem;
}