.chainhouse24-live-rate-app-page .MuiBox-root{
    border-radius: 0;
}

/***** HEADER ***********************************/

.chainhouse24-live-rate-app-page .live-rate-section header{
    padding: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
 }

.chainhouse24-live-rate-app-page .live-rate-section header h2{
    /* display: none; */
    /* font-weight: 600; */
    text-transform: uppercase;
    font-size: 3rem;
    color: var(--btn_primary_color);
}

.chainhouse24-live-rate-app-page .live-rate-section header .close-btn{
    font-size: 2.2rem;
    color: #212121;
    
}

/***** live-rate-body ***********************************/
.chainhouse24-live-rate-app-page .live-rate-body{
    position: relative;
}

.chainhouse24-live-rate-app-page .last-update-bar{
    display: flex;
    padding: 0.5em;
    background-color: var(--live_rate_full_timebar_bg_color);
    color: var(--live_rate_full_timebar_fg_color);
    font-weight: 500;
    justify-content: center;
    gap: 0.5em;
    
}

/***** live-rate-container ***********************************/
.chainhouse24-live-rate-app-page .live-rate-container{
    display: flex;
    flex-direction: column;
    /* background-color: #f8f6f7; */
    width: min(800px,100%);
    margin: 0 auto 2em;
    /* box-shadow: 0px 0px 15px rgba(0,0,0,0.1); */
    /* border: 1px solid #d9d9d9; */
    border-radius: 0.3rem;
    /* overflow: hidden; */
}

.chainhouse24-live-rate-app-page .live-rate-container .rows{
    display: flex;
    padding: 1em;
    background-color: #fff;
    color: #212121;
    align-items: center;
    gap: 0.5em;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.15);
    border: 1px solid #d9d9d9;
    min-height: 50px;
    margin-block: 0.5em;
    border-radius: 15px;
}


.chainhouse24-live-rate-app-page .live-rate-container .header.rows{
    /* background-color: #f8f6f7; */
    font-size: 1.7rem;
    font-weight: 600;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    
   
}

.chainhouse24-live-rate-app-page .live-rate-container .rows .name{
    flex: 3;
    font-weight: 600;
    display: flex;
    gap: 1em;
    margin-left: 1.5em;
    align-items: center;
}

.chainhouse24-live-rate-app-page .live-rate-container .rows .short_name{
    background-color: var(--live_rate_full_icon_bg_color);
    color: var(--live_rate_full_icon_fg_color);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.chainhouse24-live-rate-app-page .live-rate-container .rows .name_group{
    display: flex;
    flex-direction: column;
    
}

.chainhouse24-live-rate-app-page .live-rate-container .rows .display_name{
    font-size: 1.6rem;
    color: var(--live_rate_full_cell_fg_color)
}

.chainhouse24-live-rate-app-page .live-rate-container .rows .desc_name{
    color: var(--live_rate_full_cell_fg_color);
    font-size: 1.3rem;
    font-weight: 400;
}

.chainhouse24-live-rate-app-page .live-rate-container .rows > div:not(.name){
    flex: 1;
    text-align: center;
}

.chainhouse24-live-rate-app-page .live-rate-container .rows .buy,
.chainhouse24-live-rate-app-page .live-rate-container .rows .sell{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chainhouse24-live-rate-app-page .right-align-text .block{
   
    text-align: right;
}

.chainhouse24-live-rate-app-page .live-rate-container .rows .buy .block,
.chainhouse24-live-rate-app-page .live-rate-container .rows .sell .block
{
    font-size: 1.6rem;
    font-weight: 600;
}



.chainhouse24-live-rate-app-page .live-rate-row:has(.buy) .sell .block{
    font-weight: 400;
}


.chainhouse24-live-rate-app-page .live-rate-container .rows .block{
    /* background-color: var(--live_rate_boxed_default_bg_color); */
    color: var(--live_rate_boxed_default_fg_color);
    padding: 0.25em 0.35em;
    min-width: 150px;
}

.chainhouse24-live-rate-app-page .block.red-block{
    color: var(--live_rate_boxed_red_bg_color) !important;
    /* color: var(--live_rate_boxed_red_fg_color) !important; */
}

.chainhouse24-live-rate-app-page .block.green-block{
    color: var(--live_rate_boxed_green_bg_color) !important;
    /* color: var(--live_rate_boxed_green_fg_color) !important; */
}

.chainhouse24-live-rate-app-page .live-rate-container .rows .small{
    font-size: 1.3rem;
}


.chainhouse24-live-rate-app-page .live-rate-container .section + .section{
    margin-top: 1em;
}

.chainhouse24-live-rate-app-page .live-rate-container .contact-details{
    margin-block: 1em !important;
}

.chainhouse24-live-rate-app-page .live-rate-container .contact-details p{
    color: #000000;
    font-size: 1.8rem;
}

.chainhouse24-live-rate-app-page .live-rate-container .contact-details a{
    font-size: 1.6rem;
    color: var(--live_rate_contact_fg_color);
    text-decoration: underline;
    
}

@media only screen and (max-width: 576px){
    .chainhouse24-live-rate-app-page .live-rate-section header h2{
        display: block;
        font-size: 2.6rem
    }

    

    .chainhouse24-live-rate-app-page .live-rate-section header{
        margin: 0;
    }

    .chainhouse24-live-rate-app-page .live-rate-container{
        box-shadow: none;
        border: none;
        padding: 0 1em;
    }

    .chainhouse24-live-rate-app-page .live-rate-container .rows .block{
        min-width: 90px;
    }

    .chainhouse24-live-rate-app-page .live-rate-container .rows .name{
        margin-left: 0;
        
    }


    .chainhouse24-live-rate-app-page .live-rate-container .contact-details p{
        font-size: 1.6rem;
    }

    .chainhouse24-live-rate-app-page .live-rate-container .contact-details a{
        font-size: 1.4rem;
    }

}