.mui-delete-modal .delete-section{
    display: flex;
    flex-direction: column;
    gap: 2em;
    
}

.mui-delete-modal .delete-section header{
    text-align: center;
}

.mui-delete-modal .delete-section header p{ 
    font-size: 1.5rem;
    color: var(--named_wishlist_popup_fg_color);
}


.mui-delete-modal .delete-section .btns{
    display: flex;
    gap: 1em;
}

.mui-delete-modal .delete-section .btns button{
    flex: 1;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.35em 0.75em;
    border-radius: 0.3rem;
    text-transform: uppercase;
}