.finalize-order-tags-modal .MuiBox-root{
    border-radius: 0.3rem;
}

.finalize-order-tags-modal header{
    display: flex;
    justify-content: space-between;
    padding: 1em;
    border-bottom: 1px solid #eeeeee;
    align-items: center;
}

.finalize-order-tags-modal header .close-icon{
    font-size: 2rem;
    color: #212121;
    cursor: pointer;
}

/*=========== order-tag-modal-body ========================================*/
.finalize-order-tags-modal .order-tag-modal-body {
    position: relative;
    overflow-y: auto;
}

.finalize-order-tags-modal .order-tag-modal-body .search-container{
    /* padding: 1em; */
    position: sticky;
    display: flex;
    align-items: center;
    top: 0;
}

.finalize-order-tags-modal .order-tag-modal-body .search-container input{
    margin: 0 !important;
    padding-right: 3em;
}

.finalize-order-tags-modal .order-tag-modal-body .search-container .search-icon{
    position: absolute;
    right: 1.5em;
}

.finalize-order-tags-modal .order-tag-modal-body ul{
    overflow-y: auto;
    height: 70vh;
}

.finalize-order-tags-modal .order-tag-modal-body ul li{
    padding: 0 0.75em !important;
    display: flex;
    gap: 1em;
    align-items: center;
}

.finalize-order-tags-modal .order-tag-modal-body ul li + li{
    border-top: 1px solid #eeeeee;
    
}

.finalize-order-tags-modal .order-tag-modal-body ul li input{
    width: 20px;
    height: 20px;
    margin: 0 !important;
    accent-color: var(--btn_primary_color);
    box-shadow: inset 0 0 0 2px var(--btn_primary_color);
    cursor: pointer;
    
}

.finalize-order-tags-modal .order-tag-modal-body ul li label{
    margin: 0 !important;
    /* background-color: red; */
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.75em 0 !important;
    cursor: pointer;
   
}



.finalize-order-tags-modal footer{
    display: flex;
    padding: 1em;
    gap: 1em;
}

.finalize-order-tags-modal footer .button{
    flex: 1;
    text-align: center;
    text-transform: uppercase;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.5em !important;
    border-radius: 0.3rem;
    cursor: pointer;
}