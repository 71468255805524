/********** HEADER ***************************/

.mui-side-slider-option .mui-sidebar-header{
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--menu_separator_color, #eeeeee);
    background-color: var(--login_register_bg_color);
    text-transform: uppercase;
    color: var(--highlight_color);
    min-height: 65px;
}

.mui-side-slider-option .mui-sidebar-header h2{
    font-size: 2rem;
    color: inherit;
}

.mui-side-slider-option .mui-sidebar-header .close-icon{
    font-size: 2rem;
    cursor: pointer;
}

/**************** MUI BOX CONTAINER ***********************/
.mui-side-slider-option .MuiDrawer-paper{
    width: var(--sidebar-container-width) !important;
    
} 