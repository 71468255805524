.user-prefernce-modal .MuiBox-root{
    border-radius: 0;
    outline: none;
}

/************* HEADER *******************************/

.user-prefernce-modal .main-header{
    padding: 0.75em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fefefe;
    border-bottom: 1px solid #cccccc;
    
}

.user-prefernce-modal .user-preference-section{
    max-height: 80vh;
    overflow: auto;
    position: relative;
}

.user-prefernce-modal .main-header h3{
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: normal;
    margin: 0 auto;
    color: var(--highlight_color);

    
}

.user-prefernce-modal .main-header .close-icon{
    /* margin-left: auto; */
    cursor: pointer;
    font-size: 2.3rem;
    color: var(--highlight_color);

}

/************* Body *******************************/

.user-prefernce-modal .user-defaults > *:not(header),
.user-prefernce-modal .desc,
.user-prefernce-modal footer
{ 
    padding: 1em;
}

.user-prefernce-modal .desc{
    color: #212121;
    text-align: center;
    font-weight: 500;
    padding-block: 2em;
}

.user-prefernce-modal  .user-defaults-body{
    padding-block: 1.5em !important;
}

.user-prefernce-modal .user-defaults header{
    border-bottom: 1px solid #777;
    line-height: normal;
}

.user-prefernce-modal .user-defaults header h4{
    margin: 0 1em;
    text-transform: uppercase;
    line-height: normal;
    display: inline-block;
    font-weight: 600;
    color: var(--highlight_color);
    border-bottom: 2px solid var(--highlight_color);
    position: relative;
    top: 1px;
    padding-bottom: 0.5em;

}

.user-prefernce-modal .user-defaults-body .input-groups .label{
    font-size: 1.4rem !important;
    margin: 0 !important;
    margin-bottom: 0.75em !important;
    display: block;
    color: #212121;
    font-weight: 400;

}

.user-prefernce-modal .user-defaults-body .input-groups .inputs{
    display: flex;
    gap: 1em;
}

.user-prefernce-modal .user-defaults-body .input-groups label{
    border: 1px solid var(--highlight_color);
    padding: 0.35em 0.75em;
    border-radius: 0.3rem;
    cursor: pointer;
    color: var(--highlight_color);
}

.user-prefernce-modal .user-defaults-body .input-groups input:checked + label{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
}

.user-prefernce-modal footer{
    display: flex;
    gap: 1em;
    position: sticky;
    bottom: 0;
}

.user-prefernce-modal footer button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    flex: 1;
    padding: 0.5em;
    text-transform: uppercase;
    border-radius: 0.3rem;
}