.request-access-modal .modal-dialog{
    
    width: min(450px, 100%);
    height: auto;
}



.request-access-modal .modal-body{
    display: flex;
    flex-direction: column;
    gap: 3.5em;
    align-items: center;
}

.request-access-modal .company-logo{
    height: var(--web_company_logo_height);
    max-height: var(--web_company_logo_max_height);
}

.request-access-modal .company-name{
    font-size: 1.5rem;
    color: #212121;
    font-weight: 600;
    margin-top: 0.5em;
}

.request-access-modal h2{
    font-size: 2rem ;
}

.request-access-modal p{
    font-size: 1.6rem ;
    /* color: #212121; */
}

.request-access-modal footer{
    width: 100%;
}

.request-access-modal .mobile-no-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.6rem;
    
}

.request-access-modal .mobile-no-readOnly{
    font-weight: 600;
    color: #444444 !important;
}

.request-access-modal .change-no-btn{
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin-left: auto;
    
}

.request-access-modal .mobile-no-section .react-tel-input{
    border-bottom: 1px solid #aaa;
    
}

.request-access-modal .request-access-telephone{
    width: 100%;
}

.request-access-modal .request-access-telephone, .request-access-modal .request-access-telephone-country{
    border: 0 !important;
    border-radius: 0;
    
}

.request-access-modal .request-access-telephone-country .country-list {
    text-align: left;
}

.request-access-modal .request-access-telephone{
    width: fit-content;
    padding-right: 0;
    font-size: 1.6rem !important;
}



.request-access-modal .mobile-no-readOnly{
    font-weight: 600;
    color: #212121;
}

.request-access-modal .change-no-btn{
    color: inherit;
    text-decoration: underline dashed;
    text-underline-offset: 5px;
    
}

.request-access-btn{
    margin-top: 1em !important;
    --font-size: 1.4rem;
    display: flex;
    align-items: center;
    gap: 0.5em;
    justify-content: center;
    padding: 0.75em 1em;
    text-transform: uppercase;
    font-weight: 600;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    border-radius: 0.3rem;
    margin: 0 auto;
    width: 100%;
    letter-spacing: 1px;
    line-height: normal;
}

.request-access-btn .shield-icon{
    font-size: calc(var(--font-size) * 1.2);
}