.otp-form-modal-body{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.Otp-container{
    display: grid !important;
    grid-template-columns: repeat(6, 35px);
    grid-template-rows: 35px;
    gap: 0.5em;
    justify-content: center;
}

.Otp-container input{
    display: block;
    width: 100% !important;
    height: 100%;
    padding: 0;
}

.otp-form-modal-body .verify-opt-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.25em;
    width: min(200px, 100%);
    margin: 0 auto;
    text-transform: uppercase;
    cursor: pointer;
}

@media only screen and (min-width:768px) {
    .otp-form-modal-body{
        gap: 0.75em;
    }

    .Otp-container{
        grid-template-columns: repeat(6, 50px);
        grid-template-rows: 50px;
        gap: 1em;
    }
}