.home-banner-slider .slick-list{
    position: relative;

}

.home-banner-slider .slick-list::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
    bottom: 0;
    z-index: 1;
}

.home-banner-slider.shift-gradient-upward .slick-list::before{
    bottom: 5px;
}

.home-banner-slider .home-banner-slick-dots{
    position: absolute;
    bottom: 20px;
    z-index: 2;
    width: 100%;
}

.custom-banner-star-dot{
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
}


.custom-banner-star-dot .start-dot-img{
    width: 100%;
    position: absolute;
}

.custom-banner-star-dot .start-dot-img:not(.selected){
    opacity: 0.60;
}

.custom-banner-star-dot .start-dot-img.selected{
    background: none !important;
    border: none !important;
}

.home-banner-slick-dots li.slick-active .start-dot-img:not(.selected){
    display: none;
}

.home-banner-slick-dots li:not(.slick-active) .start-dot-img.selected{
    display: none;
}

@media only screen and (max-width: 576px){
    .custom-banner-star-dot{
        width: 20px;
        height: 20px;

    }

    .home-banner-slider .slick-list::before{
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    }
}