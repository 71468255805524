.custom-order-details-container{
    width: var(--box-container-width);
    margin: 0 auto;
    
}

.custom-order-details-container .custom-order-details-title{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.25em 0 0.5em;
    text-transform: uppercase;
    padding: var(--cart-group-by-padding);
    color: #777777;
}

.custom-order-details-container .custom-order-details-body {
    padding: var(--cart-group-by-padding);
    padding-bottom: 4rem;
}

/* ---------- Custom Order Detail Image Container --------------------- */

.custom-order-detail-image-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.custom-order-details-body .custom-order-detail-image-container{
    margin-bottom: 1em;
}

.custom-order-details-body .custom-order-detail-image-container .main-image-preview{
    height: 60vh;
    border: 1px solid #eee;
    border-radius: 0.3rem;
    position: relative;
}

.custom-order-detail-image-container .main-image-preview .zoom-in{
    position: absolute;
    z-index: 1;
    bottom: 0.75em;
    right: 0.75em;
}

.custom-order-detail-image-container .main-image-preview .zoom-in .zoom-in-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    line-height: normal;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    
}

.custom-order-details-body .custom-order-detail-image-container .main-image-preview *:not(.slick-arrow, .tools, button, .zoom-in){
    height: inherit;
}

.custom-order-details-body .custom-order-detail-image-container .main-image-preview .main-img{
    object-fit: contain;
}

.custom-order-details-body .custom-order-detail-image-container .thumb-image-preview{
    height: 100px;
}

.custom-order-details-body .custom-order-detail-image-container .thumb-image-preview .slick-track{
    display: flex;
    gap: 0.5em;
}

.custom-order-details-body .custom-order-detail-image-container .thumb-image-preview *:not(.slick-arrow){
    height: inherit;
}

.custom-order-details-body .custom-order-detail-image-container .main-img-container{
    display: flex !important;
    justify-content: center;
}

.custom-order-details-body .custom-order-detail-image-container .thumb-img-container{
    display: flex !important;
    justify-content: center;
    border: 1px solid #eee;
    border-radius: 0.3rem;
    overflow: hidden;
}

.custom-order-detail-image-container .thumb-image-preview .slick-current .thumb-img-container{
    border: 1px solid var(--highlight_color);
}

.custom-order-detail-image-container .thumb-image-preview img{
    object-fit: contain;
    
}

.custom-order-detail-image-container .main-image-preview img{
    object-fit: contain;
}


/* ---------- Custom Order Detail Detail Section --------------------- */

.custom-order-details-body .custom-order-details-body-detail-section{
    
        display: flex;
        flex-direction: column;
        gap: 1em;
        
}


.custom-order-details-body .custom-order-other-details{
    width: 100%;
    border-radius: 5px;
    padding: 0.5em;
    background-color: #fff;
    box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.06);
}

.custom-order-details-body .custom-order-other-details ul li {
    padding-block: 0.25em;
}

.custom-order-details-body .custom-order-other-details ul li{
    font-size: 1.4rem;
    color: #212121;
    display: flex;
    justify-content: space-between;
}

.custom-order-details-body .custom-order-other-details ul li span:first-child{
    font-weight: 600;
}

.custom-order-details-body .custom-order-other-details ul li + li{
    border-top: 1px solid #eeeeee;
}


.custom-order-details-body  .custom-order-details-status{
    border-radius: 5px;
    padding: 0.5em;
    background-color: #fff;
    box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.06);

}

.custom-order-details-body .custom-order-details-status h3{
    font-weight: 600;
    color: var(--highlight_color);
}

.custom-order-details-body  .custom-order-details-status .custom-order-details-status-details{
    display: flex;
    gap: 0.5em;
    align-items: flex-start;
}

.custom-order-details-body  .custom-order-details-status .check-icon{
    font-size: 2rem;
    color: var(--highlight_color);
}

.custom-order-details-body  .custom-order-details-status .custom-order-details-status-details h4{
    font-weight: 600;
}

.custom-order-details-container .cancel-order-btn-container{
    
    display: flex;
    justify-content: center;
    
    
}

.custom-order-details-container .cancel-order-btn-container.sticky{
    padding: 0.5em;
    background-color: #fff;
    position: sticky;
    box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);
    z-index: 1;
}

.custom-order-details-container .cancel-order-btn-container.inner-btn.sticky{
    box-shadow: none;
    bottom: 0;
    margin-top: auto;
}

.custom-order-details-container .cancel-order-btn-container button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.5em 1.5em;
    border-radius: 0.3rem;
    width: min(350px, 100%);
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.45rem;
}


/*-------- MEDIA QUERY --------------------------------------------------------------------------------------------------------------------*/
@media only screen and (min-width:992px) {
   

    .custom-order-details-body{
        display: flex;
        gap: 1em;
    }

    .custom-order-details-body .custom-order-other-details ul li{
        font-size: 1.65rem;
    }

    .custom-order-details-body  .custom-order-details-status .custom-order-details-status-details h4{
        font-size: 1.6rem;
    }

    .custom-order-details-body .custom-order-detail-image-container{
        width: 70vh;
    }

    .custom-order-details-body .custom-order-details-body-detail-section{
        flex: 1;
    }

    .custom-order-details-body .custom-order-detail-image-container .main-image-preview{
        height: 50vh;
    }
}