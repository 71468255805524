.change-currency-modal .currency-container{
    display: flex;
    gap: 4em;
    justify-content: space-between;
    padding-inline: 1em;
}

.change-currency-modal .MuiBox-root,
.change-currency-modal .MuiBox-root:focus,
.change-currency-modal .MuiBox-root:hover,
.change-currency-modal .MuiBox-root:focus-visible,
.change-currency-modal .MuiBox-root:focus-within{
    outline: none;
}

.change-currency-modal .currency-container .currency{
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    cursor: pointer;
}

.change-currency-modal .company-logo{
    position: absolute;
    width: 100px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-shrink: 0;
}

.change-currency-modal header{
    text-align: center;
    margin-block: 3em;
}

.change-currency-modal .currency-container .currency-icon{
     height: 100px;
}