.custom-order-modal{
    padding: 0;
}


.custom-order-modal .modal-dialog{
    min-height: 85vh;
    max-height: 100vh;
    width: min(600px, 100%);
}


.custom-order-modal-body{
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.custom-order-modal-body form{
    display: flex;
    flex-direction: column;
    gap: 0.25em;
}

/*----------- MODAL TITLE ------------------------------*/
.custom-order-modal .custom-order-modal-header{
    display: flex;
    align-items: center;
}

.custom-order-modal .custom-order-modal-header .back-arrow-icon{
    font-weight: 600;
    font-size: 2.3rem;
}



.custom-order-modal .custom-order-modal-header .btn-close{
    display: none;
}

/*--------- STEPPER HEADER ------------------------------*/

.custom-order-modal-body header{
    justify-content: space-between;
    display: flex;
    padding: 1em;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    align-items: center;
    position: sticky;
    z-index: 1;
    top: 0;
    /* line-height: normal; */
}

.custom-order-modal-body header h3{
    color: var(--btn_primary_foreground_color);
    text-align: left;
}

.custom-order-modal-body header .steps{
    border: 1px solid var(--btn_primary_foreground_color);
    padding: 0.5em 2em;
    border-radius: 999px;
    flex-shrink: 0;
    line-height: normal;
}

/*--------- PRODUCT LIST (STEP 1) ------------------------------*/


.custom-order-modal-body .product-list li{
    padding: 0.5em;
    text-align: left;
    cursor: pointer;
    color: #212121;
}

.custom-order-modal-body .product-list:not(.button-view) li + li {
    border-top: 1px solid #eee;
}

.custom-order-modal-body .product-list.button-view{
    display: flex;
    gap: 1em;
    padding: 1em;
    flex-wrap: wrap;
}

.custom-order-modal-body .product-list.button-view li{
    border: 1px solid var(--btn_primary_color);
    color: var(--btn_primary_color);
    border-radius: 0.3rem;
}

.custom-order-modal-body .product-list.button-view li.selected{
    background-color: var(--btn_primary_foreground_color);
    color: var(--btn_primary_foreground_color);
}

/*--------- PRODUCT LIST (STEP 2) ------------------------------*/

.custom-order-modal-body .product-form{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

/*--- Common Styling ---*/
.custom-order-modal-body .form-input-group{
    display: flex;
    flex-direction: column;
    gap: 0.25em;
}

.custom-order-modal-body form{
    height: 100%;
}

.custom-order-modal-body .form-input-group label{
    margin: 0 !important;
    font-size: 1.5rem;
    color: #444444;
    text-align: left;
}

.custom-order-modal-body .form-input-group.disabled label{
    opacity: 0.6;
}

.custom-order-modal-body .form-input-group.disabled input{
    background-color: lightgray;
}

.custom-order-modal-body .form-input-group label .required{
    color: red
}

.custom-order-modal-body .form-input-group input{
    margin: 0;
   border-color: var(--login_register_fg_color);
}

.custom-order-modal-body .form-input-group input[type="text"]{
    padding-left: 10px;
}

/*--- FILE INPUT ---*/
.custom-order-modal-body .product-form .file-input-container input{
    all: unset;
    padding: 0.5em;
    border: 1px solid #eeeeee;
    border-radius: 0.3rem;
    border-color: var(--login_register_fg_color);
}

.custom-order-modal-body .product-form .file-input-container .images-container{
    padding: 0.5em 0;
    display: flex;
    gap: 0.35em;
    flex-wrap: wrap;
    align-items: center;
}

.custom-order-modal-body .product-form .file-input-container .image-wrapper{
    flex: 0 1 85px;
    position: relative;
    overflow: hidden;
    border-radius: 0.3rem;
    border: 1px solid var(--btn_primary_color);
    
}

.custom-order-modal-body .product-form .file-input-container .image-wrapper img{
    height: 100px;
    width: 100px;
    object-fit: contain;
    cursor: pointer;
}

.custom-order-modal-body .product-form .file-input-container .image-wrapper .number{
    position: absolute;
    bottom: 0;
    left: 0;
    color: var(--btn_primary_foreground_color);
    background-color: rgba(0,0,0,0.5);
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.65em;
    display: none;
    
}

.custom-order-modal-body .product-form .file-input-container .image-wrapper .close-btn{
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background-color: crimson;
    width: 15px;
    height: 15px;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.65em;
    cursor: pointer;
    border-bottom-left-radius: 0.3rem;
}

/*--- REACT SELECT DROPDOWN ---*/

.custom-order-modal-body .form-input-group .r-container{
    box-sizing: border-box;
}

.custom-order-modal-body .form-input-group .r-container{
    text-align: left;
}




.custom-order-modal-body .form-input-group .dropdown-with-btn-container{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 1em;
}



.custom-order-modal-body .form-input-group .dropdown-with-btn-container .add-btn{
    text-transform: uppercase;
    padding: 0.25 1em;
    border: 1px solid var(--btn_primary_color);
    color: var(--btn_primary_color);
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    cursor: pointer;
}

.custom-order-modal-body .form-input-group .r-select__control{
    border-radius: 0 !important;
}

.custom-order-modal-body .form-input-group .r-select__control, 
.custom-order-modal-body .form-input-group .r-select__value-container
{
    height: 40px;
}

.custom-order-modal-body .form-input-group .r-select__input-container{
    height: 35px;
}


.custom-order-modal-body .form-input-group .error-input{
    text-align: left;
    color: red;
}

.custom-order-modal-body .form-input-group .error-input{
    font-size: 1.4rem !important;
}

/*--- Text INPUT WITH TEXT ---*/
.custom-order-modal-body .form-input-group .text-input-group{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
}

.custom-order-modal-body .form-input-group .text-input-group .select-drop{
    position: absolute;
    padding: 0.5em;
    background-color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid #eeeeee !important;
    min-width: 60px;
    border-color: var(--login_register_fg_color) !important;
}





.custom-order-modal-body .form-input-group .text-input-group input{
    padding-right: 50px;
    border-color: var(--login_register_fg_color);
}

/*--- WEIGHT INPUT ---*/
.custom-order-modal-body .form-input-group .input-weight-group{
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.custom-order-modal-body .input-weight-group .input-section{
    position: relative;
    flex: 1;
}

.custom-order-modal-body .input-weight-group .input-section .text{
    position: absolute;
    padding: 0.5em;
    width: 60px;
    background-color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeeee;
}

.custom-order-modal-body .input-weight-group .input-section input{
    padding-left: 65px;
}

/*--- DATE INPUT ---*/
.custom-order-modal-body .form-input-group .date-calender{
    width: 50%;
    -webkit-user-select: none; /* Safari */
            -moz-user-select: none;    /* Firefox */
            -ms-user-select: none;     /* Internet Explorer/Edge */
            user-select: none; 
}

/*--- TEXTAREA INPUT ---*/
.custom-order-modal-body .form-input-group .textarea{
    padding: 0.5em;
    border: 1px solid #eeeeee;
    border-color: var(--login_register_fg_color);
}


/*--- BUTTONS INPUTS ---*/
.custom-order-modal-body .form-input-group .button-group-with-input,
.custom-order-modal-body .button-group
{
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
}

.custom-order-modal-body .form-input-group .button-group-with-input,
.custom-order-modal-body .button-group.dropdown-button{
    display: flex;
    gap: 0.5em;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.custom-order-modal-body .form-input-group .button-group-with-input,
.custom-order-modal-body .button-group.dropdown-button label{
    flex-shrink: 0;
}

.custom-order-modal-body .form-input-group .button-group-with-input input:disabled + label,
.custom-order-modal-body .button-group input:disabled + label,
.custom-order-modal-body .form-input-group input[type="text"]:disabled
{
    opacity: 0.7;
    cursor: default;
}

.custom-order-modal-body .button-group-with-input input:checked + label, 
.custom-order-modal-body .button-group input:checked + label
{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
}


.custom-order-modal-body .button-group-with-input label,
.custom-order-modal-body .button-group label
{
    background-color: var(--button_unselected_bg_color);
    color: var(--button_unselected_fg_color);
    display: block;
    padding: 0.5em;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    text-transform: uppercase;
    border: 1px solid var(--highlight_color);
    cursor: pointer;
    min-width: 40px;
    text-align: center;
}


.custom-order-modal-body .button-group-with-input input{
    margin: 0;
    width: 100px;
    align-self: flex-start;
    padding: 0.5em !important;
    border-radius: 0.3rem;
    border-color: #444444;
    
}

.custom-order-modal-body .button-group-with-input input::placeholder{
    color: #444444;
}

/*--- MODAL FOOTER ---*/
.custom-order-modal .custom-order-modal-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0.5em;
    gap: 0.5em;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.1);
    margin-top: auto;
}

.custom-order-modal .custom-order-modal-footer .order-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.5em 1.5em;
    border-radius: 0.3rem;
    width: min(460px, 100%);
    text-align: center;
    text-transform: uppercase;
    font-size: 1.45rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-order-modal .custom-order-modal-footer.multi-order .order-btn{
    width: auto;
    flex: 1;
}

.custom-order-modal .custom-order-modal-footer.multi-order .order-btn:disabled{
    opacity: 0.6;
}

.custom-order-modal .custom-order-modal-footer.multi-order .border-btn{
    background-color: var(--button_unselected_bg_color) !important;
    color: var(--button_unselected_fg_color) !important;
    border: 1px solid var(--button_unselected_border_color);
}


@media only screen and (max-width:576px) {
    .custom-order-modal .modal-dialog{
        padding: 0;
        margin: 0;
        height: 100vh !important;
        width: 100% !important;
        max-height: 100vh !important;
    }

    .custom-order-modal-body .form-input-group .date-calender{
        width: 100%;
    }
}

