.search-bar-comp{
    position: relative;
}

.search-bar-comp input{
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 5px;
    padding-inline: 35px 10px !important;
    
}

.search-bar-comp input::placeholder{
    color: #d8d8d8;
}

.search-bar-comp .search-icon{
    position: absolute;
    font-size: 2rem;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0.5em;
    color: var(--highlight_color);
}