.video-call-page{
    padding: 1.5em;
    width: min(1440px, 100%);
    margin: 0 auto;
}

.video-call-page .image-section{
    align-items: flex-start;
}


.video-call-page .detail-section .info-section ul li{
    list-style: disc !important;
    margin-left: 1em;
}

.video-call-page .detail-section .info-section h4{
    margin: 1em 0 0.25em;
    color: #555;
    font-weight: 600;
}

.video-call-page .detail-section .btn{
    margin-top: 1em ;
}



.video-call-page .detail-section .app-download-btns{
    display: flex;
    gap: 0.5em;
    margin-top: 2em;
}

.video-call-page .detail-section .app-download-btns img{
    height: 40px;
}