
.enquiry-form-modal form{
    display: flex;
    flex-direction: column;
    
}

.enquiry-form-modal form input{
    border: 1px solid #212121;
    height: 45px;
    margin-block: 0.5em;
    
}



.enquiry-form-modal form textarea{
    padding: 0.5em;
    margin-block: 0.5em;
    border: 1px solid #212121;
}

.enquiry-form-modal form .error-txt{
    color: red;
    font-size: 1.35rem;
}

.enquiry-form-modal .react-tel-input{
    border: 1px solid #212121;
    margin-block: 0.5em;
}

.enquiry-form-modal .react-tel-input .form-control{
    width: 100%;
    border: none;
}

.enquiry-form-modal form button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    align-self: flex-start;
    margin-top: 1em;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
}

.enquiry-form-modal form button:hover{
    color: var(--btn_primary_foreground_color);
}

.enquiry-form-modal .Otp-inputs{
    border: 1px solid #212121;
    
}

.enquiry-form-modal .OTP-title{
    text-align: center;
    margin-bottom: 1em;
}

.enquiry-form-modal .error{
    color: red;
    font-size: 1.35rem;
    text-align: center;
    margin-top: 0.5em;
}