.status-buttons-bar{
    
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.status-buttons-bar .status-button-group{
    display: flex;
    gap: 0.5em;
}

.status-buttons-bar .status-button-group li{
    font-size: var(--cart-group-by-btn-fs);
    padding: 0.85em;
    line-height: normal;
    border-radius: 5px;
    cursor: pointer;
}   