.history-page{
    padding: 1.5em;
    width: min(1440px, 100%);
    margin: 0 auto !important;
    
    
}

.history-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 0.75em;
    text-transform: uppercase;
}

.history-page article {
    display: flex;
    gap: 2em;
    
}

.history-page article .left-section{
    flex: 1 1 500px;
    text-align: justify;
}

.history-page article .left-section p {
    margin-bottom: 1.5em !important;
    font-size: 1.6rem;
}

.history-page article .right-section{
    flex: 1 1 300px; 
}

.history-page article .right-section img{
    width: 100%;
}

@media only screen and (max-width:992px) {
    .history-page article .right-section{
        flex: 1 1 400px; 
    }

    .history-page article .left-section p {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width:768px) {
    .history-page article {
        flex-direction: column;
    }
    .history-page article .right-section{
        flex: 1 1 300px; 
    }
}
