.named-wishlist-page{
    width: var(--box-container-width);
    margin: 0 auto;
    background-color: var(--named_wishlist_bg_color);
}

.App:has(.named-wishlist-page){
    background-color:  var(--named_wishlist_bg_color);
}

.named-wishlist-page header{
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    padding: 1em;
    border: 1px solid var(--menu_separator_color, #eee);
    background-color: var(--menu_bg_color);
    border-left: 5px solid var(--highlight_color);
    margin-bottom: 1em;
    /* background-color: var(--nav_bar_bg_color); */
    color: var(--highlight_color) !important;
    align-items: center;
    margin-top: 1em;
    margin-inline: 1em;
}


.named-wishlist-page header h2{
    margin: 0 !important;
}

.named-wishlist-page header .named-wishlist-add-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.25em 0.75em;
    border-radius: 0.3rem;
    font-size: 1.5rem;
}

.named-wishlist-page .named-wishlist-card-section{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1em;
    padding: 1em 1em 3em;
}

.named-wishlist-page .named-wishlist-card-section.less-shortlist-card{
    grid-template-columns: repeat(4, 1fr);
}

/*create-wishlist-modal*/
.create-delete-wishlist-modal .create-wishlist-section{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.create-delete-wishlist-modal .create-wishlist-section header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    color: var(--named_wishlist_popup_fg_color);
}

.create-delete-wishlist-modal .create-wishlist-section header h3{
    line-height: normal;
    margin: 0;
    color: inherit;
}

.create-delete-wishlist-modal .create-wishlist-section header .add-icon{
    font-size: 2rem;
    color: inherit;
}

.create-wishlist-section input{
    border-color: var(--named_wishlist_popup_fg_color);
    color: var(--named_wishlist_cell_fg_color);
}

.create-delete-wishlist-modal .create-wishlist-section .error-text{
    font-size: 1.3rem;
    color: #ff0000;
}

.create-delete-wishlist-modal .create-wishlist-section .btns{
    display: flex;
    gap: 1em;
    margin-top: 0.75em;
}

.create-delete-wishlist-modal .create-wishlist-section .btns button{
    flex: 1;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.35em 0.75em;
    border-radius: 0.3rem;
    text-transform: uppercase;
}

@media only screen and (max-width: 992px){
    .named-wishlist-page .named-wishlist-card-section.less-shortlist-card{
        grid-template-columns: repeat(3, 1fr);
    }
}


@media only screen and (max-width: 576px){
    .named-wishlist-page .named-wishlist-card-section.less-shortlist-card{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 475px){
    .named-wishlist-page .named-wishlist-card-section.less-shortlist-card{
        grid-template-columns: repeat(1, 1fr);
    }
}

/** Named Wishlist Card **/

.named-wishlist-page .named-wishlist-card{
    background-color: var(--named_wishlist_cell_bg_color, #fff);
    padding: 0.75em;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px  rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    flex: 0 1 300px;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border: 1px solid var(--named_wishlist_cell_border_color, #eee);

}


.named-wishlist-page .named-wishlist-card .detail > *{
    color: var(--named_wishlist_cell_fg_color);
}

.named-wishlist-page .named-wishlist-card .images-section{
    display: grid;
    gap: 0.5em;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    aspect-ratio: 3 / 2;
    max-height: 225px;
    outline: none;
    flex: 1;
}

.named-wishlist-card .images-section > div{
    overflow: hidden;
    border-radius: 0.5rem;
    background-color: var(--named_wishlist_cell_imageview_bg_color, lightgray);
    border: 1px solid var(--named_wishlist_popup_hairline_color, #eee);
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: var(--background-img-scale-cover);
    background-position: top left;
}

.named-wishlist-card .images-section img{
    height: 100%;
    object-fit: cover;
}

.named-wishlist-card .images-section div:first-child{
    grid-row: 1 / -1;
    grid-column: 1 / 3;
}

.named-wishlist-card .details-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.named-wishlist-card .details-section .menu{
    font-size: 2rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--named_wishlist_cell_fg_color, #212121);
    border-radius: 50%;
    cursor: pointer;
}

.named-wishlist-card .details-section .menu:hover{
    background-color: rgba(0, 0, 0, 0.08);
}

/** Card MENU **/
.named-wishlist-card-menu li{
    font-size: 1.5rem;
}

.named-wishlist-card-menu li:hover{
    background-color: var(--highlight_color-opac);
}