.checkout-modal-order-info .ec-register-btn, 
.checkout-modal-order-info .ec-title,
.checkout-modal-order-info .user-section .add-btn
{
    display: none !important;
}

.checkout-modal-order-info .ec-register-wrap.ec-register-half,
.checkout-modal-order-info .user-section > .r-select--is-disabled
{
    width: 100% !important;
    cursor: not-allowed !important;
}

.checkout-modal-order-info input:disabled,
.checkout-modal-order-info button:disabled,
.checkout-modal-order-info textarea:disabled{
    cursor:not-allowed !important;
}

.checkout-modal-order-info textarea::placeholder{
    color: #fff;
}
