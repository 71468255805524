body:has(.new-custom-order-container){
    overflow-x: hidden !important;
}

.new-custom-order-container{
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-inline: 1em;
    
}

.new-custom-order-container .new-custom-order-title{
    color: #777777;
    font-weight: 600;
    text-transform: uppercase;
}

.new-custom-order-container .new-custom-order-wrapper{
    
    width: min(970px, 100%);
    padding: 1.25em 1.5em;
    border-radius: 5px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    /* gap: 1em; */
    border: 1px solid #eeeeee;
    margin-bottom: 3em;
    padding-bottom: 3em;
}

/*---------- MUTI CUSTOM ORDER FORM ----------------------------------------------------------*/
.muti-custom-order-form{
    
    display: flex;
    flex-direction: column;
    /* gap: 1em; */
}



.muti-custom-order-form .form-input-group{
    display: flex;
    flex-direction: column;
    /* gap: 0.25em; */
}

.muti-custom-order-form .form-input-group label{
    margin: 0 !important;
    font-size: 1.5rem;
    color: #444444;
}

.muti-custom-order-form .form-input-group label .required{
    color: red
}

.muti-custom-order-form .form-input-group input{
    margin: 0;
}

.muti-custom-order-form .form-input-group .error-input, .custom-new-order-general .form-input-group .error-input{
    text-align: left;
    color: red;
}


/*----React Select Dropdown Stying----*/

.muti-custom-order-form .form-input-group .r-container{
    box-sizing: border-box;
}

/* .muti-custom-order-form .form-input-group .r-select__control{
    border-radius: 0;
} */



.muti-custom-order-form .form-input-group  .r-select__input-container{
    height: 40px;
}


/*----------------------------------------------*/
.muti-custom-order-form .form-input-group .two-col{
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.muti-custom-order-form .form-input-group .two-col .add-btn{
    border: 1px solid var(--btn_primary_color);
    color: var(--btn_primary_color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    width: max(100px, 20%);
    text-transform: uppercase;
}

.new-custom-order-container .add-items-buttons{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* width: min(400px, 100%); */
}

.new-custom-order-container .add-items-buttons label{
    color: #444444;
}

.new-custom-order-container .add-items-btn-group{
  
    width: 100%;
   display: flex;
   gap: 1em; 
   width: min(300px, 100%); 
}

.new-custom-order-container .add-items-btn-group .add-item-btn{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}


.new-custom-order-container .add-item-btn{
    padding: calc(var(--cart-product-btn-padding) * 0.85);
    border: 1px solid var(--btn_primary_color);
    color: var(--btn_primary_color);
    font-size: 1.4rem;
    border-radius: 0.3rem;
    text-transform: uppercase;
}

.new-custom-order-container .add-item-btn.single-btn{
    margin-top: 1em;
    width: min(300px, 100%);
    margin-inline: auto;
}


/*---------- CUSTOM NEW ORDER / GENERAL ----------------------------------------------------------*/


.custom-new-order-general .form-input-group label{
    margin: 0 !important;
    font-size: 1.5rem;
    color: #444444;
}

.custom-new-order-general .form-input-group .buttons{
    display: flex;
    gap: 0.5em;
    align-self: flex-start;
    flex-wrap: wrap;
}



.custom-new-order-general .form-input-group .buttons label{
    background-color: var(--button_unselected_bg_color);
    color: var(--button_unselected_fg_color);
    display: block;
    padding: 0.5em;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    text-transform: uppercase;
    min-width: 50px;
    border: 1px solid var(--button_unselected_border_color);
    text-align: center;
    cursor: pointer;
}

.custom-new-order-general .form-input-group .buttons .radio-input:checked + label{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
}

.custom-new-order-general .form-input-group .buttons input{
    margin: 0;
    width: 100px;
    align-self: flex-start;
    padding: 0.5em !important;
    border-radius: 0.3rem;
    border-color: var(--btn_primary_color);
}

.custom-new-order-general .form-input-group textarea{
    padding: 0.5em !important;
    border: 1px solid #eeeeee;
}


/*---------- FOOTER ----------------------------------------------------------*/
.new-custom-order-container footer {
    position: sticky;
    box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    bottom: 0;
    margin-top: auto;
    min-width: 100vw;
    margin-left: -1em;
}

.new-custom-order-container footer .footer-wrapper{
    display: flex;
    gap: 0.5em;
    width: min(970px, 100%);
    margin: 0 auto;
    padding: 0.5em;
    
    
}

.new-custom-order-container footer .total-wt{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-custom-order-container footer .total-wt h4{
    font-weight: 600;
    text-wrap: nowrap;
}

.new-custom-order-container footer .place-order-btn{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.5em 1.5em;
    border-radius: 0.3rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;   
    font-size: 1.45rem;

}

.custom-order-placed-sucess-modal .modal-dialog{
    width: min(600px, 100%);
    height: 60vh;
}





/*---------- MEDIA QUERY ----------------------------------------------------------*/
@media only screen and (min-width:992px) {
    .new-custom-order-container footer .total-wt{
        flex: 2
    }
}
