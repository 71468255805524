.common-home-about-section{
    width: var(--box-container-width);
    margin: 3em auto !important;
    display: flex;
    gap: 2em;
    flex-wrap: wrap;
    padding: 1.5em !important;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

/* .common-home-about-section > div {
    flex: 1 1 300px;
} */

.common-home-about-section .about-image-section, .common-home-about-section .about-detail-section{
    flex: 1 1 400px;
}


.common-home-about-section .about-image-section {
    border-radius: 50%;
    height: 400px;
    /* height: 475px;
    aspect-ratio: 1 / 1; */
    overflow: hidden;
    align-self: center;
    /* border: 5px solid var(--highlight_color); */
    /* background-color: #242021; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: var(--background-img-scale-cover);
    background-position: top left;
}

.common-home-about-section .about-image-section img{
    height: 100%;
    object-fit: contain;
    /* background-color: #242021; */
}

.common-home-about-section .about-detail-section h2{
    font-size: var(--category-title-fs);
    color: var(--highlight_color);
    font-weight: 600;
    margin-bottom: 0.75em;
}

.common-home-about-section .about-detail-section p + p {
    margin-top: 1em;
}

.common-home-about-section .about-detail-section .explore-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.5em 1em;
    margin-top: 1em;
    text-transform: uppercase;
    border-radius: 0.3rem;
    display: inline-block;
}

@media only screen and (max-width: 576px){
    .common-home-about-section .about-image-section {
        border-radius: 0px;
    }
}