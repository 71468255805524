.kalasha-online-login-page{
    position: relative;
    margin-bottom: 300px;
}



/*=========== LOGIN WRAPPER ========================*/
.kalasha-online-login-page .login-container-wrapper{
    width: min(600px, 100%);
    margin: 0 auto;
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
    border-radius: 15px;
}

.kalasha-online-login-page .login-container-wrapper input{
    width: 100%;
}

.kalasha-online-login-page .login-container-wrapper .login-button button{
    margin-bottom: 0 !important;
}

.kalasha-online-login-page .login-container-wrapper header h2{
    text-align: center;
    padding-block: 1em;
    color: #555;
    font-size: calc(var(--title-fs) * 0.8);
    font-weight: 800;
}

.kalasha-online-login-page .home-banner-slider .slick-list::before{
    display: none;
}

@media only screen and (max-width: 576px) {
    .kalasha-online-login-page .login-container-wrapper{
        top: 65%;
    }
}