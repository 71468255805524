.fsv-similer-product-card-container{
    padding: 4em 2em 2em;
}

.fsv-similer-product-card-container header{
     text-transform: uppercase;
}

.fsv-similer-product-card-container .slick-track{
    display: flex;
    gap: 0.75em;
    padding: 1em 0;
}

.fsv-similer-product-card-container .slick-arrow{
    width: 40px;
    height: 40px;
    margin: auto;
    border-radius: 0;
    text-align: center;
    background-color: var(--btn_primary_color);
    border-radius: 50%;
    
} 

.fsv-similer-product-card-container .slick-arrow::before{
    color: var(--btn_primary_foreground_color)
}