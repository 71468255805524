.branding-thumb-video-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
    width: var(--box-container-width);
    margin: 1.2em auto 0;
    padding: 1em;
}

@media only screen and (max-width:576px){
    .branding-thumb-video-container{
        grid-template-columns: repeat(2, 1fr);
    }
}