.matrix-branding-contact-us{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em !important;
    gap: 1em;
}


.matrix-branding-contact-us h3{
    margin-top: 0.25em !important;
}


.matrix-branding-contact-us a{
    font-size: 3rem;
    cursor: pointer !important;
}

.matrix-branding-contact-us a:hover{
    color: var(--highlight_color);
    
}

.contact-us-shake-bottom {
	-webkit-animation: contact-us-shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 200ms infinite both;
	        animation: contact-us-shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) 200ms infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-8-20 18:42:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation contact-us-shake-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes contact-us-shake-bottom {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    10% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
  }
  @keyframes contact-us-shake-bottom {
    0%,
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    10% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    20%,
    40%,
    60% {
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg);
    }
    30%,
    50%,
    70% {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    80% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    90% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
  }
  
  