.live-rate-updated-bar{
    display: flex;
    gap: 0.75em;
    padding: 0.5em;
    align-items: center;
    justify-content: center;
    color: var(--live_rate_fg_color);
    background-color: var(--live_rate_bg_color);
    cursor: pointer;
    
}

.live-rate-updated-bar:hover{
    color: var(--live_rate_fg_color);
}

.live-rate-updated-bar .rate{
    font-size: 1.6rem;
    font-weight: 600;
    
}

.live-rate-updated-bar .date-time{
    color: var(--live_rate_secondary_fg_color)
}

@media only screen and (max-width: 600px){
    .live-rate-updated-bar .rate{
        font-size: 1.4rem;
    }

    .live-rate-updated-bar .date-time{
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 470px){
    .live-rate-updated-bar .rate{
        font-size: 1.3rem;
    }

    .live-rate-updated-bar .date-time{
        font-size: 1.1rem;
    }
}