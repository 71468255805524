.opt-input-modal .MuiBox-root{
    border-radius: 0;
}

.opt-input-modal .otp-modal-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em;
    align-items: center;
}

.opt-input-modal .otp-modal-wrapper .details{
    color: #212121;
    text-align: center;
}

.opt-input-modal .otp-modal-wrapper .details + div{
    display: flex;
    gap: 0.5em;
}

.opt-input-modal .otp-modal-wrapper input{
    margin-block: 1.5em;
    width: 40px !important;
    height: 40px;
    padding: 0;
    text-align: center;
}

.opt-input-modal .otp-modal-wrapper .verify-code-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    text-transform: uppercase;
    padding: 0.35em 2em;
}

.opt-input-modal .otp-modal-wrapper .resend-otp-btn{
    color: var(--hightlight_color);
}