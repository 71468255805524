.special-jewellery-page{
    padding: 1.5em;
    width: min(1440px, 100%);
    margin: 0 auto !important;
    
    
}

.special-jewellery-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 1em;
    text-transform: uppercase;
    text-align: center;
}


.special-jewellery-page .special-jewellery-card{
    margin-block: 2em;
}

.special-jewellery-page .special-jewellery-card:first-child{
    margin-top: 0;
}


.special-jewellery-page .special-jewellery-card .card-wrapper{
    display: flex;
    gap: 3em;
}


.special-jewellery-page .special-jewellery-card .card-wrapper .image-slider{
   width: 55%; 
}

.special-jewellery-page .speical-jewellery-slider .slick-arrow{
    background-color: var(--highlight_color);
    height: 40px;
    width: 40px;
    /* border-radius: 50%; */
    color: var(--btn_primary_foreground_color);
}

.special-jewellery-page .speical-jewellery-slider .slick-arrow::before{
    color: inherit;
}

.special-jewellery-page .special-jewellery-card .info{
    font-size: 1.6rem;
    text-align: justify;
}

@media only screen and (max-width: 992px) {
    .special-jewellery-page .special-jewellery-card .card-wrapper{
        gap: 1.5em;
    }
    .special-jewellery-page .special-jewellery-card .info{

        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .special-jewellery-page .special-jewellery-card .card-wrapper{
        flex-direction: column;
    }

    .special-jewellery-page .special-jewellery-card .card-wrapper .image-slider{
        width: 100%;
    }

    .special-jewellery-page .speical-jewellery-slider .slick-arrow{
        width: 30px;
        height: 30px;
    }
}

