
.all-order-grouped-page{
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--cart_bg_color);

}

/*---------- Header --------------------------------------------------*/

.all-order-grouped-page > header{
    background-color: var(--cart_group_by_bg_color, #fff);
    padding: var(--cart-group-by-padding);
    box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
    position: sticky;
    /* top: 0; */
    z-index: 7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    border-bottom: 1px solid var(--menu_separator_color);
    color: var(--cart_group_by_label_color);
}

.all-order-grouped-page header .header-inner{
    width: var(--box-container-width);
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    max-width: 90%;
    
}

.all-order-grouped-page .all-order-details-header{
    margin: 0.75em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.all-order-grouped-page .all-order-details-header header{
    display: flex;
    justify-content: space-between;
}

.all-order-grouped-page .all-order-details-header .cancel-past-order-button button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    border-radius: 0.3rem;
    text-transform: uppercase;
    padding: 0.25em 1em;
    font-size: 1.4rem;
}   

.all-order-grouped-page .all-order-details-header .footer-data-section{
    display: flex;
    gap:  0.5em 5em;
    flex-wrap: wrap;
    color: var(--menu_text_color, #000000);
}

.all-order-grouped-page .all-order-details-header .footer-data-section .footer-data{
    display: flex;
    gap: 1em;
    font-size: 1.5rem;
    
    /* font-weight: 400; */
}

.all-order-grouped-page .all-order-details-header .footer-data-section .footer-data .value{
    font-weight: 400;
}

@media only screen and (max-width: 768px){
    .all-order-grouped-page .all-order-details-header .footer-data-section{
        gap: 0.5em 2em;
    }
}

/*---------- Grouped Section --------------------------------------------------*/
.grouped-section{
    width: var(--box-container-width);
    margin: 0 auto !important;
    padding: 1em 1em 3em;
}

.grouped-section .accordion{
    box-shadow: none;
}

.grouped-section .accordation-header{
    font-size: var(--cart-accordian-header-title) !important;
  
}

.grouped-section .accordation-header button{
    height: 50px;
    border-radius: 5px;
}

.grouped-section .accordation-header h3{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 1em;
    
    font-size: var(--cart-accordian-header-title) !important;
    /* font-weight: 700; */
    color: var(--cart_summary_section_detail_fg_color, #000000);
    margin-block: 0 !important;
    
}

.grouped-section .accordation-header h3 > span:first-child{
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

}



.grouped-section .accordation-header h3 .unit{
    color: #212121;
    /* font-size: 1.6rem; */
    display: flex;
    gap: 0.25em;
    color: var(--cart_summary_section_detail_fg_color, #000000);
}

.grouped-section .accordation-header .divide{
    align-self: stretch;
    background-color: currentColor;
    display: inline-block;
    min-height: 1em;
    opacity: .25;
    width: 1px;
    display: block;
    margin-inline: 0.15em;
}


.grouped-section .accordation-body{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5em;
    padding: 1.5em;
    background-color: var(--cart_cell_bg_color);
}

.grouped-section .accordation-body .order-accordation-card{
    box-shadow: 0 0 20px -10px #3f3f3f4d;
    display: flex;
    gap: 1em;
    border-radius: 0.3rem;
    border: 1px solid var(--menu_separator_color, #eee);
}



.grouped-section .accordation-body .order-accordation-card .image-section{
    max-height: 280px;
    /* width: 300px; */
    position: relative;
    background-position: top right;
    background-size: var(--background-img-scale-cover);
    cursor: pointer;
    flex: 1;
}

.grouped-section .accordation-body .order-accordation-card .image-section > section,
.grouped-section .accordation-body .order-accordation-card .image-section > section > div
{
    height: 100%;
}

.grouped-section .accordation-body .order-accordation-card .image-section img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    padding: 0.5em;
    min-width: 100px;
}

.grouped-section .accordation-body .order-accordation-card .image-section .cart-zoom-image-btn{
    position: absolute;
    
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    font-size: 2rem;
    border-radius: 0.3rem;
    bottom: 0.5em;
    right: 0.5em;
}

.grouped-section .accordation-body .order-accordation-card .detail-section{
    padding: 1em;
    display: flex;
    column-gap: 0.5em;
    row-gap: 1em;
    gap: var(--cart-product-details-gap)!important;
    /* width: min(400px, 100%); */
    flex-wrap: wrap;
    align-self: flex-start;
    flex: 2;
    color: var(--cart_cell_fg_color);
    
}



.grouped-section .accordation-body .order-accordation-card .detail-section div.full{
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* font-size: inherit; */
    font-size: var(--cart-product-details-fs)
}



.grouped-section .accordation-body .order-accordation-card .detail-section div.half{
    width: calc(50% - (0.75em / 2));
    font-size: inherit;
    display: flex;
    justify-content: space-between;
}

.grouped-section .accordation-body .order-accordation-card .detail-section > div.full.top,
.grouped-section .accordation-body .order-accordation-card .detail-section div > span:first-child{
    font-weight: 700;
    color: var(--cart_cell_fg_color, #777777);
}

.grouped-section .accordation-body .order-accordation-card .detail-section > div.full.top.status-group{
    display: flex;
    gap: 0.75em;
    flex-wrap: wrap;
}

.grouped-section .accordation-body .order-accordation-card .detail-section > div.full.top.status-group .status{
    display: inline-block;
    padding: 0.25em 0.5em;
    border-radius: 0.3rem;
}

@media only screen and (max-width: 950px) {
    .grouped-section .accordation-body{
        grid-template-columns: 1fr;
    }
}



@media only screen and (max-width: 400px) {
    .grouped-section .accordation-body .order-accordation-card .detail-section div.half{
        width: 100%;
    }
}   

/*---------- Footer --------------------------------------------------*/
.all-order-grouped-page footer{
    margin-top: auto;
    position: sticky;
    bottom: 0;
    box-shadow: 0 0 20px -12px #3f3f3f80;
    background-color: #fff;
    z-index: 8;
}

.all-order-grouped-page footer .cancel-past-order-button{
    display: flex;
    padding: 1em 2em;
    border-bottom: 1px solid #eee;
    /* background-color: #f7f7f7; */
}

.all-order-grouped-page footer .cancel-past-order-button button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    width: min(500px, 100%);
    padding: 0.5em 1em;
    border-radius: 0.3rem;
    margin: 0 auto;
    text-transform: uppercase;
}

.all-order-grouped-page footer .footer-data-section{
    width: var(--box-container-width);
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 2em;
    
}

.all-order-grouped-page footer section .footer-data{
    width: min(500px, 100%);
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    color: #777777;
    
}

.all-order-grouped-page footer section .footer-data .value{
    font-weight: 600;
}