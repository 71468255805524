.cancel-past-order-modal .MuiBox-root{
    border-radius: 0;
    outline: none;

}


.cancel-past-order-modal header{
    padding: 0.5em;
    border-bottom: 1px solid #eee;
}

.cancel-past-order-modal header h3{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    font-weight: 700;
    color: var(--highlight_color);
    text-transform: uppercase;
}

.cancel-past-order-modal header .close-btn{
    font-size: 2.2rem;
    cursor: pointer;
    color: inherit;
}

.cancel-past-order-modal .cancel-past-order-modal-content textarea{
    padding: 0.5em;
    margin: 0;
}

.cancel-past-order-modal .cancel-past-order-modal-content .done-btn{
    padding: 0 0.5em 0.5em;
    display: flex;
    gap: 0.5em;
}

.cancel-past-order-modal .cancel-past-order-modal-content .done-btn button{
    width: 100%;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.25em 1em;
    border-radius: 0.3rem;
    text-transform: uppercase;
}