.noprod-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    min-height: 60vh;
    padding: 1em;
}
.noprod-container img{
    width: min(250px, 100%);
}


.noprod-container h2{
    font-size: 2.5rem;
    text-align: center;
    color: var(--btn_primary_color);
}

.noprod-container p{
    font-size: 1.4rem;
    color: #777;
    text-align: center;
}

.noprod-container button {
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    font-size: var(--btn-fs) !important;
    padding: var(--primary-btn-padding) !important;
    border-radius: 5px !important;
}


@media only screen and (min-width:768px) {
    .noprod-container h2{
        font-size: 3rem;
    }

    .noprod-container p{
        font-size: 1.6rem;
    }

   

}