.fsv-matrix-modal .MuiBox-root{
    border-radius: 0;
    
}


/************* HEADER *******************************/

.fsv-matrix-modal header{
    padding: 0.75em;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* background-color: #fefefe; */
    background-color: var(--btn_primary_color);
    border-bottom: 1px solid #cccccc;
    color: var(--btn_primary_foreground_color);
}

.fsv-matrix-modal header h3{
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    /* margin-left: auto; */
    color: inherit;
    
}

/* .fsv-matrix-modal h5.ec-single-title{
    padding-left: 1em !important;
} */

.fsv-matrix-modal header .close-icon{
    margin-left: auto;
    cursor: pointer;
    font-size: 2.3rem;
    color: inherit;
}

.fsv-matrix-modal .fsv-matrix-modal-section{
    
    max-height: 85vh;
    overflow: auto;
    position: relative;
}

.fsv-matrix-modal .fsv-section .product-details-label{
    margin-top: 0 !important;
}

.fsv-matrix-modal .fsv-matrix-modal-section .heading{
    padding-left: 0 !important;
}

.fsv-matrix-modal .fsv-matrix-modal-section .fsv-section{
    padding: 1em !important;
    margin-top: 0 !important;
}

.fsv-matrix-modal .fsv-matrix-modal-section .single-pro-content .ec-pro-variation{
    border-bottom: 0;
}

.fsv-matrix-modal .fsv-matrix-modal-section footer{
    
    background-color: #fff;
    position: sticky;
    bottom: 0;
    box-shadow:  1px 4px 2px rgba(0, 0, 0, 0.10)
}

.fsv-matrix-modal .fsv-matrix-modal-section footer .fsv-footer{
    padding: 1em !important;
}

.fsv-matrix-modal .fsv-matrix-modal-section footer .fsv-footer button{
    padding: 0.85em !important;
    font-size: var(--matrix-single-product-button-fs) !important;
    font-weight: 700;
}

.fsv-matrix-modal .fsv-matrix-modal-section footer .fsv-footer > div:first-child:not(.cart-footer-btn-wrapper){
    display: none;
}

.fsv-matrix-modal .fsv-matrix-modal-section footer .fsv-footer > div:last-child{
    width: 100%;
}

