/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* @import url("./assets/css/plugins/animate.css");
 @import url("./assets/css/plugins/swiper-bundle.min.css");
 @import url("./assets/css/plugins/jquery-ui.min.css");
@import url("./assets/css/plugins/countdownTimer.css");
@import url("./assets/css/plugins/slick.min.css");
@import url("./assets/css/plugins/bootstrap.css");
@import url("./assets/css/plugins/nouislider.css");
@import url("./assets/css/backgrounds/bg-4.css");
@import url("./assets/css/vendor/ecicons.min.css");
@import url("./assets/css/responsive.css");
@import url("./assets/css/style.css");
@import url("./assets/css/animate.css");
@import url("./assets/css/bootstrap.css");
@import url("./assets/css/responsive.css");
@import url("./assets/css/landing-style.css");
@import url("./assets/css/landing-responsive.css"); */

.toast-container-popup{
  width: min(420px, 100%) !important;
  padding: 1em !important;
  
}

.toast-container-popup > div{
  border-radius: 0.5rem;
}