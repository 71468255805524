/********************************************************************/
/****************** Header Section *********************************/
/********************************************************************/
.cards-container{
    margin-block: 2em;
}

.cards-section{
    margin: 1em 0;
    /* background-color: red; */
    
}





.section-header{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    border-bottom: 1px solid var(--web_separator_color, #777);
}

.section-header h2{
    color: var(--highlight_color, --btn_primary_color);
    border-bottom: 2px solid var(--highlight_color, --btn_primary_color);
    margin: 0;
    padding-bottom: 0.5em;
    text-transform: uppercase;
}

.section-header .section-btn .btn-primary{
    border-radius: 5px !important;
    color: var(--btn_primary_color);
    border-color: var(--btn_primary_color);
    font-size: var(--category-label-fs) !important;
    margin-bottom: 0.5em !important;
    /* margin-right: 30px !important; */
    
}

.ec-title{
    font-size: var(--category-title-fs);
    font-weight: 700;
}

.subTitle{
    margin-top: 1rem;
    font-size: 1.4rem;
}

.btn-primary{
    padding: var(--primary-btn-padding);
    border-width: 1px;
    border-style: solid;
    font-size: var(--category-label-fs);
    font-weight: 500;
    border-radius: 1.2em;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #142f41;
    border-color: #142f41;
    display: block;
    margin-block: 0 !important;
}

.ec_card{
    width: 100%;
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 5px 7px 13px 0px rgba(35, 31, 32, 0.1); */
}

.ec_card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 100px;
}

.ec_card video{
    width: 100%;
    object-fit: cover;
}

.ec_card video::-webkit-media-controls {
    display: none !important;
    opacity: 0;
  }
  .ec_card video::-webkit-media-controls-start-playback-button {
    display: none !important;
  }
  



/******************** Media Query *****************************/

@media only screen and (min-width:576px) {
    .ec-title{
        font-size: var(--category-title-fs);
    }
}

@media only screen and (min-width:768px) {
    .ec-title{
        font-size: var(--category-title-fs);
    }

    .btn-primary{
        font-size: 1.4rem;
    }

    .subTitle{
        font-size: 1.5rem;
    }
}

@media only screen and (min-width:1170px) {
    .btn-primary{
        font-size: 1.5rem;
    }
}