.jewelsmith-about-us-page{
    width: var(--box-container-width);
    margin: 0 auto;
    padding: 3em 1em;
    display: flex;
    flex-direction: column;
    gap: 6em;
    /* font-family: 'Times New Roman', Times, serif; */
}

/* .jewelsmith-about-us-page *{
    font-family: inherit !important;
} */

.jewelsmith-about-us-page header h2{
    color: var(--highlight_color);
}


.jewelsmith-about-us-page .card-section{
    display: flex;
    gap: 2em;
    position: relative;
}

.jewelsmith-about-us-page .card-section + .card-section::before{
    position: absolute;
    content: '';
    width: 100%;
    background-color: #d5d5d5;
    height: 2px;
    top: -3em;
}

.jewelsmith-about-us-page .card-section:nth-child(even){
    flex-direction: row-reverse;
}



.jewelsmith-about-us-page .card-section .image-section{
   flex: 1;
   
}

.jewelsmith-about-us-page .card-section .image-section img{
    min-width: 450px;
}

.jewelsmith-about-us-page .card-section .detail-section{
    /* align-self: center; */
    flex: 2;
    color: var(--login_register_fg_color);
 }


 .jewelsmith-about-us-page .card-section .detail-section header{
    margin-bottom: 1em;
    font-size: 2.4rem;
 }

 .jewelsmith-about-us-page .card-section .detail-section h2{
    font-size: inherit;
    font-size: 3rem;
    font-weight: 600;
 }

 .jewelsmith-about-us-page .card-section .detail-section h3{
    font-weight: 600;
    margin-bottom: 1em;
    font-size: 2.2rem;
}

.jewelsmith-about-us-page .card-section .detail-section h3:not(:first-child){
    margin-top: 1em;
}

 .jewelsmith-about-us-page .card-section .detail-section p{
    font-size: 1.7rem;
}

.jewelsmith-about-us-page .card-section .detail-section p + p{
    margin-top: 1.2em;
}

@media only screen and (max-width: 992px) {
    .jewelsmith-about-us-page .card-section .image-section img{
        min-width: 350px;
    }
}

@media only screen and (max-width: 800px) {
    .jewelsmith-about-us-page .card-section .image-section img{
        min-width: 280px;
    }
    
    .jewelsmith-about-us-page .card-section, .jewelsmith-about-us-page .card-section:nth-child(even){
        flex-direction: column;
    }
}