.checkout-order-tags-container .tag-label{
    display: flex;
    align-items: center;
    
}

.checkout-order-tags-container .tag-label .add-tags-btn{
    margin-left: auto !important;
    font-size: var(--label-fs);
    color: #212121;
    cursor: pointer;
}


.checkout-order-tags-container .tags-wrapper{
    border: 1px solid #212121;
    min-height: 100px;
    border-radius: 0.3rem;
    padding: 0.5em !important;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    align-items: flex-start;
}

.checkout-order-tags-container .tags-wrapper .checkout-tag{
    margin: 0 !important;
    display: flex;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    gap: 0.5em;
    align-items: center;
    justify-content: center;
    padding: 0.25em 0.5em !important;
    font-size: 1.5rem !important; 
}

.checkout-order-tags-container .tags-wrapper .checkout-tag .close-icon{
    font-size: 1.75rem !important; 
    cursor: pointer;
}