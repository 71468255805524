.custom-order-section{
    
    min-height: 800px;
}

/*------ Custom Order Header ---------------------------------------------------------------------------*/

.custom-order-section header{
    background-color: #fff;
    padding: var(--cart-group-by-padding);
    box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
    position: sticky;
    /* top: 0; */
    z-index: 1;
}

.custom-order-section header .header-inner{
    width: var(--box-container-width);
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    
    
}



/*------ Custom Order Status ---------------------------------------------------------------------------*/
.custom-order-section .status-search-section{
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;
    /* flex-wrap: wrap; */
}

.custom-order-section .status-search-section.admin{
    flex-direction: column;
    align-items: flex-start;
}


.custom-order-section .custom-order-status{
    
    display: flex;
    align-items: center;
    gap: 0.5em;
}



.custom-order-section .status-button-group{
    display: flex;
    gap: 0.5em;
    flex: 1;
}

.custom-order-section .status-button-group li{
    font-size: var(--cart-group-by-btn-fs);
    padding: 0.85em;
    line-height: normal;
    border-radius: 5px;
    cursor: pointer;
}

.custom-order-section .status-button-group li{
    display: flex;
    gap: 0.25em;
    align-items: center;
}

.custom-order-section .status-button-group li .order-count{
    padding: 0.15em 0.25em;
    border-radius: 0.3rem;
}


/*------ Custom Order Search ---------------------------------------------------------------------------*/
.custom-order-section .custom-order-search{
    position: relative;
    width: min(300px, 100%);
}

.custom-order-section .custom-order-search input{
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 5px;
    padding-inline: 35px 10px !important;
    
}

.custom-order-section .custom-order-search input::placeholder{
    color: #d8d8d8;
}

.custom-order-section .custom-order-search .search-icon{
    position: absolute;
    font-size: 2rem;
    top: 50%;
    transform: translateY(-60%);
    margin-left: 0.5em;
    color: var(--highlight_color);
}




/*------ Custom Order Body ---------------------------------------------------------------------------*/
.custom-order-section .custom-order-body{
    width: var(--box-container-width);
    margin: 0 auto;
    padding: var(--cart-group-by-padding);
    padding-bottom: 2em !important;
    min-height: 100vh;
}

.custom-order-section .custom-order-body .custom-order-body-title{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.25em 0 0.5em;
    text-transform: uppercase;
    color: #777777;
}








/*------ Footer ---------------------------------------------------------------------------*/
.custom-order-section footer{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background-color: #fff;
    
    position: sticky;
    box-shadow: 0 -3px 25px 4px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 -3px 25px 4px rgba(0, 0, 0, 0.06);
    /* box-shadow: 0 0 20px -12px #3f3f3f80; */
}

.custom-order-section footer .custom-order-setion-footer{
    display: flex;
    width: var(--box-container-width);
    margin: 0 auto !important;
    justify-content: space-between;
    align-items: center;
}

.custom-order-section footer .button-container{
    flex: 1;
    padding: var(--cart-group-by-padding);
    display: flex;
    justify-content: flex-end;
}

.custom-order-section footer .button-container.center-align{
    justify-content: center;
}

.custom-order-section footer .footer-data-section{
    
    display: flex;
    padding: 1em 2em;
    flex: 2;
    flex-wrap: wrap;
    
    
}

.custom-order-section footer .footer-data{
    /* width: min(275px, 100%); */
    display: flex;
    gap: 1em;
    font-size: 1.4rem;
    color: #777777;
    margin-right: 2.5em !important;
}
.custom-order-section footer .footer-data .value{
    font-weight: 600;
}

.custom-order-section footer .new-order-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.5em 1.5em;
    border-radius: 0.3rem;
    width: min(275px, 100%);
    flex-shrink: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.45rem;
}


/*---------- MEDIA QUERY ------------------------------------*/

@media only screen and (max-width: 992px){
    .custom-order-section .status-search-section{
        flex-direction: column;
    }

    .custom-order-section .custom-order-search{
        width: 100%;
    }

    .custom-order-section .custom-order-status{
        width: 100%;
    }
}

@media only screen and (max-width: 576px){
    .custom-order-section footer .custom-order-setion-footer{
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .custom-order-section footer .button-container{
        width: 100%;
        justify-content: center;
        padding: 1em var(--cart-group-by-padding);
    }

    .custom-order-section footer .new-order-btn{
        width: 100%;
    }

    

    .custom-order-section footer .footer-data-section{
        flex-direction: column;
        padding: var(--cart-group-by-padding);
    }
}