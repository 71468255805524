.named-wishlist-popup-modal header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2em;
}

.named-wishlist-popup-modal header h3{
    margin: 0 !important;
    color: var(--named_wishlist_popup_fg_color, #212121);
}

.named-wishlist-popup-modal header .right{
    display: flex;
    gap: 0.75em;
}

.named-wishlist-popup-modal header .right button{ 
    text-transform: uppercase;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.35em 0.85em;
    display: flex;
    align-items: center;
    line-height: normal;
    justify-content: center;
    border-radius: 0.3rem;
}

.named-wishlist-popup-modal .no-wishlist{
    text-align: center;
    padding: 2em;
}

.named-wishlist-popup-modal .input-section li{
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.named-wishlist-popup-modal .input-section li + li{ 
    border-top: 1px solid #dedede;
}

.named-wishlist-popup-modal .input-section li input{
    accent-color: var(--btn_primary_color);
    box-shadow: inset 0 0 0 2px var(--btn_primary_color);
    height: 20px;
    width: 20px;

}

.named-wishlist-popup-modal .input-section li label{
    margin: 0;
    height: 100%;
    width: 100%;
    display: block;
    cursor: pointer;
    color: var(--named_wishlist_popup_fg_color);
}

.named-wishlist-popup-modal .btns{
    display: flex;
    gap: 1em;
    margin-top: 1em;
    
}

.named-wishlist-popup-modal .btns .button{
    flex: 1;
    text-transform: uppercase;
    text-align: center;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.35em 0.85em;
    border-radius: 0.3rem;
    cursor: pointer;
}