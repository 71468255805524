.master-list-orders-modal {
    outline: none;
}

.master-list-orders-modal .MuiBox-root{
    padding: 0;
    border-radius: 15px;
    outline: none;
}

.master-list-orders-modal header{
    display: flex;
    justify-content: space-between;
    padding: 0.75em 1em;
    border-bottom: 1px solid #eee;
    
}

.master-list-orders-modal header h3{
    color: var(--highlight-color);
}

.master-list-orders-modal header .close-btn{
    font-size: 2rem;
    cursor: pointer;
}

.master-list-orders-modal ul{
    padding: 0.75em 1em;
}

.master-list-orders-modal ul li{
    padding-block: 0.5em;
    font-size: 1.5rem;
}

.master-list-orders-modal ul li + li{
    border-top: 1px solid #eee;
}

.master-list-orders-modal ul li a:hover{
    color: var(--highlight-color);
}   

.master-list-orders-modal ul li a{
    display: inline-block;
    width: 100%;
}