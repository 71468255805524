.main-header{
    position: sticky !important;
    top: 0;
}



.header-top, .ec-header-bottom{
    border-bottom: 1px solid var(--web_separator_color, #eee) !important;
    padding: 0.75em 0;
}

.header-logo{
    margin: 0 !important;
}

.ec-header-bottom{
    height: auto !important;
    position: sticky;
    width: 100%;
    background-color: var(--web_nav_bar_bg_color, #ffffff);
    z-index: 1;
}

.header-logo img,
.header-bottom .header-left-extra-logo img
{
    margin: 0;
    width: auto !important;
    max-height: var(--web_company_logo_max_height);
    height: var(--web_company_logo_height);
}

.header-bottom .header-left-extra-logo{
    flex-shrink: 0;

}

.header-bottom .center_compnay_logo:has(.header-left-extra-logo) {
    display: flex;
    justify-content: center;
    gap: 0.25em;
}

@media only screen and (max-width: 576px){

    #header-company-logo-1:has(.header-left-extra-logo) img{
        max-height: calc(var(--web_company_logo_max_height) * 0.85);
        height: calc(var(--web_company_logo_height) * 0.85);
    }

    .header-logo img{
        max-height: calc(var(--web_company_logo_max_height) * 0.85);
        height: calc(var(--web_company_logo_max_height) * 0.85);
    }
}

/* .header-bottom .header-left-extra-logo + .center_compnay_logo{
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    transform: none;
    left: 0;
}

@media only screen and (max-width: 992px){
    .header-bottom .header-left-extra-logo + .center_compnay_logo{
        position: static;
    }
} */

.ec-header-bottom .header-bottom{
    width: var(--box-container-width);
    padding: 0 1em;
    margin: 0 auto;
}

.ec-header-bottom .header-bottom .view-style-option-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* color: var(--button_background_color); */
    cursor: pointer;
    /* position: absolute; */
    right: 1.4em;
    /* color: var(--highlight_color); */
}


.ec-header-bottom .header-bottom .view-style-option-btn .view-style-icon{
    font-size: 2rem;
}

.ec-header-bottom .header-bottom .view-style-option-btn .view-style-text{
    font-size: 1.35rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 0.25em;
}

.search_and_cart_opt{
    position: absolute !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.view-layout-modal{
    padding: 1em;
    
}

.view-layout-modal .view-layout-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1em;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--button_background_color);
}

.view-layout-modal .view-layout-header .close-btn{
    font-size: 2.5rem;
    line-height: normal;
}

.view-layout-modal .view-layout-body{
    display: flex;
    gap: 1em;
}

.view-layout-modal .view-layout-body .view-layout-image-container{
    border: 2px solid #777;
    border-radius: 0.5rem;
    overflow: hidden;
    width: 100px;
    height: 100px;
}

.view-layout-modal .view-layout-body .view-layout-image-container.selected{
    border: 2px solid var(--highlight_color);
}

.view-layout-modal .view-layout-body .view-layout-image-container img{
    height: 100%;
    width: 100%;
}


@media only screen and (min-width:1440px) {
    .header-logo img{
        /* width: 250px !important; */
        width: auto !important;
    max-height: var(--web_company_logo_max_height);
    height: var(--web_company_logo_height);
    }
}

