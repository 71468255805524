.by-design-custom-order-modal .MuiBox-root{
    border-radius: 0.3rem;
    overflow: hidden;

}

/************* HEADER *******************************/

.by-design-custom-order-modal .by-design-custom-section header{
    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f1f6;
    border-bottom: 1px solid #cccccc;
    padding: 1em;

}

.by-design-custom-order-modal .by-design-custom-section header h3{
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    /* margin-left: auto; */
    
}

.by-design-custom-order-modal .by-design-custom-section header .close-icon{
    margin-left: auto;
    cursor: pointer;
    font-size: 2.3rem;
    color: #212121;
}

.by-design-custom-order-modal .button-group{
    display: flex;
    gap: 1em;
}

/****************** BODY *****************************/
.by-design-custom-body{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    /* margin-top: 0.5em; */
    padding: 1em 1em 1.25em;
}

.by-design-custom-order-modal .input-group input{
    margin-bottom: 0.25em;
}

.by-design-custom-order-modal .button-group button{
    flex: 1;
    padding: calc(var(--cart-product-btn-padding) * 0.85);
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    font-size: 1.4rem;
    border-radius: 0.3rem;
    text-transform: uppercase;
}