.contact-us{
    padding: 2em;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}

.contact-us .contact-location .contact-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.contact-us .contact-location .contact-details h2{
    font-size: 2.8rem;
    font-weight: 600;
}


.contact-us .contact-location .contact-details .locations{
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
}

.contact-us .contact-location .contact-details .locations > .details{
    flex: 1 1 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}



.contact-us .contact-location .contact-details h3{
    font-size: 2.3rem;
}

.contact-us .contact-location .contact-details .details:not(h3){
    font-size: 1.6rem;
}


.contact-us .contact-location .contact-details .details .btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    align-self: center;
    border-radius: 0.3rem;
    font-size: 1.6rem;
    margin-top: 1em;
}