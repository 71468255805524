.QR-code-modal .MuiBox-root{
    border-radius: 0;
}

.QR-code-modal header{
    display: flex;
    padding: 1em;
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
}

.QR-code-modal header h3{
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
}

.QR-code-modal header .close-icon{
    font-size: 2rem;
    margin-left: auto;
    position: absolute;
    right: 1em;
    color: #212121;
    cursor: pointer;
}

.QR-code-modal .QR-code-modal-body{
    padding: 2em 1em;
    display: flex;
    justify-content: center;
}