.news-events-page{
    padding: 1.5em;
    width: min(1440px, 100%);
    margin: 0 auto !important;
    
    
}

.news-events-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    text-align: center;
}

.news-events-page .news-events-cards-section{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
}

.news-events-page .news-events-card{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    
}

.news-events-page .news-events-card .image-section{
    cursor: pointer;
}

.news-events-page .news-events-card .detail-section{
    text-align: center;
    font-size: 1.6rem;
}

.news-events-page .show-more-section{
    display: flex;
    justify-content: center;
    padding: 1em 0;
}

.news-events-page .show-more-btn{
    display: inline-block;
    color: var(--highlight_color);
    font-weight: 600;
}

@media only screen and (max-width: 992px) {
    .news-events-page .news-events-card .detail-section{
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .news-events-page .awards-section{
        grid-template-columns: repeat(2, 1fr);
        
    }
}

@media only screen and (max-width: 768px) {
    .news-events-page .news-events-cards-section{
        grid-template-columns: repeat(2, 1fr);
        
    }
}

@media only screen and (max-width: 475px) {
    .news-events-page .news-events-cards-section{
        grid-template-columns: 1fr;
        
    }
}