

/********** FORM-FIELD ***************************/
.add-retailer-form-container .add-retailer-form{
    position: relative;
}

.add-retailer-form-container .form-fields{
    padding: 1em;
}

.add-retailer-form-container .form-fields{
    display: flex;
    flex-direction: column;
    /* gap: 0.5em; */
}

/********** FOOTER ***************************/
.add-retailer-form-container footer{
    padding: 1em;
    background-color: #fff;
    position: sticky;
    bottom: 0;
}

.add-retailer-form-container footer .add-client-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    width: 100%;
    text-transform: uppercase;
    padding: 0.5em;
    border-radius: 0.3rem;
    font-weight: 600;
}