.past-order-details-page-menu .dot-menu-wrapper{
    width:  3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.past-order-details-page-menu .dot-menu-wrapper:hover{
    background-color: #eee;
}

.past-order-details-page-menu .dot-menu{
    font-size: 2rem;
    
}

/*---- Order-Details-Menu-Dropdown ----------------------------*/
.past-order-dropdown-list .past-order-dropdwon-item{
    font-size: 1.5rem;
}

.past-order-dropdown-list .past-order-dropdwon-item a{
    color: inherit;
}

.past-order-dropdown-list .past-order-dropdwon-item:hover{
    background-color: var(--highlight_color-opac);
}