.past-order-info-modal .MuiBox-root{
    border-radius: 0;
}

/*------ HEADER -------------------------*/

.past-order-info-modal header{
    display: flex;
    justify-content: space-between;
    padding: 0.75em 1em;
    border-bottom: 1px solid var(--menu_separator_color, #eee);
    color: var(--highlight_color);
    background-color: var(--login_register_bg_color);

    
}

.past-order-info-modal header h3{
    color: inherit;
    text-transform: uppercase;
}

.past-order-info-modal header .close-btn{
    font-size: 2rem;
    cursor: pointer;
    color: inherit;
}

.past-order-info-modal .checkout-container{
    margin-bottom: 0 !important;
}

/*------ past-order-info-modal-body -------------------------*/

.past-order-info-modal .past-order-info-modal-body{
    padding: 1em 1em 2em;
    /* height: 800px; */
    max-height: 85vh;
    overflow: auto;
    background-color: var(--login_register_bg_color);
}


