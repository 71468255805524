.chainhouse24-about-us{
    width: min(1440px, 100%);
    margin: 0 auto;
    padding: 3em 1em 5em;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.chainhouse24-about-us h2{
    text-align: center;
    font-size: 2.5rem;
    margin-block: 0.75em;
}

.chainhouse24-about-us img{
    width: min(800px, 100%);
    margin: 1em auto;
}

.chainhouse24-about-us p{
    font-size: 1.6rem;
    color: #212121;
}

.chainhouse24-about-us p + p{
    margin-top: 1em;
}

@media only screen and (max-width: 576px){
    .chainhouse24-about-us p{
        font-size: 1.45rem;
    }

    .chainhouse24-about-us h2{
        font-size: 2.3rem;
    }
}