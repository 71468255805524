.single-matrix-product .ec-product-body.grid-layout{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
}

.single-matrix-product .ec-product-body.grid-layout > *:first-child{
    grid-column: 1 / -1;
}

.single-matrix-product .ec-product-body.grid-layout > *:last-child{
    grid-column: 1 / -1;
}

.single-matrix-product .ec-product-body.grid-layout > *:not(:first-child, :last-child){
    text-align: left;
}

.single-matrix-product .ec-product-body.grid-layout > *:not(:first-child, :last-child):nth-child(odd){
    text-align: right;
}

.single-matrix-product .ec-product-body .matrix-card-label{
    color: var(--matrix_label_color, #777777)
}

.single-matrix-product .ec-product-body .matrix-card-value{
    color: var(--matrix_value_color, #777777)
}




@media only screen and (min-width: 768px){

    .homeCardSection .slick-track:has(.single-matrix-product.product-hover-effcet) {
        gap: 2em;
       
    }

    .homeCardSection .single-matrix-product.product-hover-effcet{
        transition: all 200ms ease;
        box-shadow: none;
    }
    
    .homeCardSection .single-matrix-product.product-hover-effcet:hover {
        box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.3) !important;
        /* -webkit-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06) !important; */
        /* -moz-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06); */
        /* transform: scale(1.008); */
        
    }
}
