.custom-order-filter-form{
    padding: 1em;
    position: relative;
    min-height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;
}

.custom-order-filter-form footer{
    position: sticky;
    bottom: 0;
    margin-top: auto;
    background-color: #ffffff;
    padding: 0.5em;
    display: flex;
    gap: 1em;
}

.custom-order-filter-form footer button{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.custom-order-filter-form footer .clear-btn{
    border: 1px solid var(--btn_primary_color);
    color: var(--btn_primary_color)
}

.custom-order-filter-form footer .apply-btn{
    border: 1px solid var(--btn_primary_color);
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
}