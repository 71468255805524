

.static-page article{
    
    width: var(--box-container-width);
    margin: 0 auto !important;
    padding: 15px !important;
    position: relative;
    padding-bottom: 30px !important;
    /* overflow: hidden; */
    position: relative;
}


.dummy-static-page-container{
    opacity: 0 !important;
    user-select: none;
    pointer-events: none;
    display: none;
}

.dummy-static-page-container *{
    line-height: 2 !important;
}

.static-page iframe{
    width: 100%;
    position: relative;
    top: 0;
    
    
}


/*Hide Scroll Bar Forcefully*/
.static-page::-webkit-scrollbar, .static-page *::-webkit-scrollbar {
    display: none;
}

.static-page .static-page *{
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.static-page iframe::-webkit-scrollbar{
    display: none !important; 
}

.static-page iframe{
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* width: 95vw !important; */
  overflow: visible !important;
  border: 0 !important;
  
  
}


