.matrix-branding-section{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0.5em !important;
    margin-bottom: -3em !important;
    
    
}

.matrix-branding-section .branding-image-container{
    height: 300px;
    overflow: hidden;
}

.matrix-branding-section .branding-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.matrix-branding-section .brading-text-section{
    background-color: #fff;
    box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.17);
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    align-self: center;
    padding: 1.5em !important;
    position: relative;
    transform: translateY(-50%);
    width: min(650px, 95%);
    border-radius: 0.3rem;
}

.matrix-branding-section .brading-text-section h2{
    font-size: 2rem;
}

.matrix-branding-section .brading-text-section p{
    font-style: italic;
    font-size: 1.45rem;
}

/*--------- Mobile: 768px and larger --------------------------------------------*/
@media only screen and (min-width:768px) {

 
    .matrix-branding-section .brading-text-section h2{
        font-size: 2.5rem;
    }

    .matrix-branding-section .brading-text-section{
        padding: 2em !important;
    }

    .matrix-branding-section .branding-image-container{
        height: 420px;
    }



}