.homepage-popup-modal {
  outline: none;
}

.homepage-popup-modal:focus,
.homepage-popup-modal:focus,
.homepage-popup-modal:focus,
.homepage-popup-modal:hover,
.homepage-popup-modal:focus,
.homepage-popup-modal:focus-visible,
.homepage-popup-modal:focus,
.homepage-popup-modal:focus-within {
  outline: none;
}

.homepage-popup-modal .MuiBox-root,
.homepage-popup-modal .MuiBox-root:focus,
.homepage-popup-modal .MuiBox-root:hover,
.homepage-popup-modal .MuiBox-root:focus-visible,
.homepage-popup-modal .MuiBox-root:focus-within {
  outline: none;
}

.homepage-popup-modal .MuiBox-root {
  padding: 0;
  width: auto;
}

.homepage-popup-modal .homepage-popup-container .loader {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-self: center;
  position: absolute;
  top: 0;
  left: 0;
}

/* .homepage-popup-modal .homepage-popup-container .video-wrapper{
    max-height: 75vh;
} */

.homepage-popup-modal .homepage-popup-container .video-wrapper img,
.homepage-popup-modal .homepage-popup-container .video-wrapper video {
  max-height: 75vh;
}

@media only screen and (max-width: 576px) {
    .homepage-popup-modal .MuiBox-root {
        width: 85%;
    }
}

.homepage-popup-modal video::-webkit-media-controls {
  display: none !important;
}

.homepage-popup-modal video::-webkit-media-controls-enclosure {
  display: none !important;
}

.homepage-popup-modal video::-webkit-media-controls-panel {
  display: none !important;
}

.homepage-popup-modal .homepage-popup-container footer {
  padding: 0.5em 0.75em;
  display: flex;
}

.homepage-popup-modal .homepage-popup-container .input-group {
  display: flex;
  align-items: center;
  gap: 0.35em;
}

.homepage-popup-modal .homepage-popup-container .input-group label {
  margin: 0 !important;
  font-style: italic;
}

.homepage-popup-modal .homepage-popup-container input {
  margin: 0;
  width: 15px;
  height: 15px;
  accent-color: var(--btn_primary_color);
}

.homepage-popup-modal .homepage-popup-container button {
  background-color: var(--btn_primary_color);
  color: var(--btn_primary_foreground_color);
}

.homepage-popup-modal .homepage-popup-container .muted-btn {
  position: absolute;
  right: 0.45em;
  top: 0.45em;
  cursor: pointer;
  z-index: 1;
  font-size: 1.6rem;
}

.homepage-popup-modal .homepage-popup-container .ok-btn {
  padding: 0.25em 1em;
  border-radius: 0.3rem;
  align-self: center;
}

.homepage-popup-modal video::-webkit-media-controls {
  display: none !important;
  opacity: 0;
}
.homepage-popup-modal video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
