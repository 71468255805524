@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');

.igje-about-us-page{
    width: var(--box-container-width);
    margin: 3em auto 0;
}

/********* BANNER *************************************/
.igje-about-us-page .banner{
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 2em 1em;
    justify-content: space-around;
    align-items: center;
}



.igje-about-us-page .banner .background-text{
    font-family: "Yellowtail", cursive !important;
    position: absolute;
    font-size: 140pt;
    color: rgb(159, 142, 123);
    line-height: 0.9em;
    overflow-wrap: break-word;
    opacity: 0.06;
    z-index: -1;
    transform: rotate(-15deg);
    text-transform: none;
    width: 1500px;
    left: 0;
    right: 0;
    bottom: 0;
    top: -200px;
}

.igje-about-us-page .banner .logo-image{
    width: min(500px, 80%);
    padding: 1.5em;
    background-color: #fff;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.08);
}

.igje-about-us-page .banner h2{
    font-size: 2.4rem;
    color: rgb(130, 116, 101);
    font-weight: 600;
    letter-spacing: 5px;
}

@media only screen and (max-width: 992px){
    .igje-about-us-page .banner{
        flex-direction: column-reverse;
        gap: 3em;
        padding-bottom: 0;
    }

    .igje-about-us-page .banner h2{
        text-align: center;
        padding: 1.5em 0;
        background-color: #fff;
        width: 100%;
        position: relative;
        top: 5px;
    }
}

@media only screen and (max-width: 600px){
    .igje-about-us-page .banner h2{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 475px){
    .igje-about-us-page .banner h2{
        font-size: 1.9rem;
    }
}
    

/********* MIDDLE SECTION *************************************/
.igje-about-us-page .middle-section{
    background-color: rgb(225, 209, 193);
    padding: 4em 6em;
    margin: 3em 0 0;
}

.igje-about-us-page .middle-section article{
    display: flex;
    gap: 2em;
}

.igje-about-us-page .middle-section article:nth-child(even){
    flex-direction: row-reverse;
}

.igje-about-us-page .middle-section article + article{
    margin-top: 3em;
}

.igje-about-us-page .middle-section article > *{
    flex: 1;
}

.igje-about-us-page .middle-section article .image-section{
    border-radius: 50%;
    overflow: hidden;
    align-self: flex-start;
    
    
}

.igje-about-us-page .middle-section article .image-section img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    
}




.igje-about-us-page .middle-section article .header, 
.igje-about-us-page .middle-footer .header{
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.9rem;
    font-weight: 600;
    letter-spacing: 4px;
    color: #000000;
}

.igje-about-us-page .middle-section article ol{
    width: 70%;
    margin: 0 auto;
    font-size: 1.8rem;
    color: rgb(61, 58, 56);
    
}

.igje-about-us-page .middle-section article li{
    list-style:decimal;
    margin-left: 1em;
}

.igje-about-us-page .middle-footer {
    text-align: center;
    margin-top: 5em;
}

.igje-about-us-page .middle-footer p {
    font-size: 2.1rem;
    color: rgb(61, 58, 56);
}

.igje-about-us-page .middle-footer .email-wa{
    margin-top: 2em;
}

@media only screen and (max-width: 992px){
    .igje-about-us-page .middle-section{
        padding: 4em 2em;
    }
    .igje-about-us-page .middle-section article{
        flex-direction: column;
    }

    .igje-about-us-page .middle-section article:nth-child(even){
        flex-direction: column;
    }

    .igje-about-us-page .middle-section article .image-section{
        align-self: center;
    }

    .igje-about-us-page .middle-section article ol{
        width: 90%;
    }

    @media only screen and (max-width: 768px){
        .igje-about-us-page .middle-section article .header, 
        .igje-about-us-page .middle-footer .header{
            font-size: 1.8rem;   
        }

        .igje-about-us-page .middle-footer p {
            font-size: 1.8rem;  
        }
    }
}

/***************** ASSOCIATED SECTION **********************************/
.igje-about-us-page .associated-section{
    padding: 4em 4em 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
}

.igje-about-us-page .associated-section header{
    font-size: 3.6rem;
}

.igje-about-us-page .associated-section .logo-section{
    display: flex;
    gap: 2em;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

@media only screen and (max-width: 768px){
    .igje-about-us-page .associated-section{
        padding: 4em 2em 3em;
    }
    .igje-about-us-page .associated-section header{
        font-size: 3rem;
        text-align: center;
    }
}

/***************** FOOTER SECTION **********************************/
.igje-about-us-page footer{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3em;
    align-items: center;
    justify-content: center;
    background-color: rgb(225, 209, 193);
}

.igje-about-us-page footer header{
    font-size: 2.4rem;
    color: #000000;
    font-weight: 600;
    letter-spacing: 1px;
}

.igje-about-us-page footer p:not(.small-text){
    font-size: 2.4rem;
    color: rgb(61, 58, 56);
}

.igje-about-us-page footer p.small-text{
    font-size: 1.6rem;
    color: rgb(61, 58, 56);
}

@media only screen and (max-width: 768px){
    .igje-about-us-page footer header{
        font-size: 2.1rem;
        text-align: center;
    }

    .igje-about-us-page footer p:not(.small-text){
        font-size: 2rem;
    }

    .igje-about-us-page footer p.small-text{
        text-align: center;
    }
}