.home-menu .single-menu ul.thumbnail-image-with-menu {
  min-width: 275px !important;
}

.home-menu .single-menu ul.thumbnail-image-with-menu > li,
.home-menu .single-menu ul.thumbnail-image-with-menu > li a,
.home-menu .mega-menu ul.thumbnail-image-with-menu > li,
.home-menu .mega-menu ul.thumbnail-image-with-menu > li a {
  width: 100% !important;
}

.home-menu .single-menu ul.thumbnail-image-with-menu > li a,
.home-menu .mega-menu ul.thumbnail-image-with-menu > li a {
  display: flex;
  gap: 1em;
  align-items: center;
}

.home-menu .single-menu ul.thumbnail-image-with-menu > li a .menu-text,
.home-menu .mega-menu ul.thumbnail-image-with-menu > li a .menu-text {
  flex: 1;
}

.home-menu .single-menu ul.thumbnail-image-with-menu > li a .menu-thumb,
.home-menu .mega-menu ul.thumbnail-image-with-menu > li a .menu-thumb {
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  /* background-color: red; */
}

.home-menu .single-menu ul.thumbnail-image-with-menu > li a .menu-thumb img,
.home-menu .mega-menu ul.thumbnail-image-with-menu > li a .menu-thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* mix-blend-mode: difference; */
}

/* Handle Extracted menu  */
.home-menu
  .mega-menu
  ul.thumbnail-image-with-menu
  li:has(.extracted-subcategory-main-menu):first-child
  .menu-thumb {
  display: none;
}

.home-menu
  .mega-menu
  ul.thumbnail-image-with-menu
  li:has(.extracted-subcategory-main-menu):first-child
  .menu-text {
    text-align: center;
  }
