
.jewelsmith-contact .call .value + .value{
    margin-top: 1em;
}

.jewelsmith-contact .label{
    margin: 1em 0.5em !important; 
    display: inline-block;
}

.jewelsmith-contact .call .value a:hover{
    color: var(--highlight_color)
}