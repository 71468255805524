.change-password--modal .change-password-container{
    display: flex;
    flex-direction: column;
} 

.change-password--modal .change-password-container .password-group section{
    position: relative;
    display: flex;
    align-items: center;
    
}

.change-password--modal .change-password-container .password-group input{
    padding-right: 3em;
    border-radius: 0.3rem;
    color: var(--login_register_fg_color);
    border: 1px solid var(--login_register_fg_color);
}

.change-password--modal .change-password-container .password-group svg{
    position: absolute;
    right: 1em;
}

.change-password--modal .change-password-container button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.5em 1em;
    margin-top: 0.5em;
    text-transform: uppercase;
    border-radius: 0.3rem;
}

.change-password--modal .error{
    color: red;
    font-size: 1.3rem;
}