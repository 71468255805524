.news-subscription-modal section{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0.75em;
}

.news-subscription-modal .close-btn{
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    font-size: 3rem;
    cursor: pointer;
    color: #212121;
}

.news-subscription-modal h3{
    text-align: center;
    margin-block: 0.5em 0 0;
    color: var(--highlight_color);
    font-size: 2.2rem;
}

.news-subscription-modal p{
    text-align: center;
    font-size: 1.5rem;
}

.news-subscription-modal .input-section{
    display: flex;
    gap: 0.5em;
}

.news-subscription-modal .input-section input{
    border: 1px solid #212121;
    padding: 0.75em;
    margin: 0;
}

.news-subscription-modal .input-section .error{
    font-size: 1.3rem;
    text-align: left;
    color: red;
}

.news-subscription-modal .input-section button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0 0.75em;
    border-radius: 0.3rem;
    align-self: flex-start;
    height: 40px;
    flex: 1;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .news-subscription-modal .input-section{
        flex-direction: column;
        
    }

    .news-subscription-modal .input-section button{
        margin: 0 auto;
        padding: 0.5em 1em;
        width: 100%;
    }
}