.modal-backdrop{
    backdrop-filter: blur(5px);
}

.Search-Container .modal-content{
    border-radius: 15px;
    overflow: hidden;
    box-shadow: rgba(11, 13, 14, 0.3) 0px 4px 16px 0px;
}

.Search-Container .search-header {
    padding: 0.5em;
}

.Search-Container .btn-close{
    margin-right: 0.6em !important;
}

.Search-Container .search-icon{
    font-size: 2.5rem;
}

.Search-Container .search-header .search-input{
    margin: 0;
    border: 0;
    font-size: 2rem;
    padding-inline: 0;
    color: var(--login_register_fg_color);
}

.Search-Container .modal-dialog{
    height: auto;
    max-height: 100%;
    overflow-y: hidden;
}

.Search-Container .search-list{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.5em;
    /* padding: 1em !important; */
}

.Search-Container .search-list.search-active{
    padding: 1em !important;
}

.Search-Container .search-list{
    padding: 0;
    overflow-y: auto;
}

.Search-Container .search-list .search-result{
    display: inline-block;
    padding: 0.5em 1em!important;
    border: 2px solid #e8e8e8;
    border-radius: 0.5em;
    font-size: 1.6rem;
    
}

.Search-Container .search-list .search-result:focus{
    background-color: var(--highlight_color-opac);
    outline-color: var(--highlight_color);
    border-color: var(--highlight_color);
    color: var(--highlight_color);
}

.Search-Container .search-list .search-result:focus:hover{
    outline-color: var(--highlight_color);
}   

.Search-Container .search-list .search-result:hover{
    background-color: var(--highlight_color-opac);
    outline-color: transparent;
    border-color: var(--highlight_color);
    text-decoration: underline;
    color: var(--highlight_color);

}

.Search-Container .search-result .highlighted-text{
    color: var(--highlight_color);
}

.Search-Container .no-result .no-search-icon{
    font-size: 5rem;
}

.Search-Container .no-result{
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75em;
    font-size: 1.8rem;
}


.Search-Container .no-result-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.25em 0.75em;
    border-radius: 0.3rem;
}


