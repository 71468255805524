.modal-content{
    width: 100%;
}

.modal-header, .modal-header .h4{
    font-weight: 700;
    color: var(--btn_primary_color) !important;
    display: flex;
    gap: 0.5em;
    align-items: center;
    width: 100%;
    /* justify-content: space-between; */
    
}

.modal-header .h4 span{
    color: inherit;   
    font-size: 1.8rem !important;
}

.categoryModal .modal-dialog{
    height: auto;
    max-height: 100%;
}

.categoryModal .modal-body{
    overflow: auto;
}

.modal-dialog .modal-header span:hover {
    color: inherit;
}

.categoryModal .modal-header .h4 .modal-count, .list-group-item .product-count, .accordion-header button .product-count{
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color) !important;
    height: 25px;
    min-width: 25px;
    padding: 0 .25em;
    font-size: 1.4rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
}

.categoryModal .modal-header .backbtn{
    cursor: pointer;
}


.categoryModal .list-group-item{
    cursor: pointer;
    display: flex;
}

.categoryModal .list-group-item a{
    margin-right: auto;
}

.categoryModal .list-group-item:hover{
    background-color: var(--highlight_color-opac);
    color: var(--highlight_color);
}

.categoryModal .list-group-item .forword-arrow{
    color: #555555;
}

.categoryModal .list-group-item:hover a, .categoryModal .list-group-item:hover .forword-arrow{
    color: inherit
}


.categoryModal .list-group-item a{
    font-weight: 600;
}

.categoryModal .list-group-item{
    color: var(--highlight_color);
    gap: 0.5em;
    align-items: center;
}

.categoryModal .list-group-item .product-count{
    margin-left: auto;
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    padding: 0.5em !important;
    
}


@media only screen and (max-width:576px) {
    .categoryModal{
        width: 100%;
        padding: 0 !important;
    }

    .categoryModal .modal-dialog{
        
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
        height: 100%;
    }
}

/****************** Buttton Image *******************************************/
/* 
.categoryModal .button-img-group-container{
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
}

.categoryModal .button-img-card{
    flex: 0 1 calc(33.33% - 0.35em);
    min-width: 170px;
    aspect-ratio: 1;
    border: 1px solid #777;
} */