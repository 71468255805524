.zoom-image-container{
    width: 100%;
    position: relative;
    
}

.zoom-image-container .react-transform-wrapper, .zoom-image-container .react-transform-component{
    width: 100%;
}

.zoom-image-container .react-transform-component{
    display: flex;
    justify-content: center;
}


.zoom-image-container .tools{
    position: absolute;
    z-index: 1;
    bottom: 0.75em;
    right: 0.75em;
    display: flex;
    gap: 0.5em;
    
}

.zoom-image-container .tools button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    line-height: normal;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    font-size: 2.5rem;
}



