.payment-container{
    min-height: 50vh;
    padding: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

p{
    margin: 0;
}

.payment-card{
    padding: 1em;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    align-items: center;
    gap: 1em;
    width: min(500px, 100%);
}

.payment-card .pay-icon{
    font-size: 6rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-block: 0.15em;
}

.payment-card .payment-title{
    text-align: center;
    font-weight: 600;
    margin-block: 0.75em;
}

.payment-card hr{
    background-color: #ccc;
    height: 0.15rem;
    margin-block: 0.75em;
}

.payment-card .transaction-ref{
    text-align: center;
    font-weight: 600;
    color: var(--highlight_color);
}

.payment-card .sub-text{
    text-align: center;
}

.payment-card footer{
    margin-top: 1em;
    width: 100%;
}

.payment-card footer ul li{
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
    padding-block: 0.25em;
    font-size: 1.3rem;
}

.payment-card footer ul li + li{
    border-top: 1px solid #eee;
}