.two-col-layout{
    display: flex;
    gap: 2em;
}

.two-col-layout h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    
}

.two-col-layout > div{
    flex: 1;
}

.two-col-layout .image-section{
    display: flex;
    align-items: center;
}

.two-col-layout .image-section img{
    width: 100%;
}

.two-col-layout .detail-section{
    color: #212121;
    font-size: 1.5rem;
}

.two-col-layout .detail-section p{
    margin-bottom: 1em;
}

.two-col-layout .detail-section .btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    border-radius: 0.3rem;
}

@media only screen and (max-width: 768px) {
    .two-col-layout{
        flex-direction: column;
    }

    .two-col-layout .detail-section {
        display: flex;
        flex-direction: column;
    }

    .two-col-layout .detail-section .btn{
        margin: 0 auto;
    }
}