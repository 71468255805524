.all-order-page {
    padding-bottom: 5rem;
}

/*---------- Header --------------------------------------------------*/
.all-order-grouped-page .product_title{
    width: var(--box-container-width) !important;
    margin: 1em auto 0;
    box-sizing: border-box;
}

.all-order-page > header{
    
    padding: var(--cart-group-by-padding);
    box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 3px 25px 4px rgba(0, 0, 0, 0.06);
    position: sticky;
    /* top: 0; */
    z-index: 1;
    border-bottom: 1px solid var(--menu_separator_color);
    background-color: var(--cart_group_by_bg_color, #fff);
}

.all-order-page header .header-inner{
    width: var(--box-container-width);
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    
    
}

.all-order-page header .header-inner .status-buttons-bar{
    /* padding: var(--cart-group-by-padding); */
    background-color: var(--cart_group_by_bg_color, #fff);
    /* border-bottom: 1px solid var(--menu_separator_color); */
}

.all-order-page header .header-inner .search-bar-comp{
    /* padding: var(--cart-group-by-padding); */
    background-color: var(--cart_group_by_bg_color, #fff);
}

.all-order-page header .header-inner .search-bar-comp input{
    color: var(--all_order_cell_title_fg_color);
    border: 1px solid var(--all_order_cell_title_fg_color);
}

/*---------- All Order Cards --------------------------------------------------*/
.all-order-card-section {
    width: var(--box-container-width);
    margin: 0 auto !important;
    padding: 2em 1em;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5em;
}


.all-order-card-section .all-order-card{
    box-shadow: 0 0px 10px 5px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
    padding: 1em;
    cursor: pointer;
    background-color: var(--all_order_cell_bg_color);
    border-radius: 0.3rem;
    color: var(--all_order_cell_title_fg_color);
}

.all-order-card-section .all-order-card header{
    display: flex;
    justify-content: space-between;
}

.all-order-card-section .all-order-card header .right-section{
    display: flex;
    flex-direction: column;
    gap: 0.25em;
}



.all-order-card-section .all-order-card header h4{
    font-size: 1.45rem;
    font-weight: 600;
    color: var(--all_order_cell_title_fg_color);
}

.all-order-card-section .all-order-card .date{
    font-size: 1.3rem;
    text-align: right;
}

.all-order-card-section .all-order-card .tag{
    align-self: flex-end;
    border-radius: .3rem;
    display: inline-block;
    font-size: 1.45rem;
    font-style: normal;
    font-weight: 400;
    padding: .25em .5em;
    text-wrap: nowrap;
    margin-bottom: 0.20em;
}


.all-order-card-section .all-order-card .total{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
}

.all-order-card-section .all-order-card .total-label{
    display: flex;
    justify-content: space-between;
    padding: 0.25em 0.5em;
}

.all-order-card-section .all-order-card .total-label:nth-child(odd){
    border-right: 1px solid lightgray;
}

.all-order-card-section .all-order-card .total-label{
    border-top: 1px solid lightgray;
}

.all-order-card-section .all-order-card .total-label:first-child, 
.all-order-card-section .all-order-card .total-label:nth-child(2){
    border-top: none;
}

.all-order-card-section .all-order-card .total-label.border-bottom-label{
    border-bottom: 1px solid lightgray;
}

.all-order-card-section .all-order-card .total-label span{
    font-size: 1.45rem;
}

.all-order-card-section .all-order-card .total-label .total-label-subtitle{
    color: var(--all_order_cell_subtitle_fg_color);
}

.all-order-card-section .all-order-card .total-label .total-label-value{
    color: var(--all_order_cell_title_fg_color);
}


/**************************** MEDIA QUERY ********************************/
@media only screen and (min-width: 850px) {
    .all-order-card-section {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 992px) {
    .all-order-card-section .all-order-card header h4{
        font-size: 1.6rem;
    }

    .all-order-card-section .all-order-card header p{
        font-size: 1.45rem;
    }

    .all-order-card-section .all-order-card .total-label span{
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 1260px) {
    .all-order-card-section {
        grid-template-columns: repeat(3, 1fr);
    }
}