.aboutUs{
    padding: 2em;
}

.aboutUs header{
    text-align: center;
    margin-bottom: 2em;
}

.aboutUs header h2{
    font-weight: 600;
    font-size: 3rem;
}

.aboutUs section{
    display: flex;
    gap: 2em;
    flex-wrap: wrap;
}

.aboutUs section > *{
   flex: 1 1 350px;
}

.aboutUs .image-section{
    background-color: #242021;
    display: flex;
    align-items: center;
    overflow: hidden;
    max-height: 450px;
}


.aboutUs section .details-section h3{
    margin-bottom: 0.75em;
}

.aboutUs section .details-section p{
    margin-bottom: 1em;
}