.gold-scheme-page{
    position: relative;
    padding-bottom: 2em;
}

.gold-scheme-page p, .gold-scheme-page li{
    font-size: 1.6rem;
}


/******** BANNER-2 *************************/
.gold-scheme-page .banner-2{
    background-repeat: no-repeat;
    background-size: cover;
}

.gold-scheme-page .banner-2 .banner-2-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: min(800px, 100%);
    padding: 2em 1.5em;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.gold-scheme-page .banner-2 header h2{
    color: rgb(210, 175, 109);
}

.gold-scheme-page .banner-2 .banner-2-container p{
    color: #fff;
    text-align: center;
}


@media only screen and (max-width: 576px){
    .gold-scheme-page .banner-2 .banner-2-container{
        padding: 2em 0.75em;
    }
}

/******** BANNER-3 *************************/
.gold-scheme-page .banner-3{
    background-repeat: no-repeat;
    background-size: cover;
}


.gold-scheme-page .banner-3-container{
    width: min(800px, 100%);
    padding: 2em 2em 0;
    margin: 0 auto;

}

.gold-scheme-page .banner-3 .banner-3-container {
    display: flex;
    justify-content: space-between;
}

.gold-scheme-page .banner-3 .banner-3-container .left-side{
    align-self: center;
}

.gold-scheme-page .banner-3 .banner-3-container .left-side header{
    color: var(--highlight_color);
    margin-bottom: 2em;
    
}

.gold-scheme-page .banner-3 .banner-3-container .left-side li{
    list-style: lower-alpha;
    color: #212121;
    margin-left: 1em;
}

.gold-scheme-page .banner-3 .banner-3-container .left-side header h2{
    font-size: 2.5rem;
}

.gold-scheme-page .banner-3-container img{
    width: 250px;
}

@media only screen and (max-width: 768px){
    .gold-scheme-page .banner-3 .banner-3-container{
        flex-direction: column;
        align-items: center;
    }

    .gold-scheme-page .banner-3 .banner-3-container .left-side header h2{
        font-size: 2.2rem;
    }
}

/******** TERMS AND CONDITION *************************/

.gold-scheme-page .terms-section{
    padding: 3em;
    width: min(1100px, 100%);
    margin: 0 auto;
}

.gold-scheme-page .terms-section li{
    list-style: decimal;
    margin-left: 1em;
    color: #212121;
}

.gold-scheme-page .terms-section b{
    color: #212121;
}

.gold-scheme-page .terms-section header h3{
    color: #777;
}


/******** SCAN TO DOWNLOAD *************************/

.gold-scheme-page .scan-to-download-section{
    padding: 2em;
    width: min(1100px, 100%);
    margin: 0 auto;
}

.gold-scheme-page .scan-to-download-section img{
    width: 100%;
    
}

/******** DOWNLOAD BROCHURE *************************/

.gold-scheme-page .download-brochure-btn{
    position: fixed;
    z-index: 2;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    padding: 0.85em 1em;
    transform: rotate(-90deg);
    transform-origin: right center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    right: 20px;
    font-size: 1.2rem;
    text-transform: uppercase;

}