.jewellery-care-page{
    padding: 1.5em;
    width: min(1440px, 100%);
    margin: 0 auto;
}

.jewellery-care-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    text-align: center;
}

.jewellery-care-page .image-section{
    align-items: flex-start;
}

.jewellery-care-page .detail-section ol li{
    list-style: decimal;
    margin-left: 1em;
}

.jewellery-care-page .detail-section ol li + li{
    margin-top: 1em;
}