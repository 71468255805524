.customized-menu-option .menu{
    background: transparent;
    color: var(--login_register_fg_color, #212121);
    font-size: var(--filter-section-fs);
    font-family: inherit;
}

.customized-menu-option .menu:hover{
    background-color: transparent;
}


.inline-filter-menu-list{
    position: relative;
    max-height: 500px;
}

.inline-filter-menu-list .menuItem{
    font-size: inherit;
    max-width: 300px;
    cursor: default;
}

.inline-filter-menu-list .menuItem{
    background-color: #fff;
}

.inline-filter-menu-list .menuItem:active,
.inline-filter-menu-list .menuItem:hover,
.inline-filter-menu-list .menuItem:visited,
.inline-filter-menu-list .menuItem:focus,
.inline-filter-menu-list .menuItem:focus-visible,
.inline-filter-menu-list .menuItem:focus-within
{
    background-color: transparent !important;
    
}



/*------- MENULIST RANGE OPTION --------------------------*/
.inline-filter-menu-list .menuItem .range-section .range-input{
    display: flex;
    align-items: center;
    line-height: normal;
    gap: 0.75em;
}

.inline-filter-menu-list .menuItem .range-section .range-input label{
    margin-bottom: 0;
}

.inline-filter-menu-list .menuItem .range-section .range-input input{
    width: 75px;
    padding: 0.25em;
}


/*------- MENULIST INPUT OPTION --------------------------*/
.inline-filter-menu-list .menuItem .input-section{
    display: flex;
    align-items: center;
    line-height: normal;
    gap: 0.75em;

}

.inline-filter-menu-list .menuItem .input-section label{
    margin-bottom: 0;
    font-size: 1.55rem;
}

.inline-filter-menu-list .menuItem .input-section input{
    width: 20px;
    height: 20px;
    accent-color: var(--btn_primary_color);
    box-shadow: inset 0px 0px 0px 2px var(--btn_primary_color);
    
}

.inline-filter-menu-list .menuItem .input-section input:disabled{
    accent-color: var(--btn_primary_color);
    
}


/*-------- MENULIST FOOTER ------------------------------*/

.inline-filter-menu-list .menulist-footer{
    display: flex;
    gap: 0.25em;
    padding: 0.25em;
    padding-bottom: 0.15em;
    position: sticky;
    bottom: 0;
    background-color: #fff;

}

.inline-filter-menu-list .menulist-footer button{
    flex: 1;
    background-color: red;
    padding: 0.25em 0.5em;
    text-transform: uppercase;
    border-radius: 0.3rem;
}

.inline-filter-menu-list .menulist-footer .clear-btn{
    background-color: var(--btn_secondary_color);
    color: var(--btn_secondary_foreground_color);
    border: 1px solid var(--btn_secondary_border_color);
}

.inline-filter-menu-list .menulist-footer .apply-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
}

