.user-profile-drop-down-menu .mui-wrapper{
    min-width: auto;
    /* color: var(--btn_primary_color) !important; */
    color: #747474 !important;
}

.user-profile-drop-down-menu .mui-wrapper{
    background-color: none;
}

.user-profile-drop-down-menu.mobile-nav-menu .mui-wrapper{
    padding: 0;
}

.user-profile-drop-down-menu .mui-wrapper .icons-menu{
    font-size: 3rem;
    line-height: normal;
    
}

.user-profile-drop-down-menu.mobile-nav-menu .mui-wrapper .icons-menu{
    font-size: 20px;
}

.user-profile-drop-down-menu .mui-wrapper .MuiMenuItem-root{
    
    background-color: var(--btn_primary_color);
    
}

.MuiTouchRipple-root{
    display: none;
}




.mui-drop-down-menu .MuiMenu-paper {
    margin-top: 0.5em;
    overflow: visible;
    opacity: 1;
}

/*vissibility hidden after another modal open*/
body.hide-user-profile-dropdown .mui-drop-down-menu .MuiMenu-paper{
    
    opacity: 0 !important;
}



@media only screen and (max-width:576px) {
    .mui-drop-down-menu .MuiMenu-paper{
        margin-top: 0;
        top: initial!important;
        left: initial!important;
        bottom: 65px;
        right: 1em;
        
    }
}



.mui-drop-down-menu .MuiMenu-paper li{
    font-size: 1.5rem;
    text-transform: uppercase;
} 

.mui-drop-down-menu .MuiMenu-paper .menu-list{
    display: flex;
    gap: 0.5em;
    justify-content: flex-start;
}

.mui-drop-down-menu .MuiMenu-paper .menu-list:hover{
    background-color: var(--highlight_color-opac);
    color: #555555;
}

.mui-drop-down-menu .MuiMenu-paper .menu-list:hover .user-name{
    color: rgba(0, 0, 0, 0.87);
}

.mui-drop-down-menu .MuiMenu-paper .menu-list a:hover{
    color: inherit;
}

.mui-drop-down-menu .MuiMenu-paper .menu-list .icon{
    font-size: 1.8rem;
}



/*User Profile*/

.mui-drop-down-menu .MuiMenu-paper .menu-list.user-profile:hover {
    background-color: transparent;
    cursor: default;
}

.mui-drop-down-menu .MuiMenu-paper .menu-list.user-profile .intial{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    /* font-weight: 600; */
    border-radius: 50%;
}

.mui-drop-down-menu .MuiMenu-paper .menu-list.user-profile .wel-text{
    font-size: 1.4rem;
    line-height: normal;
    opacity: 0.75;
}
.mui-drop-down-menu .MuiMenu-paper .menu-list.user-profile .user-name{
    font-weight: 600;
}

@media only screen and (max-width:576px) {
    .mui-drop-down-menu .MuiMenu-paper .menu-list.user-profile .intial{
        width: 30px;
        height: 30px;
    }

    .mui-drop-down-menu .MuiMenu-paper .menu-list.user-profile .user-name{
        font-size: 1.4rem;
    }

    .mui-drop-down-menu .MuiMenu-paper .menu-list.user-profile .wel-text{
        font-size: 1.3rem;
    }

    .mui-drop-down-menu .MuiMenu-paper li{
        font-size: 1.4rem;
        min-height: 40px;
    }

    .mui-drop-down-menu .MuiMenu-paper .menu-list .icon{
        font-size: 1.7rem;
    }
}



