.exclusive-nri-offer-page{
    padding: 3em 3em 5em;
    width: min(1440px, 100%);
    margin: 0 auto !important;
}

.exclusive-nri-offer-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    text-align: center;
}

.exclusive-nri-offer-page p{
    font-size: 1.6rem;
    color: #212121;
}

.exclusive-nri-offer-page p + p{
    margin-top: 1em;
}

.exclusive-nri-offer-page .details-section{
    display: flex;
    flex-direction: column;
    gap: 2em;
    
}

.exclusive-nri-offer-page .details-section h3{
    margin-bottom: 1em;
   font-size: 2.2rem;
}

.exclusive-nri-offer-page .card-section {
    width: min(1000px, 100%);
    margin: 0 auto 2em;
}

.exclusive-nri-offer-page .card-container{
    display: flex;
    flex-direction: column;
    gap: 2em;
    /* padding: 2.5em;
    border: 1px solid #dfdfdf; */
    
}

.exclusive-nri-offer-page .card-container .card-details-section{
    /* align-self: flex-start; */
    align-self: center;
}

.exclusive-nri-offer-page .card-container p{
    text-align: justify;
}

.exclusive-nri-offer-page .card-section h3{
    text-align: center;
}


.exclusive-nri-offer-page .exclusive-offer-card{
    display: flex;
    gap: 2em;
    padding: 2.5em;
    border: 1px solid #dfdfdf;
    border-radius: 0.3rem;
}

.exclusive-nri-offer-page .exclusive-offer-card:nth-child(even){
    flex-direction: row-reverse;
}

.exclusive-nri-offer-page .exclusive-offer-card .card-image-section{
    flex: 1 0 300px;
}


@media only screen and (max-width: 992px){
    .exclusive-nri-offer-page .card-container p{
        text-align: left;
    }

    .exclusive-nri-offer-page .exclusive-offer-card .card-image-section{
        flex: 1 0 280px;
    }
}

@media only screen and (max-width: 768px){
    .exclusive-nri-offer-page{
        padding: 3em 2em 5em;
    }

    .exclusive-nri-offer-page .exclusive-offer-card{
        padding: 2em;
    }

    .exclusive-nri-offer-page .exclusive-offer-card:nth-child(even){
        flex-direction: row;
    }

    .exclusive-nri-offer-page .exclusive-offer-card, .exclusive-nri-offer-page .exclusive-offer-card:nth-child(even){
        flex-direction: column;
    }
}



