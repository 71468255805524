.download-app-section{
    display: flex;
    position: absolute;
    z-index: 1;
    margin-right: 1em;
}

.download-app-section.without-static-pages{
    position: relative;
    width: min(400px, 100%);
}

.download-app-section .mobile{
    flex: 1 0 110px;
}

.download-app-section .detail-section{
    background-color: var(--btn_primary_color);
    text-align: center;
    border-radius: 0.5rem;
    color: var(--btn_primary_foreground_color);
    padding: 1em;
    padding-left: 1.5em;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0.75em;
    position: relative;
    z-index: -1;
    margin-left: -15px;
}

.download-app-section .detail-section h3{
    color: var(--btn_primary_foreground_color);
    
}

.download-app-section .detail-section p{
    opacity: 0.85;
    font-size: 1.3rem;
}

.download-app-section .detail-section .store-btns{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
}

.download-app-section .detail-section .store-btns img{
    height: 40px;
}

@media only screen and (max-width:1140px) {
    .download-app-section .mobile{
        flex: 0 0 100px;
    }
}

@media only screen and (max-width:992px) {
    .download-app-section{
        width: min(475px, 100%);
        position: relative;
        margin-bottom: 1em;
    }

    .download-app-section.without-static-pages{
        margin-block: 2em !important;
        margin: 0 auto;
    }
}
