/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

textarea {
  min-height: 4rem;
}

button.ec-opt-sz {
  border: 1px solid rgb(233, 233, 233);
}

.filter-sidebar-btn.ec-opt-sz {
  border: 1px solid rgb(233, 233, 233);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ------------------------------------------------------------------------ */

button.btn.btn-primary {
  border-radius: 0.3rem;
}
.required-field {
  color: red;
  font-size: 12px;
  margin-bottom: 1rem;
}
.qty-plus-minus {
  border: 1px solid var(--fullscreen_value_color, #eeeeee);
  display: inline-block;
  height: 35px;
  overflow: hidden;
  padding: 0;
  position: relative;
  /* width: 120px;  */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
}
input:not(.no-selection) {
  border: 1px solid #eeeeee;
  margin: 10px 0px 10px;
  height: 40px;
}

.dec.ec_qtybtn {
  border-right: 1px solid var(--fullscreen_value_color, #eeeeee);
  width: 40px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: 22px;
  color: #777777;
}

input.qty-input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #444444;
  float: left;
  font-size: 15px;
  height: auto;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 40px;
  outline: none;
  font-weight: 700;
}

.inc.ec_qtybtn {
  border-left: 1px solid var(--fullscreen_value_color, #eeeeee);
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: 22px;
  color: #777777;
}

.stepper {
  display: flex;
  justify-content: center;
  /*  width: 100%;  */
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.toggle-sidebar-swipe {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.ec-sidebar-block-item {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 2px;
  border-radius: 30px;
  cursor: pointer;
  margin: 0.2rem;
}

.ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-size
  .ec-pro-variation-content
  .active {
  background-color: #e2e2e2;
}

.selected,
.single-pro-content
  .ec-pro-variation
  .ec-pro-variation-inner
  .ec-pro-variation-content
  .selected,
.ec-pro-variation
  .ec-pro-variation-inner.ec-pro-variation-size
  .ec-pro-variation-content
  .selected {
  /* background: #3474d4; */
  background: var(--btn_primary_color) !important;
  border: 1px solid var(--btn_primary_color);

}


.selected #btn-span, .selected .btn-span{
  color: var(--btn_primary_foreground_color) !important;
}

button#btn-filter-apply {
  margin: 10px;
  /* height: 40px; */
  width: 120px;
}

div#apply-btn-contaiiner {
  display: flex;
  background-color: white;
  position: sticky;
  bottom: 0px;
  margin-bottom: 10px;
}

div#apply-btn-contaiiner {
  display: flex;
  background-color: white;
  position: sticky;
  bottom: 0px;
}

.ec-sidebar-wrap {
  padding-bottom: 25%;
}

button.filter-cls-btn {
  position: absolute;
  top: 0px;
  right: 13px;
  font-size: 31px;
  color: #777;
}

.ec-product-button-group .ec-add-to-cart {
  width: 100% !important;
}

.header-top {
  border-bottom: none !important;
}

.ec-product-sbtn{
  position: relative;
}

.ec-product-sbtn .ec-product-image .pic-1 {
  width: 270px;
  height: 300px;
  object-fit: contain;
}


/* .single-slide.zoom-image-hover {
  justify-content: center;
  display: flex;
} */
/* .ec-pro-variation .ec-pro-variation-inner .ec-pro-variation-size .ec-pro-variation-content li{
  width: auto;
}

element.style {
}
.ec-pro-variation .ec-pro-variation-inner.ec-pro-variation-size .ec-pro-variation-content li:hover {
    background-color: #e2e2e2;
} */

.single-pro-content
  .ec-pro-variation
  .ec-pro-variation-inner
  .ec-pro-variation-content
  li {
  width: auto;
  padding: 5px;
  margin-bottom: 10px;
}

#btn-span, .btn-span {
  color: #777777;
  font-size: 12px;
  font-family: "Poppins, sans-serif";
  background-color: #f5f5f5;
}

.imagemagnifier-zoomed {
  width: 100%;
  height: 100%;
}

.imagemagnifier-zoomed div {
  cursor: zoom-in !important;
}

.imagemagnifier div {
  cursor: unset !important;
}

.imagemagnifier-zoomed img {
  max-width: unset !important;
}

.single-product-cover {
  overflow: hidden;
  cursor: unset;
}

.image-bottom {
  display: flex;
  justify-content: center;
}

.tap {
  display: flex;
  align-items: center;
  background-color: #dfecff;
  font-weight: 700;
  border-radius: 151px;
  padding: 8px !important;
  margin-bottom: 5px;
  width: fit-content;
}

.tap i.fi-rr-search {
  line-height: 2px;
  margin-left: 10px;
}

.ec-pro-variation .stepper {
  justify-content: space-between;
}

.single-pro-content .ec-single-qty {
  justify-content: flex-end;
}

.single-pro-desc-no-sidebar {
  text-align: center;
}

.loader {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.sm-loader {
  height: 100px;
}

.shop-pro-content .shop-pro-inner .row {
  margin-bottom: 0px;
}

section.ec-page-content {
  text-align: center;
  margin-bottom: 50px;
}

.imagemagnifier img {
  width: 100%;
}

.single-pro-img-no-sidebar {
  width: 60%;
}

/* .single-pro-desc-no-sidebar {
  width: 40%;
}

.col-sm-5 {
  display: flex;
  justify-content: flex-start;
} */

/* .ec-pro-variation-content.col-sm-7 {
  display: flex;
  justify-content: flex-start;
} */

.label {
  font-size: 14px;
  color: rgb(119, 119, 119);
  font-family: sans-serif;
  font-weight: 700;
}
/* .ec-pro-variation-inner.ec-pro-variation-size select {
  -webkit-appearance: auto;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: auto;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0 !important;
  background: #ffffff;
  background-image: none;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0 10px;
  color: #777777;
  cursor: pointer;
} */
.ec-pro-variation-inner.ec-pro-variation-size .qty-plus-minus .dec.ec_qtybtn {
  border-left: 1px solid #eeeeee;
}
.ec-pro-variation-inner.ec-pro-variation-size .qty-plus-minus {
  justify-content: end;
  width: 120px;
  /* background-color: #ffffff; */
  border-radius: 0.3rem;
}
img.verified {
  height: 150px;
  object-fit: contain;
}

.notifier .modal-content {
  width: 70%;
  /* height: 500px; */
  align-items: center;
}
.modal-dialog .modal-header {
  justify-content: space-around;
  width: 100%;
  border-color: var(--menu_separator_color);
}

.modal-dialog .modal-footer {
  border-color: var(--menu_separator_color);
}

.modal-header .btn-close{
  color: var(--login_register_fg_color);
}

.notifier .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ec-pro-list-top {
  top: 7.85%;
  /* top: 5%; */
  z-index: 9;
}

/*----------------------------------------------------------Product page CSS---------------------------------------------------------------*/

#btnView {
  display: flex !important;
  justify-content: flex-start !important;
}
#btnView #inner-btnView {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 0;
}

#btnView #inner-btnView #btn-span, #btnView #inner-btnView .btn-span {
  padding: 5px;
  margin-right: 5px;
  white-space: nowrap;
}

textarea.remarks {
  margin: 10px 0px 10px;
  border: 1px groove #eeeeee;

  /* / for removing size  / */
  /* resize: none;
  height: 100px; */
}
.total .label,
.total {
  font-size: large !important;
}

/* .single-nav-thumb .single-slide {
  width: 40% !important;
} */
/* .single-nav-thumb .single-slide {
  width: 170px !important;
} */
.mapped-image {
  width: 200px !important;
  display: flex !important;
}

#leftThumbDiv {
  width: 200px;
  display: block;
}
.left .slick-arrow {
  position: unset;
  transform: rotate(90deg);
}
.single-pro-img.single-pro-img-no-sidebar.sidebar-left {
  width: 44%;
}



.single-product-cover-left {
  width:100%;
  /* height: 500px; */
}
/* 
.single-nav-thumb .single-slide {
  width: 170px !important;
}

.mapped-image {
  width: 200px !important;
  display: flex !important;
}



*/
.ec-pro-actions{
  position: absolute;
  top: 2px;
  /* right: 10px; */
  /* display: flex;
  justify-content: space-between; */
  z-index: 1;
  /* left: 10px; */
}
/*-------------------------------------------------------------------------CART PAGE------------------------------------------------------------------------------------*/

.accordians {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  color: #777777;
  letter-spacing: 0;
  position: relative;
  line-height: 17px;
  padding: 15px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Montserrat";
}

/* .imgDiv,
.productDetail {
    height: 250px;
} */

.productName,
.status,
.weight,
.gram-net-weight,
.karat-inch,
.remark-edit,
/* .qty {
    margin: 5px 0px;
} */

.inch-dropdown {
  border: none !important;
}
input.qty-input {
  width: 40px;
}
.dec_qtybtn {
  width: 50px !important ;
}

/* .section-space-p {
  padding: 0;
  max-height: 125vh;
} */
.btn-li {
  font-weight: 600;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 50px;
  cursor: pointer;
  background-color: white;
  color: #777777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 22px;
  padding: 0.6rem;
  /* border: 1px solid #eeeeee; */
  float: left;
  margin-right: 6px;
  border-radius: 0;
  font-size: 18px;
}



.cart-footer {
  position: fixed;
  width: 100vw;
  bottom: 0;
  font-size: 16px;
  padding: 5px 0;
  background-color: #f7f7f7;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

/* .row.cart-product-details {
  flex-direction: column;
} */
.cart-product-details .half-width {
  width: 50%;
  display: flex;
  align-items: center;
}
.cart-product-details .full-width {
  width: 100%;
  display: flex;
}
.row.cart-product-details .label {
  justify-content: flex-start;
  display: flex;
  margin-right: 10px;
  
}
.remarks .label {
  justify-content: flex-start;
  display: flex;
}
.remarks {
  align-items: center;
}
.qty .label {
  justify-content: flex-start;
  display: flex;
}
.qty .half-width {
  justify-content: flex-start;
  display: flex;
}
.qty {
  align-items: center;
  margin-top: 5px;
}
.imgDiv {
  display: flex;
  align-items: center;
}
.modal-content {
  text-align: center;
  background-color: var(--login_register_bg_color);
  color: var(--login_register_fg_color);
  width: 50%;
}
/* .modal-footer {
  justify-content: space-evenly;
} */
.modal-dialog {
  display: flex;
  justify-content: center;
}

.cart-data {
  /* padding: 0 2rem ; */
  padding-bottom: 6rem;
}
/* -----------------------------------------------Header--------------------------------------------------- */

.ec-header-bottom.d-none.d-sm-block {
  padding: 10px 0;
}
.ec-header {
  z-index: 10;
}
.header-logo {
  align-self: center;
}

/* -----------------------------------------------Common--------------------------------------------------- */

svg.svg-inline--fa.fa-trash {
  cursor: pointer;
}
/* -----------------------------------------------noConnectionPage--------------------------------------------------- */
.noConnectionPage {
  height: calc(100vh - 57px);
}

/* -----------------------------------------------Checkout page--------------------------------------------------- */


.ec-register-wrapper
  .ec-register-container
  .ec-register-form
  .ec-register-wrap.ec-register-half
  .form-select {
  border: 1px groove #eeeeee !important;
}
/* -----------------------------------------------Registration page--------------------------------------------------- */

.ec-register-wrapper .ec-register-container .react-select-container .react-select__control{
    height: 50px  !important;
  }

.ec-register-wrapper .ec-register-container .react-select-container .react-select__single-value{
  font-size: 1.15rem;
  color: #777;
  }

.ec-register-wrapper .ec-register-container .react-select-container .react-select__placeholder{
  font-size: 1.12rem !important ;
}

@media only screen and (max-width: 1760px) {
    .ec-register-wrapper .ec-register-container .react-select-container .react-select__control{
      height: 50px  !important;
    }

  .ec-register-wrapper .ec-register-container .react-select-container .react-select__single-value{
    font-size: 1.1rem;
    }

  .ec-register-wrapper .ec-register-container .react-select-container .react-select__placeholder{
    font-size: 1.1rem;
  }
}


@media only screen and (max-width: 767px) {
  .ec-register-wrapper .ec-register-container .react-select-container .react-select__control{
    height: 42px  !important;
  }

  ec-register-wrapper .ec-register-container .react-select-container .react-select__single-value{
    font-size: 0.95rem !important;
    
  }

  .ec-register-container .react-select__single-value{
    font-size: 0.95rem !important;
  }

  .ec-register-wrapper .ec-register-container .react-select-container .react-select__placeholder{
    font-size: 0.95rem !important;
  }
}




.flag-dropdown {
  border: 1px solid #ededed !important;
  border-right: 0 !important;
  background: white !important;
  width: 3rem !important;
}
.flag-dropdown * {
  width: auto;
  margin:auto;
}


.password-input-wrapper {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.css-b62m3t-container {
  background-color: transparent;
  /* border: 1px solid #ededed; */
  color: #777;
  font-size: 14px;
  margin: 0;
  width: 100%;
  outline: none;
}

input.form-control.number-input {
  padding-left: 48px !important;
}

#RegContaier {
  /* min-height: 84vh; */
  display: flex;
  align-items: center;
}
#RegContaier .css-b62m3t-container input {
  border: 0 !important;
  outline: none !important;
}

#RegContaier .css-1nmdi15-menu > * {
  height: 100% !important;
}

/* #RegContaier .react-tel-input * {
  border-radius: 0px;
} */
#RegContaier .react-tel-input {
  margin: 0;
}

#RegContaier .css-1fdsijx-ValueContainer {
  padding: 0 8px !important;
}

#RegContaier .css-13cymwt-control,
#RegContaier .css-t3ipsp-control {
  background-color: transparent !important;
  border: 1px solid #ededed !important;
  color: #777 !important;
  font-size: 14px !important;
  width: 100% !important;
  outline: none !important;
  border-radius: 0px !important;
  border: 0px !important;
}

#RegContaier .css-t3ipsp-control:hover,
#RegContaier .css-t3ipsp-control {
  border: 0px !important;
  box-shadow: 0 0 0 0px;
}
#RegContaier .RegInp {
  margin: 0px;
  display: flex;
}

/* #RegContaier .number-input,
#RegContaier .RegInp,
#RegContaier .css-13cymwt-control,
#RegContaier .css-13cymwt-control > * {
  height: 50px !important;
} */

/* #RegContaier .css-13cymwt-control .css-1fdsijx-ValueContainer .css-qbdosj-Input {

  margin: 0px !important;
  padding: 0px !important;
} */

#RegContaier .required-field{
  margin-bottom: 10px;
}




#LoginContainer {
  /* height: 90vh; */
  display: flex;
  align-items: center;
}

#LoginContainer .react-tel-input * {
  border-radius: 0px;
}
/* #LoginContainer .react-tel-input {
  margin: 10px 0;
} */


/*Hide for avoid conflict*/
/* #LoginContainer .number-input,
#LoginContainer input:not(.no-selection) {
  height: 50px !important;
} */



/* -------------------------------------------------------------Verification------------------------------------------------------------- */

#verificationModal .modal-content {
  width: 80vw;
  height: 60vh;
}

#verificationModal .CheckIcon * {
  font-size: 5rem;
  margin: 1rem;
}

#Verification .outer-box {
  height: calc(100vh - 68px);
}

#Verification .box {
  max-width: 95%;
  min-width: 60%;
  min-height: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.ec-header-user.dropdown.show .dropdown-menu.dropdown-menu-right {
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate(0px, 33.3333px);
}

.btn-primary:focus{
  background: gray !important;
}
.btn-outline-dark:focus{
  color: #ffffff;
    background-color: #343A40;
}
.anchor-tag:focus{
  color: #0a58ca;
}

/* add this background style for dynamic colouring the background of buttons from api */
.button_background_color {
  background-color: var(--button_background_color, #3474d4) !important;
}
.login_register_bg_color {
  background-color: var(--login_register_bg_color, #3474d4) !important;
}
.login_register_fg_color {
  color: var(--login_register_fg_color, #fff) !important;
}
.add-to-cart-button {
  background-color: var(--button_background_color, #3474d4) !important;
  color: var(--button_foreground_color, #fff  ) !important;
  border: 0px solid var(--button_boarder_color);
}
.update-button {
  background-color: var(--update_btn_bg_color, #3474d4) !important;
  color: var(--update_btn_fg_color, #fff  ) !important;
  border: 0px solid var(--button_boarder_color);
}
.btn_primary_color {
  background-color: var(--btn_primary_color, #3474d4) !important;
}
.btn_primary_foreground_color {
  color: var(--btn_primary_foreground_color, #fff) !important;
}
.button_border{
  border: 1px solid var(--btn_primary_color) !important;
}
.btn_secondary_foreground_color {
  color: var(--btn_secondary_foreground_color, #fff) !important;
}
/* .nav_secondary_bar_button_border{
  border: 1px solid var(--nav_secondary_bar_button_border) !important;
} */
.btn_secondary_border_color{
  border: 1px solid var(--btn_secondary_border_color) !important;
}


.fsv_bottom_toolbar{
  background-color: var(--fsv_bottom_toolbar_bg_color, #fff) !important;
}
.primary_button{
  background-color: var(--btn_primary_color, #3474d4) !important;
  color: var(--btn_primary_foreground_color, #fff) !important;
}
.btn_secondary_color{
  background-color: var(--btn_secondary_color, rgb(234, 234, 234)) !important;
  
}
.btn_secondary_foreground_color{
  color: var(--btn_secondary_foreground_color, #3474d4) !important;
  
}
.secondary_button_border{
  border: 1px solid var(--btn_secondary_border_color) !important;
}
.secondary_button{
  background-color: transparent !important;
  color: var(--btn_primary_color, #fff) !important;
  border: 1px solid var(--btn_primary_color) !important;
}
.border_radius{
  border-radius: 0.3rem
}
.anchor-tag{
  
    color: #555;
    text-decoration: underline;
    transition: all 0.3s ease 0s;    
    text-decoration: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}
.anchor-tag:hover {
  color: #0a58ca;
}





/* ***** */
.imagemagnifier-zoomed img{
  /* aspect-ratio: 15/10; */
  aspect-ratio: 1;
  object-fit: contain;
}

.single-nav-thumb .slick-track{
  height:auto !important;
}
.site-header-logo {
  text-align: center;
  margin-top: 20px;
  /* margin-bottom: 20px; */
}

.site-header-logo img{
  width: 265px !important; 
}
@media only screen and (max-width:768px){
  .cart-product-details .half-width {
    width: 100%;
}
}
@media only screen and (max-width:576px){
  .imagemagnifier-zoomed img{
    position: relative;
  } 

  .header-logo img {
    margin-top: 8px;
    width: 50%;
  }
  .cart-shortlist-section{
    top:10%
  }
  
}
@media only screen and (max-width:425px){
  
  .header-logo img {
    margin-top: 8px;
    width: 60%;
  }
}
@media only screen and (max-width:375px){
  
  .header-logo img {
    margin-top: 8px;
    width: 70%;
  }
}


.no-selection::selection{
  color: #343A40 !important;
  background: transparent !important;
}

.example::-webkit-scrollbar-track {
  visibility: hidden;  
}
.example::-webkit-scrollbar {
  width: 10px;
  height: 0;
}

.auth-comp{
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
}

.products-page:focus { 
  outline: none;
 }

.whishlist-selected{
color: var(--highlight_color);
  /* filter: opacity(.5) drop-shadow(0 0 var(--highlight_color));   */
}
 .whishlist{
  cursor: pointer; 
}
.whishlist-circle{
  align-self: flex-start;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid;
  font-size: 10px !important;
  border-radius: 9999px;
  /* width: 30px; */
    /* padding: 2px; */
}

.whishlist-circle i{
  margin-top: 3px;
}

.matrix-icons{
  height:30px
}

.filter-buttons {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 2px;
  /* border-radius: 30px; */
  cursor: pointer;
  margin: 0.2rem;
}

.header-img-icon{
  height: 25px;
}


.prod-accordian-header{
  background-color: #f7f7f7; 
  background-color: var(--cart_summary_section_bg_color) !important;
  color: var(--cart_summary_section_detail_fg_color)!important;
}
.accordion-button{
  background-color: var(--cart_summary_section_bg_color, #f7f7f7); 
  /* background-color: var(--cart_summary_section_bg_color) !important; */
  color: var(--cart_summary_section_detail_fg_color)!important;

}
.accordion-button::after{
  filter: opacity(.5) drop-shadow(0 0 var(--cart_summary_section_detail_fg_color));
}
.accordion-button:not(.collapsed)::after{
  filter: opacity(.5) drop-shadow(0 0 var(--cart_summary_section_detail_fg_color))!important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}
.group-by{
  background-color: var(--cart_group_by_bg_color) !important;
}
.group-by-label{
  /* color: #777; */
  color: var(--cart_group_by_label_color) !important ;
    font-size: 14px;
    font-weight: normal ;
}

.group-by-buttons{
  font-size: 13px !important;
height: 40px !important;
padding: 0px 10px !important;
border-radius: 5px !important;
font-weight: normal ;

}

.group-by-buttons-selected{
  background: var(--btn_primary_color) !important;
  color: var(--btn_primary_foreground_color) !important;
  border: 1px solid var(--btn_primary_color) !important;
}
.group-by-buttons-unselected{
  /* background: var(--button_unselected_bg_color) !important; */
  color: var(--button_unselected_fg_color) !important;
  border: 1px solid var(--button_unselected_border_color) !important;
}
.accordion{
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  
}

.accordion .accordion-button:not(.collapsed){
  /* background-color: #f7f7f7; */
  background-color: var(--cart_summary_section_bg_color, #f7f7f7);
  /* border-bottom: 2px solid #eee !important; */
}

.prod-accordian-header{
  border-radius: 5px;
}

.cart-product-detail.label{
  /* font-size: 14px; */
  font-weight: normal ;
}

.filter-by{
  filter: opacity(.5) drop-shadow(0 0 var(#777777));  
  color: #777777;
  line-height: initial !important;
}
.ec-gl-btn .btn:hover{
  background-color: #ffffff;
  color: #777777;
  border: 1px solid #eeeeee;
}
.ec-gl-btn .btn:hover i {
  color: #000;
}
.cart-data{
  height: 100vh;
  overflow-y: scroll;
}

.ec-page-content ::-webkit-scrollbar {
  width: 0px;
}


.rc-slider-handle{
  border: solid 2px var(--highlight_color);
  background-color: var(--highlight_color);
}

.rc-slider-track{
  background-color: var(--highlight_color);

}

.ec-shop-rightside .ec-pro-list-top{
  border-radius: 0%;
}
.ec-header-bottom{
  border-bottom: 0px;
}

.ec-right-bottom {
  top: 75%;
}
.ec-style .ec-right-bottom .ec-box .hovered{
  padding-right: 0; /* Initial value */
  /* Your other styles here */
  /* Define transition properties */
  transition: padding-right 0.3s ease;
  color: var(--btn_primary_foreground_color) !important;
}
.ec-style .ec-right-bottom:hover .ec-box .hovered{
  padding-right: 80px;
  /* Your hover styles here */

}
.ec-right-bottom{
right: -75px;
}

.ec-right-bottom .ec-box{
  border-radius: 5px;
}
.ec-style .ec-button .filter-color {
  color: var(--btn_primary_foreground_color, #fff  ) !important;
  }


/******************************** OTP Inputs *********************************************/
/* .ec-login-wrap .otp-inputs > div{
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    gap: 1em;
}

.ec-login-wrap .otp-inputs .no-selection{
    
    justify-self: center;
    align-self: center !important;
    position: relative;
    top: 14px;
    
    
} */

