.button-img-group-container{
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
}

/* =============== thumbimg_centertitle_centercount ===================================*/

.thumbimg_centertitle_centercount {
  display: flex;
  align-items: center;
}

.thumbimg_centertitle_centercount .ec-card-image {
  width: 50%;
}

.thumbimg_centertitle_centercount .ec-card-details {
  width: 50%;
  text-align: center;
}

/* =============== fullbgimg_notitle_centerbottomcount ===================================*/

.fullbgimg_notitle_centerbottomcount .ec-card-image {
  /* height: 150px; */
  aspect-ratio: var(--homescreen_category_height_ratio);
}

.fullbgimg_notitle_centerbottomcount .ec-card-image img {
  height: 100%;
}

.fullbgimg_notitle_centerbottomcount .ec-card-details {
  text-align: center;
}

.fullbgimg_notitle_centerbottomcount .cat_name {
  display: none;
}

/* =============== fullbgimg_notitle_rightbottomcount - OLD ===================================*/

.fullbgimg_notitle_rightbottomcount .ec-card-image {
  
  aspect-ratio: var(--homescreen_category_height_ratio);
}

.fullbgimg_notitle_rightbottomcount .ec-card-image img {
  height: 100%;
}

.fullbgimg_notitle_rightbottomcount .ec-card-details {
  text-align: right;
}

.fullbgimg_notitle_rightbottomcount .cat_name {
  display: none;
}


/* =============== fullbgimg_notitle_rightbottomcount - NEW ===================================*/
.fullbgimg_notitle_rightbottomcount{
  position: relative;
}

.fullbgimg_notitle_rightbottomcount .ec-card-details {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  
}

.fullbgimg_notitle_rightbottomcount .ec-card-details p{
  font-size: 1.6rem;
  color: #212121;
  background-color: rgba(255,255,255, 0.3);
  padding: 0.25em 0.75em;
  border-radius: 0.3rem;
}


/* =============== fullbgimg_centertitle_centercount ===================================*/
.fullbgimg_centertitle_centercount {
  position: relative;
  min-height: 150px;
}

.fullbgimg_centertitle_centercount .ec-card-image {
  /* height: 150px; */
  aspect-ratio: var(--homescreen_category_height_ratio);

}

.fullbgimg_centertitle_centercount .ec-card-image img {
  height: 100%;
  object-fit: cover;
}

.fullbgimg_centertitle_centercount .ec-card-details {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}


.fullbgimg_centertitle_centercount .ec-card-details *{
  color:  inherit !important;
}

.fullbgimg_centertitle_centercount.with_image .ec-card-details{
  left: 25%;
  
}


/* =============== fullstackimg_nllefttitle_slrightcount ===================================*/

.fullstackimg_nllefttitle_slrightcount .ec-card-image {
  /* height: 150px; */
  aspect-ratio: var(--homescreen_category_height_ratio);
}

.fullstackimg_nllefttitle_slrightcount .ec-card-image img {
  height: 100%;
}

.fullstackimg_nllefttitle_slrightcount .ec-card-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* =============== fullstackimg_nllefttitle_slrightcount_aboveimg ===================================*/

.fullstackimg_nllefttitle_slrightcount_aboveimg{
    display: flex;
    flex-direction: column-reverse;
}

.fullstackimg_nllefttitle_slrightcount_aboveimg .ec-card-image {
  /* height: 150px; */
  aspect-ratio: var(--homescreen_category_height_ratio);
}

.fullstackimg_nllefttitle_slrightcount_aboveimg .ec-card-image img {
  height: 100%;
}

.fullstackimg_nllefttitle_slrightcount_aboveimg .ec-card-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* =============== minibgimg_nllefttitle_nlleftcount / minibgimg_nlcentertitle_nlcentercount ===================================*/
.category-card:has(.minibgimg_nllefttitle_nlleftcount){
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1em !important;
}
.minibgimg_nllefttitle_nlleftcount{
  display: flex;
  flex-direction: column;
}

.minibgimg_nllefttitle_nlleftcount .ec-card-image{
  flex: 1;
  display: flex;
  align-items: center;
}

.minibgimg_nllefttitle_nlleftcount .ec-card-details{
    text-align: left;
}

.minibgimg_nlcentertitle_nlcentercount .ec-card-details{
    text-align: center;
}

@media only screen and (min-width: 425px){
  .category-card:has(.minibgimg_nllefttitle_nlleftcount){
    grid-template-columns: repeat(2, 1fr);
  }
}


@media only screen and (min-width: 768px){
  .category-card:has(.minibgimg_nllefttitle_nlleftcount){
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    gap: 1.5em;
  }
}

@media only screen and (min-width: 1140px){
  .category-card:has(.minibgimg_nllefttitle_nlleftcount){
    grid-template-columns: repeat(4, minmax(20%, 1fr));
  }

  .modal-body .category-card:has(.minibgimg_nllefttitle_nlleftcount){
    grid-template-columns: repeat(3, minmax(20%, 1fr));
  }
}
  
  

/* <<<<<<<<<<<<<<<<<<<<<<<<< MEDIA QUERY >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
@media only screen and (min-width: 400px) {
    .minibgimg_nllefttitle_nlleftcount, .minibgimg_nlcentertitle_nlcentercount{
        width: calc(50% - 0.5em) !important;
    }
}

@media only screen and (min-width: 600px) {
  /* thumbimg_centertitle_centercount */
  .thumbimg_centertitle_centercount {
    width: calc(50% - 0.5em) !important;
  }

  .fullbgimg_notitle_centerbottomcount,
  .fullbgimg_notitle_rightbottomcount,
  .fullstackimg_nllefttitle_slrightcount,
  .fullbgimg_centertitle_centercount,
  .fullstackimg_nllefttitle_slrightcount_aboveimg {
    width: calc(50% - 0.5em) !important;
  }
}

@media only screen and (min-width: 768px) {
    .minibgimg_nllefttitle_nlleftcount, .minibgimg_nlcentertitle_nlcentercount{
        width: calc(33.33% - 0.75em) !important;
    }
}

@media only screen and (min-width: 992px) {
  /* thumbimg_centertitle_centercount */
  .thumbimg_centertitle_centercount {
    width: calc(33.33% - 0.75em) !important;
  }

  .fullbgimg_notitle_centerbottomcount,
  .fullbgimg_notitle_rightbottomcount,
  .fullstackimg_nllefttitle_slrightcount,
  .fullbgimg_centertitle_centercount,
  .fullstackimg_nllefttitle_slrightcount_aboveimg {
    width: calc(33.33% - 0.75em) !important;
  }
}


/* @media only screen and (min-width: 1160px) {
    .minibgimg_nllefttitle_nlleftcount:not(.modal-button-image), .minibgimg_nlcentertitle_nlcentercount:not(.modal-button-image){
        width: calc(25% - 0.75em) !important;
    }
} */

/* @media only screen and (min-width: 1440px) {
    .minibgimg_nllefttitle_nlleftcount:not(.modal-button-image), .minibgimg_nlcentertitle_nlcentercount:not(.modal-button-image){
        width: calc(20% - 1em) !important;
    }
} */

/* @media only screen and (min-width:1200px) {
    .fullbgimg_notitle_centerbottomcount, .fullbgimg_notitle_rightbottomcount{
        width: calc(25% - 0.75em) !important;
    }
} */
