.live-rate-app-page .MuiBox-root{
    border-radius: 0;
}

/***** HEADER ***********************************/

.live-rate-app-page .live-rate-section header{
    padding: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
 }

.live-rate-app-page .live-rate-section header h2{
    display: none;
    font-weight: 600;
}

.live-rate-app-page .live-rate-section header .close-btn{
    font-size: 2.2rem;
    color: #212121;
    
}

/***** live-rate-body ***********************************/
.live-rate-app-page .live-rate-body{
    position: relative;
}

.live-rate-app-page .last-update-bar{
    display: flex;
    padding: 0.5em;
    background-color: var(--live_rate_bg_color);
    color: var(--live_rate_secondary_fg_color);
    font-weight: 500;
    justify-content: center;
    gap: 0.5em;
    
}

/***** live-rate-container ***********************************/
.live-rate-app-page .live-rate-container{
    display: flex;
    flex-direction: column;
    background-color: #f8f6f7;
    width: min(1200px,100%);
    margin: 0 auto 2em;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    border: 1px solid #d9d9d9;
    border-radius: 0.3rem;
    overflow: hidden;
}

.live-rate-app-page .live-rate-container .rows{
    display: flex;
    padding: 0.5em 1em;
    background-color: #fff;
    color: #212121;
    align-items: center;
    gap: 0.5em;
}

.live-rate-app-page .live-rate-container .rows + .rows{
    border-top: 1px solid #f8f6f7;
}

.live-rate-app-page .live-rate-container .header.rows{
    background-color: #f8f6f7;
    font-weight: 600;
}

.live-rate-app-page .live-rate-container .rows .name{
    flex: 3;
    font-weight: 600;
}

.live-rate-app-page .live-rate-container .rows > div:not(.name){
    flex: 1;
    text-align: center;
}

.live-rate-app-page .live-rate-container .rows .buy,
.live-rate-app-page .live-rate-container .rows .sell{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.live-rate-app-page .live-rate-container .rows .buy .block{
    font-weight: 600;
}

.live-rate-app-page .live-rate-container .rows .block{
    background-color: var(--live_rate_boxed_default_bg_color);
    color: var(--live_rate_boxed_default_fg_color);
    padding: 0.25em 0.35em;
    min-width: 150px;
}

.live-rate-app-page .block.red-block{
    background-color: var(--live_rate_boxed_red_bg_color) !important;
    color: var(--live_rate_boxed_red_fg_color) !important;
}

.live-rate-app-page .block.green-block{
    background-color: var(--live_rate_boxed_green_bg_color) !important;
    color: var(--live_rate_boxed_green_fg_color) !important;
}

.live-rate-app-page .live-rate-container .rows .small{
    font-size: 1.2rem;
}


.live-rate-app-page .live-rate-container .section + .section{
    margin-top: 1em;
}

@media only screen and (max-width: 576px){
    .live-rate-app-page .live-rate-section header h2{
        display: block;
    };

    .live-rate-app-page .live-rate-section header{
        margin: 0;
    }

    .live-rate-app-page .live-rate-container{
        box-shadow: none;
        border: none;
    }

    .live-rate-app-page .live-rate-container .rows .block{
        min-width: 90px;
    }
}