.PageNotFound_Section{
    
    font-family: 'Rubik',sans-serif;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap: 3em;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1em;
    position: relative;
}

.PageNotFound_Section .bgImg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.05;
    object-fit: cover;
    
    
}

.PageNotFound_Section .img{
    width: min(700px, 100%);
    
}

.PageNotFound_Section .details{
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    font-family: inherit !important;
    text-align: center;
}

.PageNotFound_Section .details h2{
    margin: 0 !important;
    font-size: 2.5rem;
}   

.PageNotFound_Section .details p{
    font-size: 1.6rem;
    color: crimson;
}

.PageNotFound_Section .refreshBtn{
    background-color: crimson;
    color: #fff;
    padding: 0.5em 1em;
    text-transform: capitalize;
    font-size: 1.6rem;
    border-radius: 0.3rem;
    letter-spacing: 0.5px;
}


@media only screen and (min-width:768px) {
    .PageNotFound_Section .details h2{
        font-size: 3.5rem;
    }   
    
    .PageNotFound_Section .details p{
        font-size: 2.5rem;
    }
}


@media only screen and (min-width:1440px) {
    .PageNotFound_Section{
        flex-direction: row;
    }

    .PageNotFound_Section .details h2{
        font-size: 4rem;
    }   
    
    .PageNotFound_Section .details p{
        font-size: 3rem;
        
    }
}