/* PINCH ZOOM */
body.active-pinch-zoom {
  touch-action: auto;
  -ms-touch-action: auto;
  overflow: auto;
}

.header-top.d-lg-block .container-fluid {
  width: var(--box-container-width);
  margin: 0 auto;
}

.header-top-login-reg {
  display: flex;
  gap: 1em;
}

.mobile-menu-login-reg {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  text-align: center;
  justify-content: stretch;
}

.header-top-login-reg a,
.mobile-menu-login-reg a,
.header-top-login-reg button {
  background-color: var(--btn_primary_color);
  display: inline-block;
  padding: 0.25em 1em;
  border-radius: 5px;
  color: var(--btn_primary_foreground_color);
  text-transform: uppercase;
  line-height: normal;
}

.header-top-login-reg button {
  background-color: transparent;
  color: var(--btn_primary_color);
  min-width: 125px;
  min-width: 150px;
  padding: 0.5em 1em;
  font-weight: 600;
  border: 1px solid var(--btn_primary_color);
}

.header-top-login-reg button.selected {
  background-color: var(--btn_primary_color);
  color: var(--btn_primary_foreground_color);
}

.mobile-menu-login-reg a {
  font-size: var(--btn-fs);
  padding: var(--primary-btn-padding);
}

/*------ Home Header Menu -------------------------------------------------*/
.home-page {
  min-height: 70vh;
}

.home-menu {
  border-bottom: 1px solid var(--web_separator_color, #eee);
  background-color: var(--web_nav_bar_bg_color, #ffffff);
}

.home-menu.sticky-menu {
  position: sticky;
  top: 0;
  z-index: 8;
}

.home-menu ul li.active > a,
.home-menu ul li > a:hover {
  color: var(--btn_primary_color) !important;
}

.home-menu ul.sub-menu li > a:hover,
.home-menu ul.mega-menu li > a:hover
{
  color: var(--btn_primary_color) !important;
}

.home-menu ul li.active > a::before,
.home-menu ul li > a::before {
  background-color: var(--btn_primary_color) !important;
}

.home-menu ul li > a.active-link {
  color: var(--btn_primary_color) !important;
}

.home-menu ul li > a.active-link::before {
  width: 100%;
}

.home-menu ul li:hover > a {
  color: var(--btn_primary_color) !important;
}

.home-menu .ec-main-menu ul li .mega-menu {
  transition: transform 50ms ease-in-out;
  /* transition: all 0.15s ease-in-out; */
  max-height: 500px;
  overflow-y: auto;
  background-color: var(--menu_bg_color, "#fff");
  border: 1px solid var(--menu_separator_color)
}

.home-menu .ec-main-menu ul li.dropdown ul li {
  flex-wrap: wrap;
  gap: 0 1.5em;
  width: fit-content;
}

.home-menu .ec-main-menu ul li .mega-menu li ul {
  width: max-content;
  min-width: fit-content;
  margin-right: 0 !important;
}

.home-menu .ec-main-menu ul li.dropdown.position-static {
  position: relative !important;
}

.home-menu .ec-main-menu ul li.dropdown ul.sub-menu {
  transition: none;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.home-menu .ec-main-menu ul li.video_call_menu a,
.header-mobile-menu .video_call_menu {
  display: flex !important;
  align-items: center;
  gap: 0.25em;
}

.home-menu .ec-main-menu ul li.video_call_menu a .video_call_icon {
  font-size: 2rem;
  /* transform: rotate(180deg); */
}

.home-menu .ec-main-menu ul li.dropdown ul.sub-menu.horizontal-menu {
  display: flex;
  width: max-content;
}

.home-menu .ec-main-menu ul li.dropdown ul.sub-menu.fixd-left-overflow {
  left: 0 !important;
  transform: translateX(0%) !important;
}

.home-menu .ec-main-menu ul li.dropdown ul.sub-menu.fixd-right-overflow {
  left: 100% !important;
  transform: translateX(-100%) !important;
}

.home-menu .ec-main-menu ul li.dropdown:hover .sub-menu {
  transition: all 50ms ease-in-out;
  background-color: var(--web_nav_bar_bg_color);
  border: 1px solid var(--menu_separator_color);
  /* transition: all 0.3s ease-in-out; */
}

.home-menu .ec-main-menu ul li .mega-menu {
  width: fit-content;
  padding: 1em 2em !important;
}

.home-menu .ec-main-menu ul li .mega-menu:not(.center-horizontal-menu) {
  left: 50% !important;
  transform: translateX(-50%);
  /* min-width: fit-content; */
  min-width: 300px;
}

.home-menu .ec-main-menu ul li .mega-menu.fixd-left-overflow {
  left: 0 !important;
  transform: translateX(0%) !important;
}

.home-menu .ec-main-menu ul li .mega-menu.center-horizontal-menu {
  transform: translateX(0%) !important;
  left: 0;
}

.home-menu .ec-main-menu ul li .mega-menu.fixd-right-overflow {
  left: 100% !important;
  transform: translateX(-100%) !important;
}

.home-menu .ec-main-menu ul li .mega-menu > li {
  width: 100% !important;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
}

.header-mobile-menu {
  z-index: 9999 !important;
  padding: 0 !important;
  /* padding-bottom: 60px !important; */
  background-color: var(--menu_bg_color, #ffffff);
}

.header-mobile-menu a.general-link, 
.header-mobile-menu .dropdown a,
.header-mobile-menu .dropdown .menu-toggle::before
{
  color: var(--menu_text_color, #444444) !important;
}

.header-mobile-menu .dropdown:hover > a,
.header-mobile-menu .dropdown:hover .menu-toggle::before
{
  color: var(--menu_text_color, #444444) !important;
}

.header-mobile-menu .dropdown .sub-menu a{
  border-color: var(--menu_separator_color) !important;
}

.header-mobile-menu .ec-menu-title {
  padding: 1rem !important;
  margin-bottom: 0 !important;
}
.header-mobile-menu .menu_title {
  color: var(--menu_header_text_color, --btn_primary_color) !important;
  font-family: inherit !important;
}

.header-mobile-menu .ec-menu-inner {
  padding: 1em;
}

.header-mobile-menu .browse-by-category-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.header-mobile-menu .browse-by-category-mobile h3 {
  text-transform: uppercase;
  color: var(--btn_primary_color);
  font-weight: 600;
}

.header-mobile-menu .browse-by-category-mobile .header-top-login-reg {
  width: 100%;
}

.header-mobile-menu .browse-by-category-mobile .header-top-login-reg button {
  flex: 1;
}

.header-mobile-menu .logout-option {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.header-mobile-menu .logout-icon {
  font-size: 1.5rem;
}

.main-header .ec-header-bottons {
  align-items: center;
  gap: 1em;
}

.main-header .ec-header-bottons.filter-icon {
  position: relative;
}

.main-header .ec-header-bottons.filter-icon.checked::before {
  content: " \2714";
  position: absolute;
  background-color: var(--count-color);
  font-size: var(--count-fs);
  min-width: 16px;
  border-radius: 999px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  right: -4px;
  top: -4px;
}

.main-header .ec-header-bottons i {
  color: #777;
  font-size: 25px;
}

.main-header .ec-header-bottons .logout-icon {
  font-size: 4.5rem;
  margin: 0;
  cursor: pointer;
}

.main-header .ec-header-bottons .logout-icon.logout-icon-right {
  font-size: 3.75rem;
  color: #777;
  margin-left: -15px !important;
}

.main-header .header-buttons {
  width: 30px !important;
}

.header-bottom .logo-container {
  margin-right: auto !important;
}

.main-header .search {
  /* margin-left: auto !important; */
  margin-right: 1rem;
  font-size: 2.5rem;
  cursor: pointer;
  margin-left: auto;
}

.header-cart-icon svg,
.header-cart-icon svg,
.header-wishlist-icon svg,
.main-header .header-user-profile-menu svg,
.header-search-icon-menu svg,
.main-header .ec-header-bottons i,
.header-mob-nav .fi-rr-menu-burger,
.header-mob-nav .mobile-search,
.header-mob-nav .view-style-option-btn svg
{
  color: var(--web_nav_bar_icon_color);
}

.header-bottom .logo-container {
  padding-top: 0.5em;
}

.logo-container.center_compnay_logo {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 576px) {
  .header-mob-nav .logo-container-mob {
    display: flex !important;
    align-items: center !important;
  }

  .header-mob-nav .header-logo {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .header-mob-nav .logo-container-mob .burger-menu {
    line-height: normal;
    position: relative;
    top: 4px;
  }

  .header-mob-nav .logo-container-mob .fi-rr-menu-burger {
    font-size: 25px;
    /* color: #777; */
  }

  .header-mob-nav .logo-container-mob:has(.view-style-option-btn) .header-logo {
    position: relative;
    left: 15px;
  }

  .header-mob-nav
    .logo-container-mob:has(.view-style-option-btn)
    .mobile-search {
    margin-left: 0.75em;
  }

  .header-mob-nav .logo-container-mob .mobile-search {
    /* color: #777; */
    font-size: 2rem;
    cursor: pointer;
  }
}

@media only screen and (min-width: 768px) {
  .main-header .search {
    margin-right: 2rem !important;
  }
}

/*------ Home All Silder -------------------------------------------------*/

.categorySection {
  width: var(--box-container-width);
  margin: 0 auto;
}

.homeCardSection .slick-track {
  /* display: flex; */
  /* gap: 0.5em; */
  padding: 2em 0;
}

.homeCardSection .slick-track .slick-slide {
  padding-inline: 0.5em;
}

.banner-slick .slick-arrow,
.categories-slick .slick-arrow,
.trending-slick .slick-arrow {
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: auto;
  border-radius: 0;
  text-align: center;

  background-color: var(--btn_primary_color);
  display: none !important;
}

.swiper-wrapper .video-banner video::-webkit-media-controls {
  display: none !important;
}

.swiper-wrapper .video-banner video::-webkit-media-controls-enclosure {
  display: none !important;
}

.swiper-wrapper .video-banner video::-webkit-media-controls-panel {
  display: none !important;
}

.banner-slick .slick-arrow::before,
.categories-slick .slick-arrow::before,
.trending-slick .slick-arrow::before {
  color: var(--btn_primary_foreground_color);
}

.categories-slick > button:first-child::before {
  left: -2px;
  top: -1px;
}

.categories-slick > button:last-child::before {
  right: -2px;
  top: -1px;
}

.categories-slick .slick-arrow,
.trending-slick .slick-arrow {
  border-radius: 50%;
}

.categories-slick .slick-arrow.slick-prev::before,
.trending-slick .slick-arrow.slick-prev::before {
  left: -1px;
  top: -1px;
}

.categories-slick .slick-arrow.slick-next::before,
.trending-slick .slick-arrow.slick-next::before {
  left: 1px;
  top: -1px;
}

/*------ Product Based Section -------------------------------------------------*/

/* .homeCardSection .add-to-cart-button{
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color) !important;
} */

.productBased-main-section {
  /* background-color: rgb(87,0,0); */
  padding: 0.75em 0;
}

.productBased-main-section .row > * {
  padding: 0 !important;
}

.productBased-main-section .ec-title {
  /* color: #fff !important; */
  font-size: var(--category-title-fs);
}

.productBased-main-section .btn-primary {
  /* color: #fff !important; */
  background-color: transparent !important;
  /* border-color: #fff !important; */
  font-size: 1.6rem;
  /* font-weight: 600; */
}

.productBased-main-section .container-fluid {
  width: var(--box-container-width);
}

/* .productBased-main-section .section-header{
    flex-direction: column;
} */

.productBased-strip-container {
  padding: 0 15px !important;
  position: relative;
}

.productBased-strip-container .cat-displayImg {
  display: none;
}

.productBased-strip-container .cards-section.no-display {
  width: 100%;
  margin-left: 0;
}

/*------ Branding Based Section -----------------------------------------------*/
.branding-based-section {
  display: flex;
  /* margin: 0 1em 2em; */
  flex-direction: column;
  position: relative;
  align-items: center;
  /* min-height: 250px; */
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
  /* width: min(1200px, 100%); */
  margin: 0 auto 2em;
  width: calc(var(--box-container-width) - 70px);
  background-size: var(--background-img-scale-cover);
  background-position: top left;
}

.branding-based-section img {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
}

.branding-based-section .branding-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.5em;
  right: 0;
  gap: 1em;
  background-color: #ffffff;
}

.branding-based-section .branding-title {
  flex: 2;
  font-size: var(--category-title-fs);
  text-align: left;
  font-weight: 700;
  color: var(--btn_primary_color);
  margin-bottom: 0;
}

.branding-based-section .branding-body {
  text-align: center;
  line-height: 1.5;
  font-size: 1.4rem;
  color: #000 !important;
  display: none;
}

.branding-based-section .branding-button {
  /* flex: 1; */
  align-self: center;
  border: 1px solid var(--highlight_color);
  color: var(--highlight_color);
  font-size: var(--category-label-fs);
  text-transform: uppercase;
  border-radius: 5px;
  padding: var(--primary-btn-padding);
  /* max-width: 200px; */
  /* font-weight: 600; */
  line-height: normal;
}

/*------ USP Section -------------------------------------------------*/

.usp-section {
  width: var(--box-container-width);
  margin: 0 auto;
}

.usp-section img {
  width: 150px !important;
  aspect-ratio: 1/1;
}

/*------ Testimonial Section -------------------------------------------------*/

.testimonial-section {
  margin-bottom: 0;
}

/*------ Browse By Categories Section -------------------------------------------------*/

.category-card {
  display: flex !important;
  gap: 0.5em;
}

.category-card a {
  padding: 0 !important;
  max-width: 100%;
  width: 100%;
}

.category-card .ec-card-image {
  width: 100%;
  overflow: hidden !important;
}

.category-card .ec-card-details {
  padding: 0.75em 1em;
}

.category-card .ec-card-details .cat_name {
  font-size: var(--category-label-fs);
  font-weight: 700;
  text-wrap: nowrap;
  /* color: #777; */
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-card .ec-card-details p {
  /* color: #777; */
  color: inherit;
  font-size: 1.2rem;
  /* font-weight: 600; */
  text-wrap: nowrap;
}

/*------ Footer Section -------------------------------------------------*/
.footer-main {
  /* border-top: 1px solid #eee; */
  /* border-top: 1px solid var(--menu_separator_color, #eee); */
  margin-top: auto;
}

.footer-top,
.footer-bottom-payment {
  background-color: var(--web_static_link_footer_bg_color);
  color: var(--web_static_link_footer_fg_color);
}

.footer-top .ec-footer-logo img {
  width: auto !important;
  /* width: min(300px, 100%) !important; */
  height: calc(var(--web_company_logo_height) * 1.2);
}

.footer-top .ec-footer-logo.bigger-logo {
  display: flex;
  justify-content: center;
}

.footer-top .ec-footer-logo.bigger-logo img {
  height: calc(var(--web_company_logo_height) * 1.25);
}

.ec-footer .footer-top .ec-footer-widget .ec-footer-heading {
  border-color: #777;
}

.footer-bottom-payment .payment-link {
  padding-top: 14px;
}

.footer-catalogue-section,
.section-space-footer-p .container-fluid {
  width: var(--box-container-width) !important;
  margin: 0 auto;
}

.ec-footer-link.address-section {
  display: flex !important;
  flex-direction: column;
  gap: 0.5em !important;
  color: inherit;
}

.social-footer-links a {
  color: inherit;
}

.ec-footer-contact:not(.horizontal-align-footer-location)
  .ec-footer-link.address-section
  + .ec-footer-link.address-section {
  border-top: 1px solid #777 !important;
  padding-top: 0.75em !important;
  margin-top: 0.75em !important;
}

.ec-footer-link.address-section > div {
  display: flex;
  gap: 1em;
  /* border: 1px solid red; */
}

.ec-footer-link.address-section > div p {
  line-height: normal;
  margin-bottom: 0;
}

.ec-footer-link.address-section .address p {
  line-height: 1.5 !important;
}

.ec-footer-contact:not(.horizontal-align-footer-location) .ec-footer-heading {
  display: none;
}

.footer-top .ec-footer-heading {
  color: inherit !important;
}

.ec-footer-social .list-inline-item .ecicon {
  font-size: 1.8rem;
}

.horizontal-align-footer-location {
  margin-left: auto !important;
}

.horizontal-align-footer-location .ec-footer-links ul {
  display: flex;
  gap: 1em;
  width: 100% !important;
}

.horizontal-align-footer-location .ec-footer-heading {
  margin-top: 1.5em !important;
}

.horizontal-align-footer-location.without-static-pages .ec-footer-heading {
  margin-top: 0 !important;
}

@media only screen and (min-width: 992px) {
  .horizontal-align-footer-location .ec-footer-heading {
    display: block !important;
    margin-bottom: 15px !important;
    border-bottom: 1px solid #777 !important;
  }
}

@media only screen and (max-width: 576px) {
  .footer-top .ec-footer-logo.bigger-logo {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 992px) {
  .horizontal-align-footer-location
    .ec-footer-links
    ul:not(.social-footer-links) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.ec-footer-widget.social-icons {
  margin-top: 2em;
  width: max-content !important;
}

.footer-catalogue-section {
  /* background-color: red; */
  padding: 3em 0;
}

.footer-catalogue-section .footer-cat-title {
  font-weight: 700;
  margin-bottom: 1em;
  color: var(--btn_primary_color);
}

.footer-catalogue-section section {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.footer-payments {
  padding: 0 1em 1em;
}

.footer-link-container {
  margin: 0.5em 0 !important;
}

.footer-bottom .container-fluid {
  width: var(--box-container-width);
  margin: 0 auto;
}

.footer-bottom {
  padding-bottom: 70px;
  text-align: center;
  background-color: var(--web_copyright_footer_bg_color);
  color: var(--web_copyright_footer_fg_color);
  border-top: 1px solid var(--web_separator_color);
}

.footer-bottom .ec-copy {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-bottom a {
  color: inherit;
  opacity: 0.75;
}

.mobile-footer-navigation-bar {
  background-color: var(--tab_bar_bg_color);
}

@media only screen and (min-width: 575px) {
  .footer-bottom {
    padding-bottom: 18px;
    text-align: left;
  }
}

.mobile-footer-navigation-bar .ec-nav-panel-icons {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  color: #777;
}

.mobile-footer-navigation-bar .ec-nav-panel-icons a {
  color: inherit;
}

.mobile-footer-navigation-bar .ec-nav-panel-icons.active {
  color: var(--tab_bar_foreground_color, --btn_primary_color);
}

.mobile-footer-navigation-bar .footer-icons {
  font-size: 20px;
  color: inherit;
}

.mobile-footer-navigation-bar .ec-header-count {
  font-size: 0.85rem !important;
  width: calc(0.85rem * 1.1) !important;
  right: -8 !important;
}

.mobile-footer-navigation-bar .footer-icons-text {
  font-size: 0.95rem;
  color: inherit;
  text-transform: uppercase;
  margin-top: auto;
}

/************************* General Setting **************************************/
.categoryLinks,
.ec-side-cart .ec-menu-inner .ec-menu-content ul li .sub-menu li a {
  color: #444444;
  /* font-weight: 600 !important; */
}

.header-mobile-menu .categoryLinks {
  color: var(--menu_text_color, #444444) !important;
}

.ec-mobile-menu .categoryLinks,
.ec-mobile-menu .dropdown a {
  display: flex !important;
  justify-content: space-between;
}

.ec-mobile-menu .categoryLinks .product-count,
.ec-mobile-menu .dropdown a .product-count,
.header-mobile-menu .edit-btn {
  background-color: var(--btn_primary_color) !important;
  color: var(--btn_primary_foreground_color) !important;
  height: 25px;
  min-width: 25px;
  padding: 0.5em;
  font-size: 1.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  margin-right: 2em;
}

.home-menu .categoryLinks {
  color: var(--web_nav_bar_fg_color, #777777) !important;
  font-weight: 600 !important;
}

.home-menu .categoryLinks.extracted-subcategory-main-menu {
  font-size: 1.6rem;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.home-menu .categoryLinks.extracted-subcategory-sub-menu {
  display: inline-block;
  margin-left: 0.25em;
}

.categoryLinks:hover {
  color: var(--btn_primary_color) !important;
}

.homeCardSection:has(.trending-slick) .home-card-header-sec h2 {
  margin-left: 25px !important;
}

.productBased-main-section:has(.trending-slick) .section-header h2 {
  margin-left: 40px !important;
  /* position: relative;
    top: 7px; */
}

.App .slider-arrow-icons {
  display: none;
}

.homeCardSection:has(.trending-slick) .slider-arrow-icons,
.usp-section:has(.trending-slick) .slider-arrow-icons {
  display: block;
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--btn_primary_color);
  margin-bottom: 0.5em;
  margin-right: 25px;
}

/**************************************************************************************/
/********************************** MEDIA QUERY ***************************************/
/**************************************************************************************/

/* @media only screen and (min-width:600px) {

    

    .branding-based-section{
        display: flex;
        position: relative;
        align-items: center;
        margin: 0 0 2em;
        
    }

    .branding-based-section .branding-details{
        position: absolute;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        height: 100%;
        padding: 1em;
        right: 0;
        gap: 0.5em;
        background-color: rgba(255,255,255,1);
        
        
    }

    
   

    .branding-based-section .branding-title{
        flex: 0;
        text-align: center;
        
        
    }

    .branding-based-section .branding-body{
        display: block;
    }

    .branding-based-section .branding-button{
        flex: 0;
        padding: 0.25em 1em;
        align-self: center;
    }


} */

@media only screen and (min-width: 768px) {
  .productBased-main-section .row > * {
    /* padding: 0 15px !important; */
  }

  .productBased-main-section .slick-arrow.slick-prev {
    left: -15px;
  }

  .productBased-main-section .slick-arrow.slick-next {
    right: -15px;
  }

  .branding-based-section .branding-body {
    font-size: 1.6rem;
  }

  .banner-slick .slick-arrow,
  .categories-slick .slick-arrow,
  .trending-slick .slick-arrow {
    display: block !important;
  }

  .banner-slick.no-arrow .slick-arrow {
    display: none !important;
  }

  .category-card {
    gap: 1em;
  }

  .category-card a {
    width: calc(33.33% - 0.75em);
  }

  .main-header .ec-header-bottons {
    gap: 2em;
  }
}

@media only screen and (min-width: 992px) {
  .category-card a {
    width: calc(33.33% - 1em);
  }

  .footer-link-container {
    margin: 0 !important;
  }

  .branding-based-section .branding-details {
    gap: 1.5em;
  }

  .category-card a {
    width: calc(25% - 0.75em);
  }
}

@media only screen and (min-width: 1170px) {
  .branding-based-section .branding-body {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1440px) {
  /* .branding-based-section .branding-title{
        font-size: 3.5rem;
    }

    .branding-based-section .branding-body{
        font-size: 2.2rem;
    }

    .branding-based-section .branding-button{
        font-size: 2.2rem;
    } */

  .category-card a {
    width: calc(20% - 1em);
  }
}

/* .ec-product-sbtn .ec-product-image .pic-1 {
    height: auto !important;
} */

.modal-dialog-centered {
  width: min(670px, 100%) !important;
}

/************************** Product Based Category ******************************************************/
.productBased-strip-container .single-matrix-product .ec-product-body {
  padding: 0.5em !important;
}

.branding-img-ondummy {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 999;
  /* opacity: 0; */
}

.branding-img-ondummy ~ * {
  opacity: 0;
}

/**************************************************************************************/
/********************************** MEDIA QUERY ***************************************/
/**************************************************************************************/

@media only screen and (min-width: 600px) {
  /* .productBased-strip-container .slick-slider{
        padding: 0 !important;
    } */

  .homeCardSection .slick-track {
    gap: 0.75em;
  }

  .productBased-strip-container .cat-displayImg {
    position: absolute;
    z-index: 1;
    width: var(--category-display-img-width);
    /* min-width: 320px; */
    /* aspect-ratio: 1.64; */
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 0.3rem;
  }

  .productBased-strip-container .cat-displayImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .productBased-strip-container .cards-section {
    width: calc(100% - var(--category-display-img-width));
    margin-left: calc(var(--category-display-img-width) + 5px);
  }

  .cat-displayImg-inside {
    display: none !important;
  }

  .productBased-strip-container .slick-track > div {
    height: 100% !important;
  }
}

.modal {
  padding: 1em;
}

/* .main-header .dropdown .mega-menu{
    width: inherit !important;
} */

/*CSS for newZoomImage feature for react-zoom-pan-pinch*/
.fsv-section .imgLeft {
  position: relative;
}

.fsv-section .imgLeft .tools {
  position: absolute;
  z-index: 1;
  bottom: 0.75em;
  right: 0.75em;
  display: flex;
  gap: 0.5em;
  font-size: 2.5rem;
}

.fsv-section .imgLeft .tools button {
  background-color: var(--btn_primary_color);
  color: var(--btn_primary_foreground_color);
  line-height: normal;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
}

.fsv-section .zoom-img-outer-container {
  background-size: var(--background-img-scale-cover);
  background-position: top left;
}

.fsv-section .zoom-img-outer-container .static-img-container {
  display: flex;
  position: relative;
}

.fsv-section .zoom-img-outer-container .static-img-container .static-zoom-icon {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  background-color: var(--btn_primary_color);
  color: var(--btn_primary_foreground_color);
  font-size: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}

.fsv-section .fsv-thumb-img {
  background-size: var(--background-img-scale-cover);
  background-position: top left;
}

.fsv-section .react-zoom-pan-pinch,
.fsv-section .react-zoom-pan-pinch .react-transform-component {
  width: 100% !important;
  cursor: grab;
}

.fsv-section .react-zoom-pan-pinch .main-zoom-img {
  width: 100%;
  object-fit: contain;
  /* max-height: calc(100vh - 310px); */
  max-height: calc(100vh - 250px);

  /* height: var(--left-thumb-container-height); */
}

@media only screen and (min-width: 576px) {
  .fsv-section .react-zoom-pan-pinch .main-zoom-img {
    min-height: 600px;
  }
}

.animated-category {
  margin-top: -1.5em !important;
}

/*====================== UTILS CLASSES =======================================*/
.no-uppercase {
  text-transform: none !important;
}

/*=========== NEW CATEGORY CARD STYLING (overwite .category-card a) =============================*/
.category-card .no-silck-margin .slick-track {
  margin: 0;
}

.category-card:not(
    .button-img-group-container,
    :has(.minibgimg_nllefttitle_nlleftcount_horizontalscroll)
  ) {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5em !important;
}

@media only screen and (max-width: 768px) {
  .category-card:not(
      .button-img-group-container,
      :has(.minibgimg_nllefttitle_nlleftcount_horizontalscroll)
    ) {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .category-card:not(
      .button-img-group-container,
      :has(.minibgimg_nllefttitle_nlleftcount_horizontalscroll)
    ) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.category-card a {
  width: 100% !important;
}

.categoryModal .category-card a {
  color: inherit;
}

.button-img-group-container.category-card {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5em !important;
}
