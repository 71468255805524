html, html * {
    color-scheme: light only !important;
}

html{
    overscroll-behavior-x: none;
}

.header-top{
    display: none !important;
    background-color: var(--web_nav_bar_bg_color, #ffffff);
}

.header-top:has(.category-header-top-btns){
    display: block !important;
}

.checkout-side-modal.hide-checkout-side-modal{
    opacity: 0;
}

body{
    overflow-x: hidden;
    color-scheme: light only !important;
    background-color: #fff !important;
    
    /*FOR Prevnet Horizontal drag event in iPhone device*/
    overscroll-behavior-x: none;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-y; /* Only vertical scrolling allowed */
}

body > *{
    /* overflow-x: hidden; */
     /*FOR Prevnet Horizontal drag event in iPhone device*/
     overscroll-behavior-x: none;
     -webkit-overflow-scrolling: touch;
     touch-action: pan-y; /* Only vertical scrolling allowed */
}

.App{
    /* overflow: hidden; */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--body_background);
}
.loader {
    width: 100% !important;
}

#text:first-child{
    display: none !important;
}

/***********************************************************************/
/************************** LOGIN PAGE ********************************/
/*********************************************************************/

/* ---------- Login -----------------*/

#LoginContainer{
    margin-bottom: var(--page-margin-bottom-for-footer);
}

.auth-company-logo {
    margin: 0 !important;
    box-sizing: content-box;
    width: 100% !important;


}

.auth-company-logo a {
    width: 100% !important;
    display: block;
}

.auth-company-logo img {
    all: unset;
    padding: 0 1em;
    margin: 2em auto 0;
    /* display: inline-block; */
    width: clamp(220px, 20%, 300px) !important;
}

.login-wrapper {
    /*Reset*/
    border-radius: 0 !important;

    width: min(100%, 700px);
    margin: 0 auto;
}

.login-container {
    /*Reset*/
    border-radius: 5px !important;
    background-color: var(--login_register_bg_color) !important;
    color: var(--login_register_fg_color) !important;
    padding: var(--form-container-padding) !important;
    border-color: var(--menu_separator_color ) !important;
}

/*Login with otp screen only*/
.login-with-pass-btn { 
    margin-top: 0 !important;
}

.ec-login-wrapper .login-container label{
    color: var(--login_register_fg_color) !important;
}

.ec-login-wrapper .login-container input{
    border-radius: 0.3rem !important;
    color: var(--login_register_fg_color) !important;
}

.ec-login-wrapper .login-container input::placeholder{
    color: var(--login_register_fg_color) !important;
}

.ec-login-wrapper .login-container .Login-links button{
    color: var(--login_register_fg_color);
}

.ec-title.title {
    margin-top: 0.68em !important;
    color: var(--highlight_color);
}

.ec-register-wrapper .ec-register-container{
    background-color: var(--login_register_bg_color);
    color: var(--login_register_fg_color);
    border-color: var(--menu_separator_color);
}

.ec-register-wrapper .ec-register-container .ec-register-form input{
    border-radius: 0.3rem !important;
    color: var(--login_register_fg_color);
    border-color: var(--login_register_fg_color);
}

.ec-register-wrapper .ec-register-container .ec-register-form .contryCodeDropDown{
    border-color: var(--login_register_fg_color) !important;
}

.ec-register-wrapper .ec-register-container .ec-register-form input::placeholder{
    color: var(--login_register_fg_color) !important;
}

.version {
    /*Reset*/
    padding: 0 !important;
    margin: 0 !important;

    /*Modify*/
    font-size: var(--smallText-fs) !important;
    margin: 0 1em !important;
}



/* ---------- LOGIN WITH OTP -----------------*/
.otp-inputs>div {
    --opt-digits: 6;

    all: unset !important;

    display: flex !important;
    padding: 1em 0 0.75em !important;
    justify-content: space-between !important;
    gap: 0.5em !important;

}

.otp-inputs>div>input {
    width: calc(100% / var(--opt-digits)) !important;
    min-width: 35px;
    max-width: 65px;
    padding: 0.15em !important;
    aspect-ratio: 1 /1;
    color: var(--login_register_fg_color) !important;
    -webkit-text-fill-color: var(--login_register_fg_color) !important

}

input:-webkit-autofill:focus, input:-webkit-autofill{
    color: var(--login_register_fg_color) !important;
    -webkit-text-fill-color: var(--login_register_fg_color);

}


/*-----------------------------------------------------------------*/
/*----------------- Media Query ----------------------------------*/
/*-----------------------------------------------------------------*/

/*--------- Mobile: 460px and larger --------------------------------------------*/
@media only screen and (min-width:460px) {

    /*LOGIN WITH OTP*/
    .otp-inputs>div {
        gap: 2em !important;
    }

    #Verification .otp-inputs>div{
        gap: 1em !important;
    }
}

/*--------- Mobile: 576px and larger --------------------------------------------*/
@media only screen and (min-width:576px) {}

/*--------- Table: 768px and larger --------------------------------------------*/
@media only screen and (min-width:768px) {
    #Verification .otp-inputs>div{
        gap: 2em !important;
    }
}


/*--------- Laptop: 1024px and larger --------------------------------------------*/
@media only screen and (min-width:1024px) {}


/*--------- Desktop: 1280px and larger --------------------------------------------*/
@media only screen and (min-width:1280px) {}


/*--------- 4K Screen: 2560px and larger --------------------------------------------*/
@media only screen and (min-width:2560px) {}

.flag-dropdown .country-list {
    background: var(--login_register_bg_color);
    color: var(--login_register_fg_color);
}

.flag-dropdown .country-list li.highlight,
.flag-dropdown .country-list li:hover
{
    background: var(--login_register_fg_color);
    color: var(--login_register_bg_color);
}


/*********************************************************************************/
/************************** FORGOT PASSWORD PAGE ********************************/
/*******************************************************************************/
#ForgotContainer{
    margin-bottom: var(--page-margin-bottom-for-footer);
}


.forgot-wrapper {
    /* Veriables */
    --forgot-container-width: min(750px, 100%);
    --forgot-container-padding: 1.5em;

    width: var(--forgot-container-width) !important;

    margin: 0 auto;
}

.forgot-container {
    border-radius: 0 !important;
    padding: var(--forgot-container-padding) !important;
    background-color: var(--login_register_bg_color) !important;
    border-radius: 5px !important;
}

.forgot-container input{
    border-radius: 0.3rem !important;
    border-color: var(--login_register_fg_color) !important;
    color: var(--login_register_fg_color)  !important;
}

.send-otp-btn {
    margin-inline: auto !important;
    margin-bottom: 0 !important;
    width: 200px !important;
}

.verify-otp-btn {
    margin-bottom: 0 !important;
    width: 200px !important;
}

.reset-password-btn {
    width: 250px !important;
    margin-inline: auto !important;
    margin-bottom: 0 !important;
}

.forgot-container .reset-password-inputs:first-child {
    margin-bottom: 1.5em !important;
}

.verify-otp.otp-inputs>div {
    padding: 0 !important;
}

/******************************************************************************/
/************************** REGISTRATION PAGE ********************************/
/****************************************************************************/

#RegContaier{
    margin-bottom: var(--page-margin-bottom-for-footer);
}

.register-wrapper {
    /* width: min(1920px, 100%); */
    margin-bottom: 1em;
}

#RegContaier .register-wrapper textarea{
    border: 1px solid #eeeeee;
    padding: 0.5em;
    font-size: var(--input-value-fs) !important;
}

#RegContaier .register-wrapper .required-field{
    font-size: 1.3rem;
}

.register-wrapper .btn-primary {
    margin: 1em auto 0 !important;
    width: 100% !important;
}

.r-select__menu {
    font-size: var(--input-value-fs) !important;
}

.r-select__menu{
    background-color: var(--login_register_bg_color) !important;
    color: var(--login_register_fg_color) !important;
}

.r-select__input {
    font-size: var(--input-value-fs) !important;
}

.auth-comp .reg-verification-container{
    background-color: var(--login_register_bg_color);
    color: var(--login_register_fg_color);
    box-shadow: none !important;
}

.auth-comp .reg-verification-container button{
    color: var(--login_register_fg_color);
}

.auth-comp .reg-verification-container .h1,
.reg-verification-confirmation-modal .h1,
.reg-verification-confirmation-modal .h4
{
    color: var(--login_register_fg_color);
}



#verificationModal .modal-content{
    width: min(500px, 90%);
    min-height: 200px;
    
}

#verificationModal .modal-content .h1{
    margin: 1em 0 0.5em;
}

#verificationModal .modal-content .CheckIcon *{
    font-size: 7.5rem;
}

.react-tel-input .flag-dropdown{
    background-color: transparent !important;
}

/*-----------------------------------------------------------------*/
/*----------------- Media Query ----------------------------------*/
/*-----------------------------------------------------------------*/

/*--------- Mobile: 576px and larger ---------------------------------------*/
@media only screen and (min-width:576px) {

    .register-wrapper .btn-primary {
        width: min(220px, 100%) !important;
    }


}


/***********************************************************************/
/************************** HEADER ************************************/
/*********************************************************************/
/* Reset */
.mainHeader {

    --logo-size: clamp(150px, 30%, 200px);

    margin: 0 !important;
    padding: 0 !important;
    height: var(--mainHeader-height) !important;
    align-items: center !important;


}

.mainHeader .ec-header-bottom {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
}



.mainHeader .ec-header-bottom div {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
}

.mainHeader .header-logo img {
    width: 100% !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
}

.ec-header-bottom {
    padding: 0.5em 0 !important;
}

.header-buttons {
    margin: 0 !important;
    padding: 0 !important;
}

.header-buttons * {
    margin: 0 !important;
    padding: 0 !important;
}


.header-buttons .ec-header-count {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    bottom: initial !important;
    right: initial !important;
    top: initial !important;
    left: initial !important;
    line-height: normal !important;
}

/* Modify */
.mainHeader {
    padding: var(--mainHeader-padding) !important;
}

.mainHeader .header-logo img {
    width: var(--logo-size) !important;
}

.header-buttons .ec-header-bottons {
    gap: 2.5em
}

.header-buttons .ec-header-count {
    background-color: var(--count-color) !important;
    font-size: var(--count-fs) !important;
    width: calc(var(--count-fs) * 1.8) !important;
    aspect-ratio: 1 / 1 !important;
    border-radius: 999px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    right: -6px !important;
    bottom: -4px !important;
    padding: 0.1em !important;
    min-width: 16px !important;
    font-weight: 600 !important;

}

/*-----------------------------------------------------------------*/
/*----------------- Media Query ----------------------------------*/
/*-----------------------------------------------------------------*/


/*--------- Table: 768px and larger --------------------------------------------*/
@media only screen and (min-width:576px) {
    .ec-header-bottom {
        padding: 0.75em 0 !important;
    }
}


/***********************************************************************/
/************************** MATRIX PAGE *******************************/
/*********************************************************************/
.login-popup-modal-matrix .modal-dialog{
    height: auto;
}

.login-popup-modal-matrix .modal-content{
    background-color: transparent;
    border: none;
}

.login-popup-modal-matrix .modal-body{
    padding: 0;
}


.ec-nav-toolbar{
    z-index: 9 !important;
}

/* Reset */
.filter-section {
    --filter-section-fs: 1.4rem;
    --filter-section-sort-select-width: 170px;
    --filter-section-padding: 0.5em 1em;

    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    
}

/* Reset Sort Filter*/
.filter-section .r-container, .filter-section .r-container *{
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    line-height: normal !important;
    outline: 0 !important;
    
}

.filter-section .r-container *{
    font-size: var(--filter-section-fs) !important;
    text-align: left !important;
}

.filter-section .select-wrapper{
    width: 170px;
}

.filter-section .r-container{
    width: 100% !important;
}


.filter-section .r-container .r-select__value-container{
    padding: 0 0.5em!important;
}

.filter-section .r-container .r-select__option{
    padding: 0.5em!important;
}

.filter-section .r-container .r-select__dropdown-indicator{
    padding: 0 0.5em !important;
    
}

.filter-section .r-container .r-select__control, .filter-section .r-container .r-select__control--menu-is-open{
    border-color: #eeeeee !important;
    box-shadow: none !important;
    border-radius: 0.3rem;
}




.filter-section * {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    top: initial !important;
    left: initial !important;
    bottom: initial !important;
    right: initial !important;
}

.filter-section .ec-pro-list-top {
    background: transparent !important;
    border: none !important;
}

.filter-sidebar-section .ec-sb-block-content {
    border-bottom: none !important;
}


.filter-sidebar-section .es-price-slider label.filter__label::before{
    content: "" !important;
    margin: 0 !important;
}

.filter-sidebar-section .es-price-slider label.filter__label span{
    margin-inline: 4px !important;
}

.filter-sidebar-section .ec-sidebar-block .ec-sb-title h3 {
    border: none !important;
    color: var(--filter_content_fg_color, #555555);
}

.matrix-products-section {
    margin: 0 !important;
    padding: 0.75em 0.5em !important;
}



.matrix-products-section * {
    margin: 0 !important;
    padding: 0 !important;
}

.matrix-products-section .matrix-card-container{
    flex: 1 !important;
}

.myc_catelogue_title{
    padding: 0.65em 0.65em !important;
    text-align: center;
}

.myc_catelogue_title h2{
    
    font-size: 2rem;
    display: inline-block;
    font-weight: 600;
    /* color: #777; */
    /* color: var(--button_background_color); */
    /* color: var(--matrix_title_bar_bg_color); */
    color: var(--highlight_color);
    /* --btn_primary_color */
    position: relative;
}

.myc_catelogue_title h2::before{
    position: absolute;
    content: "";
    width: 100%;
    bottom: -0.15em;
    height: 0.15em;
    border-radius: 999px;
    background-color: var(--btn_primary_color);
    /* --button_background_color */
}

/* Modify */
.filter-section {
    padding: var(--filter-section-padding) !important;
    background-color: var(--matrix_toolbar_bg_color, #f7f7f7);
    border-bottom: 1px solid var(--menu_separator_color, #eeeeee) !important;
    font-size: var(--filter-section-fs) !important;
    top: 0;
}

.filter-section .ec-pro-list-top {
    align-items: stretch !important;

}

.filter-section .filter-by {
    font-size: var(--filter-section-fs) !important;
    padding: 0.5em 0.65em !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    gap: 0.15em !important;
    height: 100% !important;
    background-color: transparent;
    color: var(--menu_text_color);
    border-color: var(--menu_text_color);
    border-radius: 0.3rem;
}

.filter-section .filter-by i {
    display: block !important;
    color: var(--menu_text_color);
}

.filter-section .sort-by {
    font-size: var(--filter-section-fs) !important;
    display: none !important;
    color: var(--matrix_toolbar_fg_color);
}

.filter-section .ec-sort-select {
    gap: 0.35em !important;
}

.filter-section .ec-select {
    font-size: var(--filter-section-fs) !important;
    width: var(--filter-section-sort-select-width) !important;
    padding: 0.5em !important;
}

.filter-sidebar-section {
    width: min(300px, 100%) !important;
    color: #777 !important;
    background: #f7f7f7 !important;

}

.static-filter-sidebar{
    z-index: 0;
    height: 100% !important;
    margin-top: 0.65em !important;
}

.static-filter-sidebar .ec-sidebar-wrap{
    /* height: calc(100% - 70px) !important; */
    overflow: auto;
    scrollbar-width: none;
    background-color: var(--filter_content_bg_color);
}

.static-filter-sidebar .ec-sidebar-wrap::-webkit-scrollbar{
    scrollbar-width: none;
}



.filter-sidebar-section .ec-sidebar-heading {
    padding: 0.95em !important;
    display: flex !important;
    /* border-bottom: 1px solid #eee !important; */
    align-items: center !important;
    margin-left: auto !important;
    justify-content: space-between !important;
    width: 100% !important;
    background-color: var(--filter_option_bg_color, transparent);
    color: var(--filter_option_title_fg_color);
}

.filter-sidebar-section .ec-sidebar-heading h1 {
    color: inherit !important;
    border: none !important;
    background: transparent !important;
    font-size: 1.6rem !important;
    width: 100% !important;


}

.filter-sidebar-section .ec-sidebar-heading .filter-cls-btn {
    position: absolute !important;
    line-height: normal !important;
    margin-right: 0.25em !important;
    top: 2px !important;
}

.filter-sidebar-section:not(.static-filter-sidebar) .ec-sidebar-wrap {
    padding: 1em !important;
    padding-bottom: 160px !important;
}

.filter-sidebar-section .ec-sidebar-block {
    /* border: 2px solid red !important; */
    display: flex !important;
    flex-direction: column !important;
}

.filter-sidebar-section .ec-sidebar-block .ec-sb-block-content {
    padding-top: var(--filter-section-sidebar-spacing) !important;
    padding-bottom: var(--filter-section-sidebar-spacing) !important;
    padding-inline: var(--filter-sidebar-section-inline-padding) !important;
}



.filter-sidebar-section .ec-sidebar-block .ec-sb-block-content ul > .range-wrapper,
.filter-sidebar-section .ec-sidebar-block .ec-sb-block-content ul > .buttons-wrapper
{
    width: 100%;
    
}

.filter-sidebar-section .buttons-wrapper + .buttons-wrapper{
    margin-top: 0.75em !important;
}

.filter-sidebar-section .buttons-wrapper .btn-container{
    display: flex;
    align-items: center;
    gap: 0.75em
}

.filter-sidebar-section .buttons-wrapper input{
    width: 20px;
    height: 20px;
    accent-color: var(--btn_primary_color);
    box-shadow: inset 0px 0px 0px 2px var(--btn_primary_color);
}

.filter-sidebar-section .show-option-btn{
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
    font-weight: 600;
    color: var(--highlight_color);
    margin-top: 0.5em !important;
}

.filter-sidebar-section .range-wrapper + .show-option-btn{
    display: none;
}



.filter-sidebar-section .buttons-wrapper .filter-btn-label{
    font-size: 1.55rem;
    color: var(--filter_content_fg_color, #777777);
}   


.filter-sidebar-section .ec-sidebar-block .ec-sb-title {
    border-bottom: var(--filter-section-sidebar-title-border) !important;
    border-top: var(--filter-section-sidebar-title-border) !important;
    padding-top: var(--filter-section-sidebar-title-padding) !important;
    padding-bottom: var(--filter-section-sidebar-title-padding) !important;
    font-size: var(--filter-section-sidebar-title-fs) !important;
    padding-inline: var(--filter-sidebar-section-inline-padding) !important;
    border-color: var(--filter_content_hairline_color, #eeeeee) !important;

}

.filter-sidebar-section .es-price-slider {
    padding: 1em 1.5em !important;
    background-color: var(--filter_option_bg_color, --filter-section-sidebar-slider-bgColor) !important;
    border: var(--filter-section-sidebar-slider-border) !important;
    border-color: var(--filter_option_bg_color) !important;

}

.filter-sidebar-section .es-price-slider label{
    color: var(--filter_content_fg_color);
}

.filter-sidebar-section .ec-price-filter {
    gap: calc(var(--filter-section-sidebar-spacing) * 0.75);
}

.filter-sidebar-section .ec-price-input {
    gap: 0.5em;
}

.filter-sidebar-section .filter__input {
    max-width: 55px !important;
    font-size: var(--filter-section-sidebar-input-fs) !important;
    border-radius: 0.3rem;
    background-color: transparent;
    color: var(--filter_content_fg_color);
    border-color:  var(--filter_content_fg_color);
}

.filter-sidebar-section #btn-span, .filter-sidebar-section .btn-span{
    background-color: var(--button_unselected_bg_color);
    /* color: var(--button_unselected_fg_color); */
    color: #777;
    border: 1px solid transparent;
    
}



.filter-sidebar-section .filter-btn-input:checked ~ .filter-btn-label .filter-sidebar-btn.ec-opt-sz{
    border: 1px solid transparent !important;
}


.filter-sidebar-section #btn-span.selected, .filter-sidebar-section .btn-span.selected{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    border-color: var(--btn_primary_color);
}

.filter-sidebar-section #btn-span.selected button, .filter-sidebar-section .btn-span.selected button{
    color: var(--btn_primary_foreground_color);
    border-color: var(--button_unselected_border_color);
}


.filter-sidebar-section #btn-span>*, .filter-sidebar-section .btn-span>* {
    padding: var(--filter-section-sidebar-btn-padding) !important;
    font-size: var(--filter-section-sidebar-btn-fs) !important;
}

.filter-sidebar-section .rc-slider-horizontal {
    display: flex !important;
    align-items: center;

}

.filter-sidebar-section #apply-btn-contaiiner {
    flex-direction: row !important;
    justify-content: center !important;
    padding-block: 1em !important;
    padding-inline: var(--filter-sidebar-section-inline-padding) !important;
    gap: 1em !important;
    z-index: 2;
    background-color: var(--filter_content_bg_color) !important;
}

.filter-sidebar-section #apply-btn-contaiiner button {
    font-size: 1.6rem !important;
    padding: 0.65em !important;
    flex: 1;
}

.filter-sidebar-section .btn_secondary_foreground_color{
    background-color: transparent !important;
    color: var(--btn_secondary_foreground_color) !important;
}


/* .matrix-products-section {
    --top-offset: calc(53px + 0.5em);
    margin-top: var(--top-offset) !important;
} */

.matrix-products {
    padding: 0.65em 0.65em !important;
}

.single-matrix-product {
    border-radius: 0.3rem;
    overflow: hidden;
    box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.10);
    background-color: var(--matrix_card_bg_color, #fff);
    justify-content: space-between;
}

.single-matrix-product .cart-top-container{
    gap: 0.5em;
}

.single-matrix-product .cart-top-container .card-top-label, .fsv-section .card-top-label{
    padding: 0.25em 0.5em !important;
    line-height: normal;
    border-radius: 0.3rem;
    margin-right: auto !important;
    font-size: var(--card-top-label-fs);
    text-transform: uppercase;
}

.single-matrix-product .cart-top-container .cartIcon{
    flex-shrink: 0;
}

.single-matrix-product .product-name {
    font-size: var(--matrix-single-product-title-fs) !important;
}

.single-matrix-product .ec-add-to-cart {
    border-radius: 0.3rem;
}

.single-matrix-product .ec-product-image, .category-home-card {
    background-position: top right;
    background-size: var(--background-img-scale-cover);
    /* aspect-ratio: 1 / 1; */
    overflow: hidden;
    /* height: 250px !important; */
}

.single-matrix-product .ec-product-image a{
    display: block;
    height: 100%;
    overflow: hidden;
}

.single-matrix-product .ec-pro-actions {
    padding: 0.5rem !important;
}

.single-matrix-product .ec-product-image .pic-1 {
    width: 100% !important;
}

.single-matrix-product .ec-pro-actions img {
    padding: 0 !important;
    margin: 0 !important;
}

.single-matrix-product .whishlist-circle {
    background-color: #fff !important;
    cursor: pointer;
}

.single-matrix-product .whishlist-circle i {
    line-height: normal !important;
}

.single-matrix-product .cartIcon {
    background-color: #fff !important;
    border-radius: 50%;
}


.single-matrix-product .ec-product-body {
    display: flex !important;
    flex-direction: column;
    gap: 0em;
    padding: 0.6em !important;
    font-size: var(--matrix-single-product-fs) !important;
    background-color: var(--matrix_card_bg_color, #ffffff);
    margin-bottom: auto !important;
    margin-top: auto !important;
}

.single-matrix-product .custom-price-style{
    font-weight: 700;
    font-size: 1.7rem;
    position: relative;
}



.single-matrix-product .add-to-cart-button,
.single-matrix-product .update-button {
    height: auto !important;
    line-height: normal !important;
    font-size: var(--matrix-single-product-button-fs) !important;
    padding: var(--matrix-single-product-button-padding) !important;
}

.single-matrix-product .atc-matrix-edit-btn{
    color: var(--button_background_color) !important;
    border: 1px solid var(--button_boarder_color);
    padding-inline: 0.5em !important;
    border-radius: 0.3rem;
    font-size: calc(var(--matrix-single-product-button-fs) * 1.8) !important;
    display: flex;
    line-height: normal;
    align-items: center;
    justify-content: center;

}


/*-----------------------------------------------------------------*/
/*----------------- Media Query ----------------------------------*/
/*-----------------------------------------------------------------*/


/*--------- Table: 768px and larger --------------------------------------------*/
@media only screen and (min-width:475px) {
    .myc_catelogue_title h2{
        font-size: 2.5rem;
    }
}

@media only screen and (min-width:768px) {
    .filter-section {
        --filter-section-fs: 1.4rem;
        --filter-section-sort-select-width: 195px;
        
    }

    .filter-section .select-wrapper{
        width: 215px;
    }

    .single-matrix-product .cart-top-container{
        gap: 1em;
    }
    
    .myc_catelogue_title h2{
        font-size: 2.8rem;
    }
}

@media only screen and (min-width:1200px) {
    .filter-section .sort-by {
        display: block !important;
    }
}


/*********************************************************************************/
/******************************** FSV PAGE **************************************/
/*******************************************************************************/

.fsv-header {
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
    padding: var(--fsv-header-padding) !important;
}

.fsv-header .ec-breadcrumb-title {
    margin: 0 !important;
    padding: 0 !important;
    font-size: var(--fsv-header-title-fs) !important;
}


.fsv-header .ec-breadcrumb-list>* {
    font-size: var(--fsv-header-nav-fs) !important;
}


.fsv-container {
    display: flex !important;
    flex-direction: column !important;
    min-height: calc(var(--device-pixel-ratio-height) - var(--mainHeader-height)) !important;
}

.products-page.fsv-container{
    background-color: var(--fullscreen_bg_color);
}

.fsv-container .next-prev-buttons-container{
    display: flex;
    justify-content: space-between;
    padding: 1em;
    position: absolute;
    width: 100%;
    pointer-events: none;
    z-index: 7;
    
    
}

.fsv-container .next-prev-buttons-container img{
    pointer-events: auto;
    cursor: pointer;
    height: 35px;
}


/*Reset main FSV section*/
    .fsv-section,
.fsv-section *:not(.single-nav-thumb *){
    margin: 0 !important;
    padding: 0 !important;
    /* line-height: normal !important; */
}

.fsv-section .card-top-label{
    padding: 0.25em 0.5em !important;
}



.fsv-section .single-pro-content .ec-pro-variation{
    padding-bottom: 1em !important;
}

/*Remove all FSV input (Remove empty div)*/
.fsv-section .single-pro-content .ec-pro-variation > div{
    /* background-color: red;
    min-height: 50px; */
    display: none;
}
/*Show only input which has product-details-label (Remove empty div)*/
.fsv-section .single-pro-content .ec-pro-variation > div:has(.product-details-label){
    display: block;
}

.fsv-section .img-container{
    position: relative;
}

.fsv-section .card-top-label{
    position: absolute;
    z-index: 1;
    top: 1em;
    left: 1em;
    font-size: calc(var(--card-top-label-fs) * 1.1);
}

.fsv-section .fsv-stepper-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    flex-wrap: wrap;
}

.fsv-section .fsv-default-image-container{
    height: 565px;
    background-size: var(--background-img-scale-cover);
    background-position: top left;
}

@media only screen and (max-width: 1024px) {
    .fsv-section .fsv-default-image-container{
        height: 400px;
    }
}

.fsv-section .fsv-default-image-container img{
    height: 100% !important;
    object-fit: contain;
}

.fsv-section .fsv-stepper-container .fsv-available-qty{
    color: var(--btn_primary_foreground_color);
    padding: 0.25em 0.75em !important;
    background-color: var(--btn_primary_color);
    border-radius: 999px;
}

.fsv-section .fsv-stepper-container .disabled{
    opacity: 0.35;
    cursor: default;
}

.fsv-section .fsv-stepper-container .inc.ec_qtybtn,
.fsv-section .fsv-stepper-container .dec.ec_qtybtn
{
    border: none;
}

.fsv-section .fsv-stepper-container .qty-input{
    height: 100%;
    border-inline: 1px solid var(--fullscreen_value_color);
}

.fsv-footer {
    margin: 0 !important;
    padding: 0 !important;

    margin-top: auto !important;
    padding: 1.5em 1em 1.75em!important;
    background-color: var(--fullscreen_tab_color) !important;
    /* box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.10); */
    border-top: 1px solid var(--menu_separator_color, #eee);
    bottom: 58px !important;
    gap: 0.5em;
    z-index: 2;
}

.fsv-footer * {
    margin: 0 !important;

}


.fsv-footer > div{
    flex: 1;
}

.fsv-footer > div *{
    width: 100% !important;
    height: 100% !important;
}

.fsv-footer .btn-primary,
.fsv-footer .btn-secondary {
    font-size: var(--fsv-footer-btn-fs) !important;

}

.fsv-footer .btn-secondary {
    font-size: var(--fsv-footer-btn-fs) !important;
    background-color: transparent !important;
}

.fsv-footer .update-cart-btn{
    display: flex;
    /* padding: 0.5em 1em !important; */
    width: min(500px, 100%);
    min-height: 48.5px;
    justify-content: center;
    align-items: center;
}

.fsv-footer .button_background_color{
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color) !important;
}

.fsv-footer .cart-footer-btn button{
    padding: var(--cart-footer-btn-padding) !important;
    font-size: var(--cart-footer-btn-fs) !important;
}

.fsv-section {
    margin: 2em auto 0 !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 1.5em !important;
    margin-bottom: 50px !important;

}

.fsv-section .fsv-col {
    /* gap: 1.5em !important; */
    gap: 1.4em !important;
}

.fsv-section .fsv-col > div{
    align-self: flex-start !important;
    
}

.fsv-section .single-nav-thumb>button {
    margin: 0 auto !important;
}

.fsv-section .heading{
    background-color: var(--fullscreen_tab_color ,--fsv_bottom_toolbar_bg_color);
    color: var(--fullscreen_title_color, --highlight_color);
    padding: var(--fsv-prod-details-title-padding) !important;
    border-block: 1px solid var(--fullscreen_tab_color , #eeeeee);
    padding-left: 1em !important;
}

.fsv-section .ec-single-title {
    font-size: var(--fsv-prod-details-title-fs) !important;
    font-weight: 600;
    color: inherit;
    text-align: left;
   
}

.fsv-section .product-details-label{
    font-size: var(--fsv-prod-details-label-fs) !important;
    /* margin-top: 12.8px !important; */
    color: var(--fullscreen_label_color);
}

.fsv-section .qty-input,
.fsv-section .dec.ec_qtybtn,
.fsv-section .inc.ec_qtybtn{
    color: var(--fullscreen_value_color)
}

.fsv-section .fsv-custom-varient-details.mb{
    margin-bottom: 1em !important;
}

.fsv-section .fsv-custom-varient-details .product-details-label{
    margin-top: 0 !important;
}

.fsv-section .product-details-value {
    font-size: var(--fsv-prod-details-value-fs) !important;
    color: var(--fullscreen_value_color)
}

.fsv-section .material-details .product-details-value.text-left{
    width: fit-content;
    max-width: none;
}

.fsv-section .material-details .product-details-value.text-left .diamond-details{
    margin-left: 5px !important;
    font-size: 1.2rem;
    text-wrap: nowrap;
}


.fsv-section .material-details .product-details-value.text-left ~ td {
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fsv-section .material-details .product-details-value.text-left ~ td:last-of-type{
    justify-content: flex-end;
}

.fsv-section .product-details-btn-value {
    font-size: var(--fsv-prod-details-value-fs) !important;
    display: flex !important;
    gap: calc(var(--fsv-prod-details-gap) * 0.8)!important;
}

.fsv-section .FSV-fieldValue{
    margin: 0 !important;
    padding: 0 !important;
    font-size: var(--fsv-prod-details-value-fs) !important;
    padding: var(--fsv-prod-details-input-padding) !important;
}

.fsv-section .form-select:not(:disabled){
    border: 1px solid #eee !important;
}

.fsv-section .product-details-btn-value span, .fsv-section .product-details-btn-value .btn-span {
    /* padding: var(--fsv-prod-details-btn-padding) !important; */
    padding: 1em !important;
    min-width: 45px;
}

.fsv-section .product-details-btn-value input:checked ~ .btn-span{
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color) !important;
}

/* .fsv-section .product-details-inner-group-sm>* {
    margin-block: var(--fsv-prod-details-group-sm-gap) !important;
} */

.fsv-section .fsv-qty-stepper{
    margin-bottom: calc(var(--fsv-prod-details-gap) * 1.5) !important;
}

/* .fsv-section .remarks-group-sm{
    margin-bottom: calc(var(--fsv-prod-details-gap) * 1.5) !important;
} */

/* Reset Sort Filter*/
.fsv-section .r-container, .fsv-section .r-container *{
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    line-height: normal !important;
    outline: 0 !important;
    
}

.fsv-section .r-container *{
    font-size: var(--fsv-prod-details-value-fs) !important;
    text-align: left !important;
}

.fsv-section .r-container{
    border: none;
}

.fsv-section .buttonflow input:checked ~ .buttonflow-btn{
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color);
}

.fsv-section .buttonflow-btn *{
    background-color: transparent !important;
}

.fsv-section .buttonflow-btn{
    position: static;
    background-color: transparent !important;
    border: 1px solid #777;
}

.fsv-section .buttonflow-btn{
   min-width: 45px;
   padding: 0.75em !important;
   display: block !important;
   border-radius: 0.3rem !important;
   outline-color: var(--btn_primary_color) !important;
   cursor: pointer;
   border-color: var(--btn_primary_color);
   color: var(--btn_primary_color);
   
}

.fsv-section .buttonflow ul{
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--fsv-prod-details-gap) * 0.8)!important;
}

.fsv-section .buttonflow.no-flow ul{
    flex-wrap: nowrap;
    overflow: auto;
}

.fsv-section .buttonflow li{
    background-color: transparent !important;
    border: none !important;
    height: auto !important;
}

.fsv-section .custom-fsv-input{
    padding: 0.5em !important;
    border-radius: 0.3rem;
    width: 100px;
    border: 1px solid var(--btn_primary_color);
    text-align: center;
    font-size: 1.2rem;
}

.fsv-section .custom-fsv-input::placeholder{
    font-size: inherit;
    text-align: center;
}

.fsv-section .buttonflow .buttonflow-btn-inner{
    top: 0 !important;
    color: inherit !important;
}

/* .fsv-section .fsv-custom-varient-details{
    margin-bottom: 2em !important;
} */

.fsv-section .fsv-custom-varient-details .container .row + .row{
    margin-top: 1em !important;
}

.fsv-section .fsv-custom-varient-details .varient-details-value{
    text-align: left;
    color: var(--fullscreen_value_color);
}


.fsv-section .buttonflow-btn #btn-span, .fsv-section .buttonflow-btn .btn-span{
    position: relative;
    top: -10px;
}






.fsv-section .r-container .r-select__value-container{
    padding: 0 0.5em!important;
}

.fsv-section .r-container .r-select__option{
    padding: 0.5em!important;
}

.fsv-section .r-container .r-select__dropdown-indicator{
    padding: 0 0.5em !important;
    
}

.fsv-section .r-container .r-select__control, .fsv-section .r-container .r-select__control--menu-is-open{
    border-color: #eeeeee !important;
    box-shadow: none !important;
}


/* .fsv-section .remarks-group-sm>* {
    margin-block: var(--fsv-prod-details-group-sm-gap) !important;
} */

.fsv-section .remarks-input {
    padding: var(--fsv-prod-details-input-padding) !important;
    font-size: var(--fsv-prod-details-value-fs) !important;
    height: 100%;
    border: 1px solid var(--fullscreen_value_color, #eeeeee);
    outline: none;
    color: var(--fullscreen_value_color);
    background-color: transparent;
    border-radius: 0.3rem;
}

.fsv-section .remarks-input:focus {
    border-color: #eeeeee;
}

.fsv-section .product-details-bottom-group {
    margin-block: var(--fsv-prod-details-bottom-group-gap) !important;
}

.fsv-section .product-other-details-bottom-group {
    margin-block: var(--fsv-prod-other-details-bottom-group-gap) !important;
}

.fsv-section .product-other-details-bottom-group .ml-auto{
    margin-left: auto !important;
}

.fsv-section .total-cost {
   font-size: var(--fsv-prod-details-total-fs) !important;
   font-weight: 700 !important;
   border-top: 1px solid var(--fullscreen_label_color, #eee);
   border-bottom: 1px solid var(--fullscreen_label_color, #eee);
   padding-block: 0.5em !important;
   align-items: center;
}

.fsv-section .material-details .heading{
    margin-bottom: 8px !important;
}

.fsv-section .total-cost .total-amt {
    margin-left: auto !important;
}



.fsv-section .nav-thumb-bottom {
    margin-top: 0.5em !important;
}
/* .fsv-section .nav-thumb-bottom .single-slide {
    margin-inline: var(--fsv-bottom-img-slider-gap) !important;
} */

.fsv-section .nav-thumb-bottom .slick-arrow {
    top: 50% !important;
    transform: translateY(-50%);
}

.fsv-section .single-product-scroll {
    width: 100% !important;
}

.fsv-section .single-product-cover {
    max-width: 100% !important;
}


.fsv-section .loaderImg img{
    margin: 0 auto !important;
}

/* .fsv-section .single-nav-thumb .nav-slider-thumb-bottom {
    
    width: 80px !important;
} */


.fsv-prod-details-section .product-details-inner-group-sm > div:first-child{
    flex: 1;
    text-align: left;
    align-self: flex-start !important;
    /* margin-top: 5px !important; */
    
}

.fsv-prod-details-section .product-details-inner-group-sm > div:last-child{
    flex: 2;
}

.fsv-prod-details-section .select-subproduct{
    margin-bottom: var(--fsv-prod-details-gap) !important;
}

.fsv-prod-details-section .select-subproduct-checkbox{
    display: flex;
    flex-direction: column;
    gap: 0.85em;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection{
    display: flex;
    text-align: left;
    align-items: center;
    gap: 0.65em;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection label{
    cursor: pointer;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection input[type="checkbox"]{
    width: auto;
    height: auto;
    display: none;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection img{
    
    width: 20px;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection svg{
    font-size:2.3rem;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection label .checkedImg{
    display: none;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection svg.checkedImg{
    color: var(--highlight_color);
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection svg.canceledImg{
    color: crimson;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection input[type="checkbox"]:checked ~ label .checkedImg{
    display: block;
}

.fsv-prod-details-section .select-subproduct-checkbox .checkBoxSection input[type="checkbox"]:checked ~ label .canceledImg{
    display: none;
}


.fsv-prod-details-section .select-subproduct-checkbox label{
    line-height: normal;
    text-transform: uppercase;
}

.fsv-prod-details-section .ProductNavTab{
    display: flex;
    padding: 0 !important;
    margin-bottom: var(--fsv-prod-details-gap) !important;
    overflow: auto;
}

.fsv-prod-details-section .ProductNavTab .tab{
    padding: var(--fsv-prod-details-title-padding) !important;
    border-bottom: 3px solid transparent;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
}

.fsv-prod-details-section .ProductNavTab .tab.active{
    
    border-color: var(--highlight_color);
}

.single-pro-content .ec-pro-variation .ec-pro-variation-inner .ec-pro-variation-content li{
    transition: none;
}

.fsv-prod-details-section .ec-pro-variation {
    display: flex;
    flex-direction: column;
    gap: calc(var(--fsv-prod-details-gap) * 1.5);
}

.fsv-prod-details-section  .product-details-inner-group-sm #btnView{
    flex-wrap: wrap;
}






/*-----------------------------------------------------------------*/
/*----------------- Media Query ----------------------------------*/
/*-----------------------------------------------------------------*/


/*--------- Table: 768px and larger --------------------------------------------*/


@media only screen and (min-width:576px) {
   

    .fsv-footer{
        bottom: 0 !important;
    }

    .fsv-section {
        margin-bottom: 0px !important;
    }

    

    /* .fsv-footer {
        box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.10);
    } */

    .fsv-section .zoomedImage-Container{
        transition: none;
    }

    /*Image Container for zoomed*/
    .fsv-section .zoomedImage-Container:hover > img{
        transition: none;
        /* opacity: 0 !important; */
        cursor: default;
    }

   
    
  

    .fsv-section .zoomedImage-Container > img + div{
        width: 100% !important;
        height: 100% !important;
        background-color: #fff;
    }

    /* .fsv-section .left-thumb-container div:last-child{
        margin-left: auto !important;
    } */
    
}

@media only screen and (min-width:768px) {
    .fsv-footer{
        justify-content: space-evenly;
        gap: 2em;
    }

    .fsv-footer > div{
        flex: none;
        width: min(400px, 30%);
    }
    
    .fsv-footer > div *{
        width: 100% !important;
        height: 100% !important;
    }
}





@media only screen and (min-width:1200px) {
    .fsv-section .zoomedImage-Container > img{
        min-height: 500px !important;
    }
}


@media only screen and (max-width: 576px){
    .fsv-section .single-product-cover .slick-track div {
        min-height: 430px;
    }
    
}

/***********************************************************************/
/************************** CART PAGE ********************************/
/*********************************************************************/
.cart-page-container{
    background-color: var(--cart_bg_color);
}

/* Reset */
.cart-container{
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
}

.cart-container * {
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
}

/* Modify */
/* .cart-container .group-by{
    top: 55px !important
} */

.group-by *{
    margin: 0 !important;
}

.group-by{
    border-bottom: 1px solid var(--menu_separator_color, #eee);
}

.group-by .container-fluid{
    width: var(--box-container-width);
    margin: 0 auto !important;
}

 .group-by-inner{
    padding: var(--cart-group-by-padding) !important;
    justify-content: center !important;
}

.group-by-label{
    font-size: var(--cart-group-by-fs) !important;
}

.group-by-btns-container{
    gap: var(--cart-group-by-btn-gap);
}

.group-by-btns-container .group-by-buttons, .removeAllProductSection .remove-all-prod-btn{
    font-size: var(--cart-group-by-btn-fs) !important;
    padding: 1em !important;
}

.group-by-btns-container .group-by-buttons.group-by-buttons-unselected{
    background-color: transparent !important;
}

.removeAllProductSection{
    margin-top: 1em !important;
}

.removeAllProductSection .inner-section{
    width: var(--box-container-width);
    margin: 0 auto !important;
    padding: var(--cart-group-by-padding) !important;
    display: flex;
    justify-content: flex-end;

}

.removeAllProductSection .remove-all-prod-btn{
    line-height: 0 !important;
    display: flex;
    align-items: center;
    gap: 0.25em;
    padding: calc(var(--cart-footer-btn-padding) * 0.85) !important;
    font-size: calc(var(--cart-footer-btn-fs) * 0.95) !important;
    border: 1px solid var(--btn_primary_color) !important;
    background-color: transparent !important;
    color: var(--btn_primary_color) !important;
    border-radius: var(--btn-border-radius) !important;
    text-transform: uppercase;
    font-weight: 600;
    /* height: 50px !important; */
}

.removeAllProductSection .remove-all-prod-btn .del-icon{
    font-size: 1.8rem;
}

.cart-container .cart-main{
    margin-top: 20px !important;
    padding: 1em !important;
    width: var(--box-container-width);
    margin-inline: auto !important;
    min-height: 60vh;
}

.cart-main .product-section{
    position: relative;
    padding-top: 1em !important;
    border: 1px solid var(--menu_separator_color, #eee);
    border-radius: 5px;
    padding: 1em !important;
    width: 100%;
    box-shadow: 0 0 20px -10px rgba(63, 63, 63, 0.3);
    /* background-color: var(--cart_summary_section_bg_color); */
}

.cart-main .empty-cart{
    display: none;
}

.cart-main .empty-cart section{
    border-radius: 0.3rem;
    height: 100%;
    /* background-color: red; */
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    border: 2px dashed #777;
}

.cart-main .empty-cart section img{
    width: min(200px, 40%);
}

.cart-main .empty-cart section button{
    background-color: var(--button_background_color);
    display: block;
    font-size: var(--cart-product-details-fs);
    text-transform: uppercase;
    border-radius: 0.3rem;
    color: var(--button_foreground_color);
    padding: var(--cart-product-btn-padding) !important;
}

.product-section .cart-product-details .half-width {
    width: auto;
    flex: 0 1 calc(50% - (var(--cart-product-details-gap) / 2));
}

.cart-product-details .row > .half-width .label{
    text-wrap: nowrap;
}

@media only screen and (max-width: 576px){
    

    .cart-container .cart-accordation-body .productDetail{
        flex: 2;
    }
}

.cart-container .cart-inner-body{
    margin-bottom: var(--cart-accordian-bottom-margin) !important;
}

.cart-container .prod-accordian-header{
    padding: 0.5em !important;
    font-size: var(--cart-accordian-header-title) !important;
}

.cart-container .prod-accordian-header .right-side{
    gap:0.5em;
}

.cart-container .cart-accordation-body{
    
    padding: 1em !important;
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    background-color: var(--cart_cell_bg_color);
}

.cart-container .cart-accordation-body .del-btn{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.8rem !important;
    width: fit-content;
    margin-left: auto;
    z-index: 0;
}

.cart-container .cart-accordation-body .del-btn:hover svg{
    color: var(--count-color) !important;
}

.cart-container .cart-accordation-body .product{
    /* padding-bottom: 1em !important; */
    gap: 1em !important;
}

.cart-container .cart-accordation-body .product .imgDiv{
    background-position: top right;
    background-size: var(--background-img-scale-cover);
    position: relative;
    cursor: pointer;
}

.cart-container .cart-accordation-body .product .imgDiv > section{
    width: 100%;
}


.cart-container .cart-accordation-body .product .imgDiv img{
    margin: 0 auto !important;
    align-self: center;
}

.cart-container .cart-accordation-body .product .imgDiv .cart-zoom-image-btn{
    position: absolute;
    padding: 0.25em !important;
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    font-size: 2rem;
    border-radius: 0.3rem;
    bottom: 1em;
    right: 1em;
}

.cart-container .cart-accordation-body .productDetail{
    display: flex !important;
    flex-direction: column;
}

/* .productDetail .text-start{
    font-weight: 700;
} */

.cart-container .productDetail, .cart-container .productDetail .other-cart-details, .cart-container .productDetail .qty{
    gap: var(--cart-product-details-gap) !important;
    font-size: var(--cart-product-details-fs) !important;
}

.cart-container .productDetail * {
    font-size: inherit !important;
    color: var(--cart_cell_fg_color);
}

.cart-container .productDetail .label{
    font-weight: 400;
}

.cart-container .productDetail .remarks.label{
    margin-bottom: 0.5em !important;
    align-self: flex-start;
}

.cart-container .productDetail .remarks textarea{
    padding: 0.4em !important;
    color: var(--cart_cell_fg_color);
    background-color: var(--cart_cell_bg_color);
}

.cart-container .productDetail .remarks .data{
    flex-direction: column;
}

.cart-container .productDetail .remarks .update-btn{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    margin-left: auto !important;
    margin-top: 0.5em !important;
    display: block;
    padding: 0.5em !important;
    font-size: 1.4rem !important;
    border-radius: 0.3rem;

}

.cart-container .productDetail .remarks .edit-remark{
    margin-left: auto !important;
}


.cart-container .productDetail .remarks .edit-btn{
    /* background-color: var(--btn_primary_color); */
    color: #444444;
    border: 1px solid #eeeeee;
    padding: 0.5em !important;
    font-size: 1.4rem !important;
    border-radius: 0.3rem;
    margin-left: auto !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: fit-content;
    justify-content: center;
    border-color: var(--cart_cell_fg_color);
}


.cart-container .productDetail .other-cart-details .data, .cart-container .productDetail .qty .data{
    margin-left: auto !important;
    
}

.cart-container .productDetail .other-cart-details .data.selectedValue{
    margin-left: 0 !important;
}

.cart-container .productDetail .selectedValue{
    display: flex;
    gap: 0.5em;
    
}



.cart-container .productDetail .r-container {
    width: 122px !important;
}

.cart-container .productDetail .qty{
    width: auto !important;
}

.cart-container .productDetail .qty.repositioned-params{
    width: 100% !important;
}

.cart-container .productDetail .single-prod-transfer-btn{
    margin-top: auto !important;
}

.cart-container .productDetail .karat_dropdown{
    height: auto !important;
    display: flex;
    align-items: center !important;
}

.cart-container .productDetail .karat_dropdown .inch-dropdown{
    padding: var(--cart-product-dropdown-padding) !important;
}

.cart-container .productDetail .qty-plus-minus{
    height: auto !important;
    margin-left: auto !important;
    border-radius: 0.3rem;
    border-color: var(--cart_cell_fg_color) !important;
    /* padding: var(--cart-product-dropdown-padding) !important; */
}

.cart-container .productDetail .qty-plus-minus > *{
    padding: var(--cart-product-dropdown-padding) !important;
    padding-inline: 0 !important;
}

.cart-container .productDetail .btn{
    padding: var(--cart-product-btn-padding) !important;
    background-color: var(--button_background_color) !important;
    color: var(--button_foreground_color) !important;
}

.cart-container .productDetail .wishlist-btn .btn, .cart-container .productDetail .cartlist-btn .btn{
    
    background-color: var(--button_unselected_bg_color) !important;
    color: var(--button_unselected_fg_color) !important;
    border: 1px solid var(--button_unselected_border_color) !important;
}


.cart-footer{
    position: sticky;
    width: 100% !important;
    padding: var(--cart-footer-padding) !important;
    font-size: var(--cart-footer-fs) !important;
    bottom: 60px;
    z-index: 9;
    background-color: var(--cart_total_bg_color);
    border-block: 1px solid var(--menu_separator_color);
    
    /* box-shadow: 0 0 20px -10px rgba(63, 63, 63, 0.5); */
}

.cart-footer .cart-footer-label{
    color: var(--cart_total_label_color);
}

.cart-footer .cart-footer-value{
    color: var(--cart_total_value_color);
}

.cart-footer .container-fluid{
    width: var(--box-container-width);
    margin: 0 auto !important;
}

.cart-footer .details .row{
    align-items: center;
}

.cart-footer .text-left{
    display: flex;
    flex-direction: column;
    gap: var(--cart-footer-gap) !important;
}

.cart-footer .btn-li{
    padding: var(--cart-footer-btn-padding) !important;
    font-size: var(--cart-footer-btn-fs) !important;
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color) !important;
    border-radius: var(--btn-border-radius) !important;
}

.modal-footer button{
    background-color: var(--btn_primary_color) !important;
    color: var(--btn_primary_foreground_color) !important;
}


/*-----------------------------------------------------------------*/
/*----------------- Media Query ----------------------------------*/
/*-----------------------------------------------------------------*/
@media only screen and (min-width:576px) {

    .cart-footer{
        bottom: 0;
        box-shadow: 0 0 20px -10px rgba(63, 63, 63, 0.5);
    }
}


@media only screen and (min-width:768px) {
    .cart-container .productDetail{
        position: relative;
    }

    .cart-container .productDetail .other-cart-details .karat_dropdown{
        width: 122px !important;
    }

    /* .cart-container .productDetail .other-cart-details .full-width, .cart-container .productDetail > .qty{
        width: calc(50%  - (var(--cart-product-details-gap) / 2)) !important;
        margin-right: 1em !important;
    } */

    /* .cart-container .productDetail > .qty button{
        position: absolute;
        right: 0;
        bottom: 0;
    } */

    .cart-container .productDetail .other-cart-details .full-width .label{
        width: auto !important;
    }

    /* .cart-container .productDetail .other-cart-details .half-width{
        width: calc(50% - (var(--cart-product-details-gap) / 2)) !important;
    } */
}

@media only screen and (min-width:1024px) {
    .cart-main .product-section{
        width: calc(50% - 0.5em) !important;
    }

    .cart-main .empty-cart{
        display: block;
    }
}



/*************************************************************************/
/************************** CHECKOUT PAGE ********************************/
/*************************************************************************/

.checkout-container, .checkout-container * {
    margin: 0 !important;
    padding: 0 !important;
    line-height: normal !important;
}

.checkout-container{
    padding: 1em !important;
    margin-bottom: 2em !important;
    
}

.checkout-container .user-section{
    display: flex;
    gap: 0.5em;
}

.checkout-container .user-section .add-btn{
    text-transform: uppercase;
    border: 1px solid var(--btn_primary_color);
    color: var(--btn_primary_color);
    display: flex;
    align-items: center;
    outline: none;
}

.checkout-container .user-section .add-btn:focus{
    outline: 1px solid var(--btn_primary_color);
}

.checkout-container .ec-title{
    color: #777;
    font-weight: 600;
    margin-bottom: 0.5em !important;
}

.checkout-container .ec-register-container{
    width: min(670px, 100%);
    margin: 0 auto !important;
}

.checkout-container .ec-register-container{
    padding: 1em !important;
}

.checkout-container form{
    gap: var(--checkout-fields-gap) !important;
}

.checkout-container form label{
    margin: var(--label-margin-tb) !important;
}



.checkout-container form textarea{
    border: 1px solid #eeeeee;
    outline: none;
}

.checkout-container form textarea:focus{
    border: 1px solid #eeeeee;
}

.checkout-container .ec-register-btn{
    margin-top: 1em !important;
}

.add-form-modal .modal-dialog{
    height: auto;
}

.add-form-modal-body form{
    display: flex;
    flex-flow: row wrap;
    gap: 0 1em;
}

.add-form-modal-body .ec-register-half{
    display: block;
    text-align: left;
}

.add-form-modal-body .label{
    font-weight: 500;
    color:  #444;
}

.react-tel-input .form-control{
    border: 1px solid #eeeeee;
}

.add-form-modal-body .number-input{
    /* border: none; */
    width: 100%;
}



.checkout-container .ec-register-half, .add-form-modal-body .ec-register-half{
    width: calc(50% - (var(--checkout-fields-gap) / 2)) !important;
}

.checkout-container .ec-register-form .checkout-note{
    flex: 1 1 100%;
    font-size: var(--label-fs);
    text-align: center;
}

.add-form-modal .ec-register-btn button, .add-form-modal .ec-register-btn{
    width: 100% !important;
    
}

.add-form-modal .ec-register-btn button{
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    font-weight: 600;
    letter-spacing: 0.5px;
    height: auto;
}

.checkout-container .ec-register-btn button{
   
    background-color: var(--btn_primary_color);
    color: var(--btn_primary_foreground_color);
    font-weight: 600;
    letter-spacing: 0.5px;
}

/*-----------------------------------------------------------------*/
/*----------------- Media Query ----------------------------------*/
/*-----------------------------------------------------------------*/

@media only screen and (max-width:576px) {
    .add-form-modal-body .ec-register-half{
        width: 100% !important;
    }
}

@media only screen and (min-width:768px) {
    .checkout-container .ec-register-btn button, .add-form-modal .ec-register-btn button{
        margin: 0 auto !important;
        width: 100% !important;
    }
}

@media only screen and (min-width:1440px) {
    .checkout-container form input{
        height: 50px !important;
    }
}

/************** BootStrap Classes *****************************************/

.ml-auto{
    margin-left: auto !important;
}

.fit-content{
    width: fit-content !important;
}

.pb-force-5{
    padding-bottom: 18px !important;
}


/************** Utility Elements *****************************************/

.product-tag-btn{
    display: inline-block;
    padding: 0.5em !important;
    border-radius: 0.3em;
    position: absolute;
    bottom: 1em;
    left: 0.75em;
    line-height: normal;
    z-index: 1;
    font-size: var(--card-top-label-fs);
}

.matrix-page-bottom-padding-for-myc{
    padding-bottom: 2.5em !important;
}


.r-container .r-select__value-container{
    padding: 0 0.5em!important;
}

.r-container .r-select__option{
    padding: 0.5em!important;
}

.r-container .r-select__dropdown-indicator{
    padding: 0 0.5em !important;
    
}

#RegContaier .r-select__control{
    border: 1px solid #ededed !important;
    border-radius: 0.3rem !important;
}

#RegContaier .r-select__control:hover{
    border: 1px solid #ededed !important;
}

#RegContaier button.btn.btn-primary{
    border-radius: 0.3rem !important;
}

.r-container .r-select__control, .r-container .r-select__control--menu-is-open{
    border-color: var(--login_register_fg_color) !important;
    box-shadow: none !important;
    background-color: transparent;
    border-radius: 0.3rem;
}


/* .left-thumb-container, .imgLeft{
    display: none !important;
    z-index: 999;
} */



.enlargedImageContainerClassName{
    display: none;
}

.checkout-container .ec-register-container{
    width: min(1000px, 100%);
    
}

.checkout-container .selected-flag{
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout-container .selected-flag .flag{
    transform: translateY(-50%);
}

.checkout-container .country-list li{
    padding: 0.25em !important;
    display: flex;
    gap: 0.25em;
    align-items: center;
}

.checkout-container #inner-btnView{
    display: flex !important;
    gap: 0.5em !important;
    flex-wrap: wrap;
    overflow: hidden !important;
}

.checkout-container #btn-span.selected{
    color: var(--btn_primary_foreground_color)
}

.checkout-container #btn-span{
    border: 1px solid var(--btn_primary_color);
    border-radius: 0.3rem;
    background-color: transparent;
    font-size: 1.4rem !important;
    font-weight: 600;
    color: var(--btn_primary_color);
    padding: 1em !important;
    overflow-x: auto;
    cursor: pointer;
    outline: 0;
}

.checkout-container #btn-span:focus{
    outline: 1px solid var(--btn_primary_color)
    
}

.checkout-container .buttonflow #btn-span{
    overflow-x: initial;
    cursor: pointer;
    
}

.checkout-container .buttonflow{
    display: flex;
    gap: 0.5em;
} 

.checkout-container select{
    font-size: 1.5rem;
    padding: 0.5em !important;
}

.checkout-container .drop-down-select{
    display: flex;
    gap: 1em;
}

iframe{
    overflow: hidden !important;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; 
}

.dummyContainer *{
    padding-inline: 0 !important;
}

/*Hide video play button from ios device*/
.video-banner *::-webkit-media-controls-panel{
    display: none!important;
    -webkit-appearance: none;
}

.video-banner *::-webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
  }

  .video-banner *::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  }

  .ec-footer .footer-top .ec-footer-widget .ec-footer-links .ec-footer-link a:hover{
    color: var(--highlight_color)
  }