.management-page {
    padding-bottom: 3em;
}

.management-page .profile-cards-section{
    padding: 1.5em;
    width: min(1440px, 100%);
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    gap: 2.5em;
}

.management-page header h2{
    font-size: 2.5rem;
    color: var(--highlight_color);
    margin: 0.75em 1em;
    text-align: center;
    text-transform: uppercase;
}


.management-page .profile-card{
    display: flex;
    gap: 2em;
    align-items: center;
}

.management-page .profile-card:nth-child(odd) .image-section{
    order: 1;
}
.management-page .profile-card:nth-child(odd) .details-section{
    order: 2;
}

.management-page .profile-card:nth-child(even) .image-section{
    order: 2;
    display: flex;
    justify-content: flex-end;
}


.management-page .profile-card:nth-child(even) .details-section{
    order: 1;
}

.management-page .profile-card .image-section{
    flex: 1 1 400px;
}

.management-page .profile-card .image-section img{
    width: 75%;
}

.management-page .profile-card .details-section{
    flex: 1 1 500px;
    display: flex;
    flex-direction: column;
    gap: 0.35em;

}

.management-page .profile-card .details-section .name{
    font-weight: 600;
}

.management-page .profile-card .details-section .designation{
    font-size: 1.5rem;
    color: var(--highlight_color)
}

.management-page .profile-card .details-section .info{
    text-align: justify;
}

@media only screen and (max-width: 992px){
    .management-page .profile-card .image-section img{
        width: 85%;
    }
}

@media only screen and (max-width: 768px){
    .management-page .profile-card{
        flex-direction: column;
    }

    .management-page .profile-card .image-section{
        order: 1 !important;
        flex: initial;
        display: flex;
        justify-content: center !important;
    }

    

    .management-page .profile-card .details-section{
        order: 2 !important;
        flex: initial;
    }
}